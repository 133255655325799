export type ToasterType = 'info'|'warning'|'error'|'success'|'chargement'

export const Toaster = {
    $text: null,
    $visible: false,
    $type: 'info',
    show: (type: ToasterType, text: string, timeout: number = null): any => {
        Toaster.$type = type
        Toaster.$text = text
        Toaster.$visible = true

        if (timeout !== null) {
            setTimeout(() => { Toaster.hide() }, timeout)
        }
    },
    hide: () => {
        Toaster.$text = null
        Toaster.$visible = false
    },
}
