
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import ArrayPicker from '@/components/ArrayPicker.vue'
import AdminCreateUserModal from '@/components/modals/AdminCreateUserModal.vue'
import AdminEditUserModal from '@/components/modals/AdminEditUserModal.vue'

export default Vue.extend({
    name: 'AdminPage',
    components: { ArrayPicker, AdminCreateUserModal, AdminEditUserModal },
    data() {
        return {
            editedUser: null,
        }
    },
    async mounted() {
        await this.$store.dispatch('admin/fetchAvailableRoles')
        await this.$store.dispatch('admin/fetchAccountsList', { limit: 99, skip: 0 })
    },
    watch: {
        error(error: string) {
            Toaster.show('error', error)
        },
    },
    computed: {
        ...mapState({
            error: (state: State) => state.admin.error,
            accounts: (state: State) => state.admin.accounts,
            availableRoles: (state: State) => state.admin.availableRoles,
            authenticatedUserId: (state: State) => state.user.userId,
            authenticatedUserAcl: (state: State) => state.user.userId,
        }),
    },
    methods: {
        hasAnyRole(roles: string[]) {
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        saveRoles(accountId: string|number, roles: string[]) {
            this.$store.dispatch('admin/setAccountRoles', { accountId, roles })
        },
        disableAdminOptionForMyself(account: any) {
            // use string comparison because User id are declared as string | number, and we dont know which...
            return (`${account.id}` === `${this.authenticatedUserId}` && account.roles.includes('ADMIN'))
        },
        search() {
            const term = this.$refs.termInput.value
            this.$store.dispatch('admin/fetchAccountsList', { limit: 99, skip: 0, term })
        },
        openCreateUserModal() {
            this.$modal.show('create-user-modal')
        },
        openEditUserModal(account: any) {
            this.editedUser = account
            this.$modal.show('edit-user-modal')
        },
        deleteUserAccount(accountId: string) {
            const sure = confirm('It cannot be undone, are you sure?')
            if (sure) {
                this.$store.dispatch('admin/deleteAccount', { accountId })
            }
        },
    },
})
