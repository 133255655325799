
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { DEEPLINK_SCHEMAS, DEEPLINK_EXPLANATIONS } from 'lib-ad-platform-models'
import { State } from '@/store'
import DeeplinkGenModal from '@/components/modals/DeeplinkGenModal.vue'
import { Toaster } from '@/components/Toaster'
import { isValidUrl } from '@/utils/RegexpCheckUrl'
import Api from '@/api/api'

export default Vue.extend({
    name: 'ArticleEditPart2',
    props: ['missingFields'],
    components: {
        DeeplinkGenModal,
    },
    data() {
        return {
            originalSource: null,
            deeplinkOptions: Object.values(DEEPLINK_SCHEMAS),
            deeplinkExplanations: Object.values(DEEPLINK_EXPLANATIONS),
            assetsColors: {
                post: '#4D6DCE',
                diapo: '#39A5C1',
                quiz: '#E55E32',
                medics: '#C41723',
                pink: '#D226AA',
                yellow: '#E3992A',
            },
            activeSourceIndex: '',
        }
    },
    computed: mapState({
        article: (state: State) => state.art.article,
        deeplink: (state: State) => state.deeplink.deeplink,
        initValues: (state: State) => state.previewHistory.initValues,
        activeSources() {
            return this.sourcesChoice[this.activeSource]
        },
    }),
    methods: {
        ...mapMutations({
            activePreview: 'previewHistory/activePreview',
        }),
        setQuizLink() {
            this.article.quiz.link = this.article.source
        },
        setDefaultFootNote(language: string) {
            const value = {
                french: 'Vos données sont protégées. Aucune donnée nominative n’est partagée avec le partenaire.',
                english: 'Your personal data is protected. No information is shared with the partner.',
                spanish: 'Sus datos están protegidos. No se compartirán sus datos personales con nadie.',
                italian: 'I tuoi dati sono protetti. Nessun dato personale viene condiviso con il partner.',
            }

            this.article.footNote = value[language]
        },
        toggleNewDeeplinkModal(open: boolean) {
            return open
                ? this.$refs.DeeplinkModal.openModal()
                : this.$refs.DeeplinkModal.closeModal()
        },
        setActiveSource(index: number) {
            this.activeSourceIndex = index
        },
        onCopy() {
            Toaster.show('success', 'Link copied', 2000)
            this.actionsToCopy = []
        },
        onError() {
            Toaster.show('error', 'Failed to copy actions', 2000)
            this.actionsToCopy = []
        },
        showMetadataLink() {
            const decodedUrl = this.decodeUrl(this.article.source)
            return isValidUrl(decodedUrl)
        },
        decodeUrl(url: string) {
            const decodedSource = decodeURIComponent(url)
            return decodedSource.match(/\bhttps?:\/\/\S+/gi)
                ? decodedSource.match(/\bhttps?:\/\/\S+/gi)[0]
                : url
        },
        resetPreview() {
            this.article.title = this.initValues.title
            this.article.description = this.initValues.description
            this.article.author = this.initValues.author
            this.article.image = this.initValues.image
            this.article.images = this.initValues.images
            this.article.logo = this.initValues.logo
        },
        async parseUrl(url: string) {
            this.resetPreview()
            Toaster.show('chargement', 'Récupération des données en cours')
            const decodedUrl = this.decodeUrl(url)
            if (!isValidUrl(decodedUrl)) {
                Toaster.show('error', 'L\'URL n\'est pas valide', 3000)
                return
            }
            try {
                const parsedArticle = await Api.get<{
                        title?: string;
                        description?: string;
                        author?: string;
                        image?: string;
                        logo?: string;
                    }>(`/url/parse?url=${decodedUrl}`)

                this.article.title = parsedArticle.title
                this.article.tagline = parsedArticle.description
                this.article.author = parsedArticle.author

                const warningToaster: { cover?: boolean; logo?: boolean } = {}
                if (parsedArticle.image) {
                    this.article.image = parsedArticle.image
                    this.article.images = [...this.initValues.images]
                    this.article.images.unshift(parsedArticle.image)
                } else {
                    warningToaster.cover = true
                }
                if (parsedArticle.logo) {
                    this.article.logo = parsedArticle.logo
                } else {
                    warningToaster.logo = true
                }
                this.activePreview()
                this.formatToasterSubmitMessage(warningToaster)
            } catch (e) {
                Toaster.show('error', `Impossible de récupérer les données depuis l'URL : ${e.toString()}`, 3000)
            }
        },
        formatToasterSubmitMessage(warningToaster) {
            let message = 'Données récurérées depuis l\'URL avec succès'
            if (warningToaster.logo && warningToaster.cover) {
                message = message.concat(' (mais ne contient ni logo ni image)')
                Toaster.show('warning', message, 5000)
                return
            }
            if (warningToaster.cover) {
                message = message.concat(" (mais ne contient pas d'image)")
                Toaster.show('warning', message, 5000)
                return
            }
            if (warningToaster.logo) {
                message = message.concat(' (mais ne contient pas de logo)')
                Toaster.show('warning', message, 5000)
                return
            }
            Toaster.show('success', message, 5000)
        },
    },
})
