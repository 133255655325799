
import Vue from 'vue'
import { Inventory } from '@/models/Inventory'
import { getFirstAndLastMonthOfYear } from '@/utils/getFirstAndLastMonthOfYear'
import { populationCategoriesMapToId } from '@/utils/projectFilters'
import { Toaster } from '@/components/Toaster'
import { mapRowSpecialityIdToName } from '@/utils/mapRowSpecialityIdToName'

export default Vue.extend({
    name: 'InventoryAnnualViewModal',

    data() {
        return {
            actualYear: new Date().getFullYear(),
            inventoryRowDetails: {
                type: Object as Vue.PropType<Inventory[]>,
                required: true,
                default: () => ({}),
            },
            footerData: {
                totalAivaibleViews: 0,
                pourcentage: 0,
            },
            rowdetails: {
                rowSpecialityId: 0,
                rowSpecialityLabel: '',
            },
            filters: {
                from: getFirstAndLastMonthOfYear(Number(new Date().getFullYear())).formatedStartDate,
                to: getFirstAndLastMonthOfYear(Number(new Date().getFullYear())).formaredEndDate,
                specialty: [],
                profession: [],
                viewType: 'quarter',
            },
            isModalOpen: false,
            isLoading: false,
        }
    },

    methods: {
        openModal(rowSpecialityId: number, rowSpecialityLabel: string) {
            this.rowdetails = {
                rowSpecialityId,
                rowSpecialityLabel,
            }
            this.getQuarterInventory(rowSpecialityId, rowSpecialityLabel.toLowerCase())
            this.isModalOpen = true
        },
        closeModal() {
            this.isModalOpen = false
        },
        calculPourcentage(totalViews: number, consumedViews: number) : number {
            return (consumedViews / totalViews) * 100
        },
        calculFooterData(inventoryItem: any) {
            const sumTotalViews = inventoryItem.inventory.reduce((acc, current) => {
                return acc + current.totalViews
            }, 0)

            const sumConsumedViews = inventoryItem.inventory.reduce((acc, current) => {
                return acc + current.consumedViews
            }, 0)

            const totalAivaibleViews = sumTotalViews - sumConsumedViews
            this.footerData = {
                totalAivaibleViews,
                pourcentage: this.calculPourcentage(sumTotalViews, sumConsumedViews),
            }
        },
        navigate(isNavigateForward: boolean) {
            if (isNavigateForward) {
                this.actualYear += 1
                this.filters = {
                    ...this.filters,
                    from: getFirstAndLastMonthOfYear(Number(this.actualYear)).formatedStartDate,
                    to: getFirstAndLastMonthOfYear(Number(this.actualYear)).formaredEndDate,
                }
                this.getQuarterInventory(this.rowdetails.rowSpecialityId, this.rowdetails.rowSpecialityLabel.toLowerCase())
            } else {
                this.actualYear -= 1
                this.filters = {
                    ...this.filters,
                    from: getFirstAndLastMonthOfYear(Number(this.actualYear)).formatedStartDate,
                    to: getFirstAndLastMonthOfYear(Number(this.actualYear)).formaredEndDate,
                }
                this.getQuarterInventory(this.rowdetails.rowSpecialityId, this.rowdetails.rowSpecialityLabel.toLowerCase())
            }
        },
        getQuarterInventory(id: string, label: string) {
            this.isLoading = true
            this.filters = {
                ...this.filters,
                [label]: [id],

            }
            this.$store
                .dispatch('proj/getModalQuarterInventory', this.filters)
                .then((response: Inventory[]) => {
                    const name = mapRowSpecialityIdToName(id, label, populationCategoriesMapToId)
                    response[0].populationCategory = name
                    const formatedResponse = response[0]
                    this.inventoryRowDetails = formatedResponse
                    this.calculFooterData(this.inventoryRowDetails)
                })
                .catch((error) => {
                    Toaster.show('error', error.response, 3000)
                    this.isLoading = false
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
    },

})
