import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import AccessDenied from '../views/AccessDenied.vue'
import AdminPage from '../views/AdminPage.vue'
import AdvertEditPage from '../views/AdvertEditPage.vue'
import AdvertListPage from '../views/AdvertListPage.vue'
import AlphonseListPage from '../views/AlphonseListPage.vue'
import AlphonseProjectPage from '../views/AlphonseProjectPage.vue'
import ArticleEditPage from '../views/ArticleEditPage.vue'
import ArticleListPage from '../views/ArticleListPage.vue'
import AudienceDebugPage from '../views/AudienceDebugPage.vue'
import AudienceEditPage from '../views/AudienceEditPage.vue'
import AudienceGroupEditPage from '../views/AudienceGroupEditPage.vue'
import AudienceListPage from '../views/AudienceListPage.vue'
import BatPage from '../views/BatPage.vue'
import CdnDocumentUpload from '../views/CdnDocumentUpload.vue'
import GroupListPage from '../views/GroupListPage.vue'
import ImportsPage from '../views/ImportsPage.vue'
import LoginPage from '../views/LoginPage.vue'
import PasswordConfirmPage from '../views/PasswordConfirmPage.vue'
import ProjectEditPage from '../views/ProjectEditPage.vue'
import ProjectListPage from '../views/ProjectListPage.vue'
import TargetListPage from '../views/TargetListPage.vue'
import TestAccountsPage from '../views/TestAccountsPage.vue'
import InventoryDasboard from '../views/InventoryDashboard.vue'
import PushNotificationCreatePage from '../views/PushNotificationCreatePage.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/403',
        name: 'AccessDenied',
        component: AccessDenied,
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/password/confirm',
        name: 'PasswordConfirmPage',
        component: PasswordConfirmPage,
    },
    {
        path: '/',
        name: 'Home',
        component: AdvertListPage,
    },
    {
        path: '/projects',
        name: 'ProjectListPage',
        component: ProjectListPage,
    },
    {
        path: '/project/edit/:id',
        name: 'ProjectEditPage',
        component: ProjectEditPage,
    },
    {
        path: '/targets',
        name: 'TargetListPage',
        component: TargetListPage,
    },
    {
        path: '/audiences',
        name: 'AudienceListPage',
        component: AudienceListPage,
    },
    {
        path: '/audiences/:id',
        name: 'AudienceEditPage',
        component: AudienceEditPage,
    },
    {
        path: '/groupes',
        name: 'GroupListPage',
        component: GroupListPage,
    },
    {
        path: '/group/:label/:id',
        name: 'AudienceGroupEditPage',
        component: AudienceGroupEditPage,
    },
    {
        path: '/test-accounts',
        name: 'TestAccountsPage',
        component: TestAccountsPage,
    },
    {
        path: '/adverts',
        name: 'AdvertListPage',
        component: AdvertListPage,
    },
    {
        path: '/adverts/edit/:id',
        name: 'AdvertEditPage',
        component: AdvertEditPage,
    },
    {
        path: '/advert/bat/:advertId',
        name: 'BatPage',
        component: BatPage,
    },
    {
        path: '/articles',
        name: 'ArticleListPage',
        component: ArticleListPage,
    },
    {
        path: '/articles/edit/:id',
        name: 'ArticleEditPage',
        component: ArticleEditPage,
    },
    {
        path: '/document/upload',
        name: 'CdnDocumentUpload',
        component: CdnDocumentUpload,
    },
    {
        path: '/admin',
        name: 'AdminPage',
        component: AdminPage,
    },
    {
        path: '/debug/audiences/:id',
        name: 'AudienceDebugPage',
        component: AudienceDebugPage,
    },
    {
        path: '/imports',
        name: 'ImportsPage',
        component: ImportsPage,
    },
    {
        path: '/alphonse',
        name: 'AlphonseListPage',
        component: AlphonseListPage,
    },
    {
        path: '/alphonse/project/:id',
        name: 'AlphonseProjectPage',
        component: AlphonseProjectPage,
    },
    {
        path: '/inventory',
        name: 'InventoryDashboard',
        component: InventoryDasboard,
    },
    {
        path: '/push/create',
        name: 'PushNotificationCreatePage',
        component: PushNotificationCreatePage,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
