
import Vue from 'vue'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'
import { State } from '@/store'
import { UIKitStuff } from '@/models/MiscTypes'
import { replaceWord } from '@/filters/replaceWord.filter'
import { capitalize } from '@/filters/capitalize.filter'

declare let UIkit: UIKitStuff

export default Vue.extend({
    name: 'ProjectActiveFilterList',
    filters: { replaceWord, capitalize },
    props: {
        mode: { type: String },
        placeholder: {
            type: String,
            default: () => 'Rechercher... [Projet, client]',
        },
        showActiveFilters: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        ...mapState({
            filters: (state: State) => state.proj.listFilters,
        }),
        activeFilters() {
            return Object.keys(this.filters)
                .filter(
                    (key) => !['order', 'orderBy'].includes(key) && !isEmpty(this.filters[key]),
                )
                .map((key) => {
                    const isArray = typeof this.filters[key] === 'object'

                    return {
                        label: key,
                        values: this.filters[key],
                        count: isArray ? this.filters[key].length : null,
                    }
                })
        },
    },
    data() {
        return {
            term: '',
            labels: {
                name: 'Projet',
                clientName: 'Client',
                keyAccount: 'CSM',
                targetStatus: 'Statut',
                type: 'Type',
                populationCategory: 'Spé',
                populationSize: 'Taille cible',
                startDate: 'Date de début',
                checkDate: 'Date de feedbacks intermédiaires',
                endDate: 'Date de fin',
                populationSample: 'Ciblage',
                term: 'Term',
            },
            autocompleteList: {
                name: [],
                clientName: [],
            },
        }
    },
    mounted() {
        // this.resetFilters()
    },
    methods: {
        removeFilter(label: string) {
            this.$store.commit('proj/removeProjectFilter', label)
            this.term = ''
            this.$emit('removeFilter')
        },
        resetFilters() {
            this.$store.commit('proj/resetProjectFilters')
            this.term = ''
            this.$emit('resetFilters')
        },
        filterByTerm() {
            UIkit.dropdown(this.$refs.dropdownRef).hide()
            this.$emit('search', this.term)
        },
        async onKeyUp(event) {
            if (this.term.length < 2 || event.key === 'Enter') {
                this.autocompleteList = {
                    name: [],
                    clientName: [],
                }
                UIkit.dropdown(this.$refs.dropdownRef).hide()
                return
            }

            await Promise.all(Object.keys(this.autocompleteList).map(async (field) => {
                try {
                    this.autocompleteList[field] = await this.$store
                        .dispatch('proj/getFilterList', {
                            label: 'Project',
                            term: this.term,
                            field,
                            limit: 10,
                        })
                } catch (error) {
                    // @fix
                }
            }))

            if (this.autocompleteList.name.length > 0 || this.autocompleteList.clientName.length > 0) {
                UIkit.dropdown(this.$refs.dropdownRef).show()
            } else {
                UIkit.dropdown(this.$refs.dropdownRef).hide()
            }
        },
        autocompleteFill(value: string) {
            UIkit.dropdown(this.$refs.dropdownRef).hide()
            this.term = value
            this.$emit('search', value)
        },
        onFocus() {
            this.$emit('focus')
        },
        onBlur() {
            this.$emit('blur')
        },
    },
})
