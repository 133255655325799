export default {
    debug: 'warn',
    modules: {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ align: '' }, { align: 'center' }, { align: 'right' }],
                [
                    'link',
                ],
                [{ color: [] }, { background: [] }],
            ],
            handlers: {
                image() {
                    document.getElementById('getImage').click()
                },
            },
        },
    },
    placeholder: 'Ajouter un commentaire',
    theme: 'snow',
}
