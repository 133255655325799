
import Vue from 'vue'

export default Vue.extend({
    name: 'QuizPreview',
    props: {
        advert: { type: Object, default: null },
        scale: { type: Number, default: 1 },
    },
    data() {
        return {
            isShowReadMoreClicked: false,
            flow: ['step1', 'step2', 'step3'],
        }
    },
    computed: {
        validateLabel() {
            switch (this.advert.footNote) {
            case 'Vos données sont protégées. Aucune donnée nominative n’est partagée avec le partenaire.':
                return 'Valider'
            case 'Your personal data is protected. No information is shared with the partner.':
                return 'Validate'
            case 'Sus datos están protegidos. No se compartirán sus datos personales con nadie.':
                return 'Validar'
            case 'I tuoi dati sono protetti. Nessun dato personale viene condiviso con il partner.':
                return 'Validare'
            default:
                return 'Valider'
            }
        },
    },
})
