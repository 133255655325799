
import Vue from 'vue'

export default Vue.extend({
    name: 'SearchAdvertModal',
    props: ['target', 'projectId'],
    data() {
        return {
            advertSearchResults: null,
        }
    },
    methods: {
        keyup() {
            const term = this.$refs.termInput.value

            if (term === '') {
                this.advertSearchResults = null
                return
            }

            const typeMatching = {
                'contenu additionel': 'search',
                'conseil associé': 'search',
                notification: 'story',
                sondage: 'quiz',
                'story video': 'story',
                'flash notif': 'notification',
            }

            this.$store.dispatch('advt/searchAdverts', { term })
                .then((res) => {
                    if (res.length) {
                        this.advertSearchResults = res
                            .filter((advert) => {
                                return advert.type === (typeMatching[this.target.type] || this.target.type)
                            })
                    } else {
                        this.advertSearchResults = null
                    }
                })
        },
        setAdvert(advertId: string) {
            this.$store.dispatch('proj/addNewAdvertToTarget', { targetId: this.target.id, advertId, projectId: this.projectId })
            this.$emit('close')
        },
    },

})
