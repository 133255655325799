
import Vue from 'vue'
import uniqid from 'uniqid'
import { StoryScreen } from 'lib-ad-platform-models'

import { StoryLogoImageUpload } from '@/components/AttachmentUpload'
import { Toaster } from '@/components/Toaster'
import { advertParser } from '@/utils/advertParser'
import { mixpanelLinkToScreen } from '@/utils/mixpanelLinks'

export default Vue.extend({
    name: 'StoryRowIconEditor',
    props: [
        'artefact',
        'currentScreen',
        'label',
        'isEditable',
    ],
    data() {
        return {
            updateTimeStamp: null,
        }
    },
    methods: {
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onStoryLogoChange() {
            if (this.$refs.screenLogoInput.files.length === 0) { return }
            const uploader = new StoryLogoImageUpload(this.$refs.screenLogoInput.files[0], this.artefact.id)

            Toaster.show('info', 'Upload du logo...')

            uploader.performUpload()
                .then((r) => {
                    Toaster.show('success', 'Logo enregistré.', 1400)
                    this.artefact.logo = r.webpath
                    this.$refs.screenLogoImg.src = r.webpath
                }).catch((e) => {
                    Toaster.show('error', `N'erreur est survenue lors de l'enregistrement du logo.${e.toString()}`, 1400)
                })
        },
        ensureStoryLogo() {
            return `${(typeof this.artefact.logo !== 'undefined' && this.artefact.logo !== null)
                ? this.artefact.logo
                : '/images/default_vignette_image.jpg'}?d=${uniqid()}`
        },
        mixpanelLinkToScreen() {
            if (!this.currentScreen) { return null }

            return mixpanelLinkToScreen(this.currentScreen.id)
        },
        addScreen() {
            if (this.label === 'advert') {
                this.$store.dispatch('advt/addStoryScreen', {
                    artefact: this.artefact,
                    screen: {
                        ...this.artefact.story.screens[0] ?? new StoryScreen(),
                        uuid: uniqid(),
                        id: null,
                        illustration: null,
                    },
                })
                    .then(() => {
                        this.$store.dispatch('advt/updateAdvert', { advert: this.artefact })
                    })
                    .then(() => {
                        this.$store.commit('advt/setAdvert', advertParser(this.artefact))
                    })
            } else if (this.label === 'article') {
                this.$store.dispatch('art/addArticleStoryScreen', {
                    artefact: this.artefact,
                    screen: {
                        ...this.artefact.story.screens[0] ?? new StoryScreen(),
                        uuid: uniqid(),
                        id: null,
                        illustration: null,
                    },
                })
            }
        },
    },
})
