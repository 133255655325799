
import Vue from 'vue'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'
import { dayjs } from '@/utils/dayjs'
import { State } from '@/store'
import { normalDate } from '@/filters/simpday.filter'
import { transMonth } from '@/filters/trans.filter'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'CellDatePicker',
    filters: { transMonth, normalDate },
    props: {
        epoch: Number,
        center: { type: Boolean, default: false },
        col: String,
    },
    data() {
        return {
            ctrlDown: false,
            hover: false,
            editMode: false,
        }
    },
    computed: {
        ...mapState({
            clipboard: (state: State) => state.proj.clipboard,
        }),
        isToday() {
            const today = new Date().setHours(0, 0, 0, 0)
            const itemDate = new Date(this.epoch * 1000).setHours(0, 0, 0, 0)
            return itemDate === today
        },
    },
    mounted() {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Control' || e.key === 'Meta') {
                this.ctrlDown = true
            }
        })

        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key === 'Control' || e.key === 'Meta') {
                this.ctrlDown = false
            }
        })

        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'c' && this.ctrlDown && this.hover) {
                Toaster.show('success', 'Copy to clipboard!', 1000)
                this.$store.commit('proj/copyToClipboard', {
                    category: this.col,
                    value: this.epoch,
                })
            }

            if (e.key === 'v' && this.ctrlDown && this.hover && this.clipboard) {
                const { category, value } = this.clipboard

                if (category === this.col) {
                    this.$emit('change', value)
                }
            }
        })
    },
    methods: {
        fromUnixTimestamp(timestamp: number) {
            if (timestamp !== null) {
                return dayjs(timestamp * 1000).local().format('YYYY-MM-DD')
            }
            return null
        },
        submitDate(dateStr: string) {
            let updatedEpoch: number

            if (isEmpty(dateStr)) {
                updatedEpoch = null
            } else {
                updatedEpoch = dayjs(dateStr).unix()
            }

            this.$emit('change', updatedEpoch)
        },
        setEditMode() {
            if (this.editMode === true) {
                setTimeout(() => { this.editMode = false }, 1000)
            }
            this.hover = false
        },
    },
})
