export function truncate(text: string, max = 80): string {
    if (text === null) {
        return ''
    }
    if (text.length > max) {
        return `${text.substring(0, (max - 3))}...`
    }
    return text
}

export function showReadMore(text: string, showOrNot: boolean, max = 80): boolean {
    return (text.length > max && showOrNot === true)
}
