
import Vue from 'vue'
import { UIKitStuff } from '@/models/MiscTypes'

declare let UIkit: UIKitStuff

export default Vue.extend({
    name: 'AlphonseProjectTags',
    props: ['projectId', 'understandTags', 'convinceTags', 'surpriseTag'],
    data() {
        return {
            types: ['Environnement', 'Efficacité', 'Bon Usage'],
        }
    },
    methods: {
        createNewTag(type: string) {
            UIkit.dropdown(this.$refs.dropdownRef).hide()
            this.$store.dispatch('alph/createAlphonseTag', { type, projectId: this.projectId })
        },
        openDeleteTagModal(tagId: string) {
            this.$emit('openDeleteTagModal', tagId)
        },
        editTagQuery(tag: any) {
            this.$emit('editTagQuery', tag)
        },
    },
})
