
import Vue from 'vue'
import uniqid from 'uniqid'
import { Advert, Article } from 'lib-ad-platform-models'
import { Toaster } from '@/components/Toaster'
import { truncate, showReadMore } from '@/filters/truncate.filter'
import { AttachmentUpload } from '../../../components/AttachmentUpload'

export default Vue.extend({
    name: 'QuizPreview',
    filters: { truncate, showReadMore },
    props: {
        artefact: { type: Object, default: null },
        editable: { type: Boolean, default: false },
        label: { type: String, default: 'advert' },
    },
    data() {
        return {
            isShowReadMoreClicked: false,
        }
    },
    methods: {
        showFullText(isActive: boolean) {
            this.isShowReadMoreClicked = !!isActive
        },
        ensureLogoImg(artefact: Advert | Article) {
            return (
                `${artefact.logo !== 'undefined' && artefact.logo !== null
                    ? artefact.logo
                    : '/images/default_logo_image.jpg'}?d=${uniqid()}`
            )
        },
        ensureCoverImg(artefact: Advert | Article) {
            return (
                `${artefact.image !== 'undefined' && artefact.image !== null
                    ? artefact.image
                    : '/images/default_cover_image.png'}?d=${uniqid()}`
            )
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            if (!this.editable) {
                return
            }

            e.stopPropagation()

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onLogoChanged() {
            if (this.$refs.logoInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')
            const handler = new AttachmentUpload(
                this.$refs.logoInput.files[0],
                this.artefact.id,
            )

            handler
                .performUpload('logo')
                .then((r) => {
                    Toaster.show('success', 'Logo enregistré', 1300)
                    this.artefact.logo = r.webpath
                    this.$refs.logoImg.src = r.webpath
                })
                .catch((e) => {
                    Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                })
        },
        onCoverMediaChanged() {
            if (this.$refs.coverInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')
            const handler = new AttachmentUpload(
                this.$refs.coverInput.files[0],
                this.artefact.id,
            )

            handler
                .performUpload('cover')
                .then((r) => {
                    Toaster.show('success', 'Image enregistrée', 1300)
                    this.artefact.image = r.webpath
                    this.artefact.images[0] = r.webpath
                    this.$refs.coverMedia.src = r.webpath
                })
                .catch((e) => {
                    Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                })
        },
        onCoverMediaRemoved(e: MouseEvent) {
            e.stopPropagation()
            this.artefact.image = null
            this.artefact.images = []
            this.$refs.coverMedia.src = null
        },
        onCoverMediaAdd() {
            if (!this.editable) {
                return
            }

            this.artefact.image = '/images/default_cover_image.png'
            this.$refs.coverMedia.src = '/images/default_cover_image.png'
        },
    },
})
