
import Vue from 'vue'
import { mapState } from 'vuex'
import uniqid from 'uniqid'
import { orderBy } from 'lodash'
import { debounce } from 'debounce'

import Html2Pdf from '@/views/partials/bat/Html2Pdf.vue'
import LayersToolbar from '@/views/partials/bat/LayersToolbar.vue'
import SandBox from '@/views/partials/bat/SandBox.vue'
import Api from '@/api/api'
import ConfirmModal from '@/components/ConfirmModal.vue'
import editorOptions from '@/utils/editor-options-bat'
import { Toaster } from '@/components/Toaster'

const mouseXY = { x: null, y: null }
const DragPos = { x: null, y: null, w: 6, h: 8, i: null }

export default Vue.extend({
    name: 'BatPage',
    components: {
        ConfirmModal,
        LayersToolbar,
        Html2Pdf,
        SandBox,
    },
    data() {
        return {
            isLoading: false,
            isSaving: false,
            activePageIndex: null,
            activeLayerIndex: null,
            editorOptions,
            screenToDelete: null,
            batScale: 1,
            vueCanvas: null,
            isSVGDrawingEnabled: false,
            coordinates: {
                x1: null,
                y1: null,
                x2: null,
                y2: null,
            },
        }
    },
    computed: {
        ...mapState({
            bat: (state: any) => state.advt.bat,
        }),
        pages() {
            if (!this.bat) {
                return null
            }

            return this.bat.pages.map(({ page }) => page).sort()
        },
        orderedLayers() {
            return orderBy(this.bat.pages[this.activePageIndex].layers, ['level'], ['asc'])
        },
        activePage() {
            return this.bat?.pages[this.activePageIndex] ?? null
        },
        activeLayer() {
            return this.bat?.pages[this.activePageIndex].layers[this.activeLayerIndex] ?? null
        },
    },
    watch: {
        bat: {
            handler() {
                this.save()
            },
            deep: true,
        },
        isSVGDrawingEnabled(val) {
            if (val) {
                document.getElementById(`svg-container-${this.activeLayer.level}`).addEventListener('mousedown', (e) => {
                    this.coordinates.x1 = e.offsetX
                    this.coordinates.y1 = e.offsetY
                }, false)

                document.getElementById(`svg-container-${this.activeLayer.level}`).addEventListener('mouseup', (e) => {
                    this.coordinates.x2 = e.offsetX
                    this.coordinates.y2 = e.offsetY
                    this.addArrow()
                }, false)
            }
        },
    },
    mounted() {
        this.isLoading = true
        this.$store.dispatch('advt/loadBat', this.$route.params.advertId)
            .then(() => {
                if (this.bat) {
                    this.activePageIndex = this.bat.pages.findIndex(({ page }) => page === 1)

                    const max = Math.max(...this.bat.pages[this.activePageIndex].layers.map(({ level }) => level))
                    this.activeLayerIndex = this.bat.pages[this.activePageIndex].layers.findIndex(({ level }) => level === max)
                }

                this.isLoading = false
            })
            .catch((error) => {
                this.isLoading = false
                Toaster.show('error', error, 3000)
            })

        document.addEventListener('dragover', (e) => {
            mouseXY.x = e.clientX
            mouseXY.y = e.clientY
        }, false)

        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Escape' && this.isSVGDrawingEnabled) {
                this.isSVGDrawingEnabled = false
            }
        }, false)
    },
    methods: {
        save: debounce(async function () {
            this.isSaving = true
            await this.$store.dispatch('advt/saveBatLayers', { pages: this.bat.pages, advertId: this.$route.params.advertId })
            this.isSaving = false
        }, 500),
        addArrow() {
            if (!Object.values(this.coordinates).every((item) => item) || !this.isSVGDrawingEnabled) {
                return
            }

            const { x1, y1, x2, y2 } = this.coordinates
            const item = { x: x1, y: y1, w: x2, h: y2, i: uniqid(), type: 'arrow' }
            this.bat.pages[this.activePageIndex].layers[this.activeLayerIndex].content.push(item)
            this.isSVGDrawingEnabled = false
            this.coordinates = {
                x1: null,
                y1: null,
                x2: null,
                y2: null,
            }
        },
        changeActivePage(pageNumber: number) {
            this.activePageIndex = this.bat.pages.findIndex(({ page }) => page === pageNumber)
            const max = Math.max(...this.activePage.layers.map(({ level }) => level))
            this.activeLayerIndex = this.activePage.layers.findIndex(({ level }) => level === max)
        },
        back() {
            this.$router.push(`/adverts/edit/${this.$route.params.advertId}`)
        },
        addText() {
            const index = Math.max(0, ...this.activePage.layers[this.activeLayerIndex].content.map(({ i }) => parseInt(i))) + 1

            const item = { x: 0, y: 0, w: 6, h: 2, i: index.toString(), type: 'text', content: 'Ajouter votre texte ici' }
            this.bat.pages[this.activePageIndex].layers[this.activeLayerIndex].content.push(item)
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        async upload() {
            const formData = new FormData()
            formData.append('advertId', this.$route.params.advertId)
            formData.append('file', this.$refs.filesBrowser.files[0])

            try {
                const result: any = await Api.upload('/bat/screenshot/upload', formData)
                this.bat.screenshots.push({ imgPath: result.webpath[0] })
                Toaster.show('success', 'Image importé avec succès', 2000)
            } catch (error) {
                Toaster.show('error', error, 3500)
            }
        },
        downloadImage(screen: any) {
            fetch(screen.imgPath)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.href = url
                    a.download = `ad${this.$route.params.advertId}-preview.png`
                    document.body.appendChild(a)
                    a.click()
                    window.URL.revokeObjectURL(url)
                })
                .catch((error) => {
                    Toaster.show('error', error, 3500)
                })
        },
        openConfirmDeleteScreenModal(screen: any) {
            this.screenToDelete = screen
            this.$modal.show('deletePreview')
        },
        deleteImageFromBat() {
            this.$store.dispatch('advt/deleteBatScreenshot', { advertId: this.$route.params.advertId, screenshotId: this.screenToDelete.id })
            Toaster.show('success', 'Image supprimée avec succès', 2000)
        },
        addNewPage() {
            this.$store.dispatch('advt/addBatNewPage', { advertId: this.$route.params.advertId, pages: this.bat.pages })
                .then(() => {
                    const maxPage = Math.max(...this.bat.pages.map(({ page }) => page))
                    this.activePageIndex = this.bat.pages.findIndex(({ page }) => page === maxPage)

                    const maxLevel = Math.max(...this.activePage.layers.map(({ level }) => level))
                    this.activeLayerIndex = this.activePage.layers.findIndex(({ level }) => level === maxLevel)
                })
        },
        addNewLayer() {
            this.$store.dispatch('advt/addBatNewLayer', {
                advertId: this.$route.params.advertId,
                page: this.bat.pages[this.activePageIndex],
            })
                .then(() => {
                    this.activePageIndex = this.bat.pages.findIndex(({ page }) => page === this.bat.pages[this.activePageIndex].page)
                    const max = Math.max(...this.bat.pages[this.activePageIndex].layers.map(({ level }) => level))
                    this.activeLayerIndex = this.bat.pages[this.activePageIndex].layers.findIndex(({ level }) => level === max)
                })
                .catch((error) => {
                    Toaster.show('error', error, 3000)
                })
        },
        deleteLayer(level: number) {
            this.$store.dispatch('advt/deleteBatLayer', {
                advertId: this.$route.params.advertId,
                page: this.activePage,
                level,
            })
                .then(() => {
                    this.activePageIndex = this.bat.pages.findIndex(({ page }) => page === this.bat.pages[this.activePageIndex].page)
                    this.activeLayerIndex = this.bat.pages[this.activePageIndex].layers.findIndex((layer) => layer.level === level)
                    || this.bat.pages[this.activePageIndex].layers.findIndex((layer) => layer.level === level - 1)
                })
                .catch((error) => {
                    Toaster.show('error', error, 3000)
                })
        },
        selectActiveLayer(level: number) {
            this.activeLayerIndex = this.bat.pages[this.activePageIndex].layers.findIndex((layer) => layer.level === level)
        },
        deletePage(deletedPage) {
            this.$store.dispatch('advt/deleteBatPage', { advertId: this.$route.params.advertId, deletedPage, pages: this.bat.pages })
                .then(() => {
                    Toaster.show('success', 'la page a été supprimée', 2500)
                    this.activePageIndex = this.bat.pages.findIndex(({ page }) => page === deletedPage.page) === -1 ? 0 : this.bat.pages.findIndex(({ page }) => page === deletedPage.page)
                    const max = Math.max(...this.activePage.layers.map(({ level }) => level))
                    this.activeLayerIndex = this.activePage.layers.findIndex(({ level }) => level === max)
                })
                .catch((error) => {
                    Toaster.show('error', error, 3000)
                })
        },
        drag() {
            const { content, id } = this.activeLayer
            const sandboxContent = content.filter(({ type }) => type !== 'arrow')
            const parentRect = document.getElementById('sandbox-area').getBoundingClientRect()
            let mouseInGrid = false
            if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                mouseInGrid = true
            }

            if (mouseInGrid === true && (content.findIndex((item) => item.i === 'drop')) === -1) {
                content.push({
                    x: (sandboxContent.length * 2) % (this.colNum || 12),
                    y: sandboxContent.length + (this.colNum || 12),
                    w: 6,
                    h: 8,
                    i: 'drop',
                    type: 'image',
                })
            }

            const index = sandboxContent.findIndex((item) => item.i === 'drop')

            if (index !== -1) {
                const el = this.$refs[`sandbox-${id}`][0].$refs.gridlayout.$children[index]
                el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left }
                const newPos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left)
                if (mouseInGrid === true) {
                    this.$refs[`sandbox-${id}`][0].$refs.gridlayout.dragEvent('dragstart', 'drop', newPos.x, newPos.y, 8, 6)
                    DragPos.i = String(index)
                    DragPos.x = sandboxContent[index].x
                    DragPos.y = sandboxContent[index].y
                }
                if (mouseInGrid === false) {
                    this.$refs[`sandbox-${id}`][0].$refs.gridlayout.dragEvent('dragend', 'drop', newPos.x, newPos.y, 8, 6)
                    this.bat.pages[this.activePageIndex].layers[this.activeLayerIndex].content = content.filter((obj) => obj.i !== 'drop')
                }
            }
        },
        dragend(url) {
            const { content, id } = this.activeLayer
            const parentRect = document.getElementById('sandbox-area').getBoundingClientRect()
            let mouseInGrid = false
            if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                mouseInGrid = true
            }
            if (mouseInGrid === true) {
                this.$refs[`sandbox-${id}`][0].$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 8, 6)
                this.bat.pages[this.activePageIndex].layers[this.activeLayerIndex].content = content.filter((obj) => obj.i !== 'drop')

                const index = Math.max(0, ...this.bat.pages[this.activePageIndex].layers[this.activeLayerIndex].content.filter(({ type }) => type !== 'arrow').map(({ i }) => parseInt(i))) + 1

                this.bat.pages[this.activePageIndex].layers[this.activeLayerIndex].content.push({
                    x: DragPos.x,
                    y: DragPos.y,
                    w: 6,
                    h: 8,
                    i: index.toString(),
                    url: `${url}?d=${uniqid()}`,
                    displayApp: false,
                    type: 'image',
                })
                this.$refs[`sandbox-${id}`][0].$refs.gridlayout.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, 8, 6)
            }
        },
        setbatScale(action: string) {
            if (action === '+' && this.batScale <= 0.95) {
                this.batScale += 0.05
            }

            if (action === '-' && this.batScale >= 0.7) {
                this.batScale -= 0.05
            }
        },
    },
})
