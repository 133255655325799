import { Project, ProjectListFilter, Target, Comment } from 'lib-ad-platform-models'
import { Inventory, InventoryPayload } from '@/models/Inventory'
import router from '@/router'
import Api from '@/api/api'
import { Toaster } from '@/components/Toaster'
import { Console } from '@/utils/Console'

export type ProjectState = {
    project: Project,
    projects: Project[],
    targets: Target[],
    timeline: any,
    advertProjectId: string,
    listFilters: ProjectListFilter,
    inventoryFilter: any,
    clipboard: any,
    monthInventory: Inventory[],
    quarterInventory: Inventory[],
}

const state: ProjectState = ({
    project: null,
    projects: [],
    targets: [],
    timeline: {},
    monthInventory: [],
    quarterInventory: [],
    inventoryFilter: [],
    advertProjectId: null,
    listFilters: new ProjectListFilter({
        limit: 8,
    }),
    clipboard: null,
})

const getters = {}

const mutations = {
    setProject(state: ProjectState, payload: Project): void {
        if (payload === null) {
            state.project = null
            return
        }

        if (payload.id) {
            state.project = new Project(payload)

            for (const i in state.projects) {
                if (state.projects[i].id === payload.id) {
                    state.projects[i] = state.project
                }
            }
        }
    },
    setTargets(state: ProjectState, payload: any): void {
        if (!payload._more) {
            state.targets = [...payload.payload].map((node) => new Target(node))
        } else {
            state.targets = [...state.targets, ...payload.payload].map((node) => new Target(node))
        }
    },
    setTarget: (state: ProjectState, payload: any): void => {
        const { id, props } = payload

        state.targets = state.targets.map((target) => {
            if (target.id === id) {
                return new Target({
                    ...target,
                    ...props,
                })
            }

            return target
        })
    },
    setProjects: (state: ProjectState, payload: any): void => {
        state.projects = [...payload].map((data) => new Project(data))
    },
    setProjectTarget: (state: ProjectState, payload: any): void => {
        const { projectId, newTarget, position } = payload

        const updateTarget = (proj: Project) => proj._targets.map((target) => {
            if (target.id !== newTarget.id) {
                return target
            }
            return newTarget
        })

        const isNewTarget = (proj: Project) => !proj._targets.find(({ id }) => id === newTarget.id)

        if (projectId) {
            const isEditPage = `${router.currentRoute.path}`.includes('edit')

            if (isEditPage) {
                if (isNewTarget(state.project)) {
                    state.project._targets.splice(position, 0, { ...newTarget, isNew: true })
                } else {
                    state.project._targets = updateTarget(state.project)
                }
            } else {
                const projectFromList = state.projects.find((project) => project.id === projectId)

                if (isNewTarget(projectFromList)) {
                    projectFromList._targets.splice(position, 0, { ...newTarget, isNew: true })
                } else {
                    projectFromList._targets = updateTarget(projectFromList)
                }
            }
        }
    },
    unsetTarget: (state: ProjectState, payload: any): void => {
        const { targetId, projectId } = payload
        const isEditPage = `${router.currentRoute.path}`.includes('edit')
        let delIndex = null
        let delTarget = null

        if (isEditPage) {
            delTarget = state.project._targets.find(({ id }) => id === targetId)
            delIndex = state.project._targets.indexOf(delTarget)
            state.project._targets.splice(delIndex, 1)
        } else {
            const projectFromList = state.projects.find((project) => project.id === projectId)
            delTarget = projectFromList._targets.find(({ id }) => id === targetId)
            delIndex = projectFromList._targets.indexOf(delTarget)
            projectFromList._targets.splice(delIndex, 1)
        }
        Toaster.show('info', 'Action supprimée', 3000)
    },
    setMoreProjects: (state: ProjectState, payload: any): void => {
        for (const i in payload) {
            state.projects.push(new Project(payload[i]))
        }
    },
    pushInProjects: (state: ProjectState, payload: any): void => {
        state.projects = [
            ...[new Project(payload)],
            ...state.projects.filter(({ id }) => id !== payload.id),
        ]
    },
    unsetProject: (state: ProjectState, delId: string): void => {
        state.projects = state.projects.filter(({ id }) => id !== delId)
        state.project = null
        if (router.currentRoute.path !== '/projects') {
            router.push('/projects')
        }
        Toaster.show('info', 'Projet supprimé', 3000)
    },
    setProjectFilter: (state: ProjectState, filter: any): void => {
        const { category, value } = filter
        state.listFilters = Object.assign(new ProjectListFilter(state.listFilters), { [category]: value })

        if (filter.category !== 'skip') {
            state.listFilters.skip = null
        }
    },

    setInventoryFilter: (state: ProjectState, filter: any): void => {
        const { category, value } = filter
        state.inventoryFilter = Object.assign(new ProjectListFilter(state.inventoryFilter), { [category]: value })

        if (filter.category !== 'skip') {
            state.inventoryFilter.skip = null
        }
    },

    removeProjectFilter: (state: ProjectState, category: any): void => {
        state.listFilters = Object.assign(new ProjectListFilter(state.listFilters), { [category]: new ProjectListFilter()[category] })
    },
    resetProjectFilters: (state: ProjectState): void => {
        state.listFilters = new ProjectListFilter({ limit: 25 })
    },
    setTargetAdvert: (state: ProjectState, payload: any): void => {
        const { targetId, projectId, advertId } = payload
        const isEditPage = `${router.currentRoute.path}`.includes('edit')

        if (isEditPage) {
            state.project._targets.find(({ id }) => id === targetId).advertId = advertId
        } else {
            const projectFromList = state.projects.find((project) => project.id === projectId)
            projectFromList._targets.find(({ id }) => id === targetId).advertId = advertId
        }
    },
    setProjectBriefing: (state: ProjectState, payload: any): void => {
        state.project._briefing = new Comment(payload)
    },
    setProjectComments: (state: ProjectState, payload: any): void => {
        if ('_comments' in state.project) {
            state.project._comments.push(new Comment(payload))
        } else {
            state.project = new Project({
                ...state.project,
                _comments: [new Comment(payload)],
            })
        }
    },
    setMonthInventory: (state: ProjectState, payload: any): void => {
        state.monthInventory = payload
    },
    setQuarterInventory: (state: ProjectState, payload: any): void => {
        state.quarterInventory = payload
    },
    removeProjectComments: (state: ProjectState, payload: any): void => {
        state.project = new Project({
            ...state.project,
            _comments: state.project._comments.filter(({ id }) => id !== payload.id),
        })
    },
    copyToClipboard: (state: ProjectState, payload: any): void => {
        state.clipboard = payload
    },
}

const actions = {
    async createProject({ commit, rootGetters }: any, payload: any): Promise<string> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = '/project'
        Console.logAction(`Vuex store action: < createProject > as POST ${path}`, 'post')

        const project = new Project(payload)

        try {
            const result = await Api.post(path, project)
            commit('setProject', result)
            commit('pushInProjects', result)
            return result.id
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },

    async checkInventoryAvailability({ commit }: any, payload: InventoryPayload): Promise<string> {
        const path = '/inventory/availability'
        try {
            const response = await Api.post(path, payload)
            return response
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },

    async getMonthInventory({ commit }: any, payload: Inventory[]): Promise<string> {
        const path = '/inventory'
        try {
            const response = await Api.post(path, payload)
            commit('setMonthInventory', response)
            return response
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async getQuarterInventory({ commit }: any, payload: Inventory[]): Promise<string> {
        const path = '/inventory'
        try {
            const response = await Api.post(path, payload)
            commit('setQuarterInventory', response)
            return response
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },

    async getModalQuarterInventory({ commit }: any, payload: Inventory[]): Promise<string> {
        const path = '/inventory'
        try {
            const response = await Api.post(path, payload)
            return response
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },

    async updateProject({ commit, rootGetters }: any, payload: any = {}): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }
        const path = '/project'
        Console.logAction(`Vuex store action: < updateProject > as PUT ${path}`, 'put')

        if (!payload.id) {
            commit('setProject', payload)
        } else {
            try {
                const result = await Api.put(path, payload)
                commit('setProject', result)
            } catch (error) {
                commit('err/fetchFailed', error, { root: true })
            }
        }
    },

    async loadProject({ commit }: any, id: number): Promise<void> {
        const path = `/project/${id}`
        Console.logAction(`Vuex store action: < loadProject > as GET ${path}`, 'get')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.get(path)
            commit('setProject', result)
        } catch (error) {
            commit('setProject', null)
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async loadTimeline(_, payload: any): Promise<any> {
        const path = '/projects/timeline'
        Console.logAction(`Vuex store action: < loadTimeline > as POST ${path}`, 'post')

        try {
            return await Api.post(path, payload)
        } catch (error) {
            return error
        }
    },

    async loadTargets({ commit }: any, payload: any): Promise<void> {
        const path = '/targets'
        Console.logAction(`Vuex store action: < loadTargets > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.post(path, payload)
            commit('setTargets', { payload: result, _more: payload._more })
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async loadProjects({ commit }: any, payload: any): Promise<void> {
        const path = '/projects'
        Console.logAction(`Vuex store action: <loadProjects> as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.post(path, payload)
            if (payload.skip > 0) {
                commit('setMoreProjects', result)
            } else {
                commit('setProjects', result)
            }
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async updateTargetProps({ commit }: any, payload: any): Promise<void> {
        const path = `/target/${payload.id}/properties`
        Console.logAction(`Vuex store action: < updateTargetProps > as PUT ${path}`, 'put')

        try {
            await Api.put(path, payload)
            commit('setTarget', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },

    async addCommentToProject({ commit }: any, payload: any): Promise<void> {
        const path = '/newcomment'
        Console.logAction(`Vuex store action: < addCommentToProject > as POST ${path}`, 'post')

        try {
            const result = await Api.post(path, payload)
            commit('setProjectComments', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },

    async createBriefing({ commit }: any, payload: any): Promise<string> {
        const path = '/briefing/create'
        Console.logAction(`Vuex store action: < createBriefing > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.post(path, payload)
            commit('setProjectBriefing', result)
            return result.content
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async updateBriefing({ commit }: any, payload: any): Promise<string> {
        const path = `/briefing/${payload.id}`
        Console.logAction(`Vuex store action: < updateBriefing > as PUT ${path}`, 'put')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.put(path, payload)
            commit('setProjectBriefing', result)
            return result.content
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async updateComment({ commit }: any, payload: any): Promise<void> {
        const path = `/comment/${payload.id}`
        Console.logAction(`Vuex store action: < updateComment > as PUT ${path}`, 'put')

        try {
            await Api.put(path, payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },

    async addNewTargetToProject({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const { projectId, target } = payload
        const path = `/project/${projectId}/newtarget`
        Console.logAction(`Vuex store action: < addNewTargetToProject > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result: Target = await Api.post(path, target)
            commit('setProjectTarget', {
                newTarget: new Target(result),
                projectId,
                position: 0,
            })
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async addNewAdvertToTarget({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const { targetId } = payload
        const path = `/target/${targetId}/newadvert`
        Console.logAction(`Vuex store action: < addNewAdvertToTarget > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            await Api.post(path, payload)
            commit('setTargetAdvert', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async duplicateTarget({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const { target, projectId, position } = payload
        const path = `/project/${projectId}/target/${target.id}/duplicate`
        Console.logAction(`Vuex store action: < duplicateTarget > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const result: Target = await Api.post(path, target)
            commit('setProjectTarget', {
                newTarget: new Target(result),
                projectId,
                position,
            })
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async deleteTarget({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const { targetId } = payload
        const path = `/target/${targetId}`
        Console.logAction(`Vuex store action: < deleteTarget > as DELETE ${path}`, 'delete')

        commit('xhr/isPending', true, { root: true })

        try {
            await Api.delete(path, targetId)
            commit('unsetTarget', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async deleteProject({ commit, rootGetters }: any, id: string): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = `/project/${id}`
        Console.logAction(`Vuex store action: < deleteProject > as DELETE ${path}`, 'delete')

        commit('xhr/isPending', true, { root: true })

        try {
            await Api.delete(path, id)
            commit('unsetProject', id)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async getFilterList({ commit }: any, payload: any): Promise<any> {
        const path = `/search/${payload.label}?limit=${payload.limit}&term=${payload.term || ''}&field=${payload.field || ''}`
        Console.logAction(`Vuex store action: < getFilterList > as GET ${path}`, 'get')

        try {
            const response = await Api.get(path)
            return response
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },

    async unsetAdvertRel({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = `/target/${payload}/unsetRel`
        Console.logAction(`Vuex store action: < unsetAdvertRel> as PUT ${path}`, 'put')

        try {
            await Api.put(path)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },

    async importEstimateForNewProject({ commit, rootGetters }: any, payload: any): Promise<string> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const { id, keyAccount, name, actor } = payload

        const path = `/project/estimate/${id}`
        Console.logAction(`Vuex store action: < importEstimateForNewProject > as GET ${path}`, 'get')

        try {
            const result = await Api.get(path, { name, actor, keyAccount })
            if (result) {
                commit('pushInProjects', result)
                return (result.id)
            }
            return (null)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async importEstimateForExistantProject({ commit, rootGetters }: any, payload: any): Promise<any> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const { estimateId, projectId } = payload

        const path = `/project/${projectId}/estimate/${estimateId}`
        Console.logAction(`Vuex store action: < importEstimateForExistantProject > as PUT ${path}`, 'put')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.put(path)
            if (result) {
                commit('setProject', result)
            }
            return true
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async createTargetsComment({ commit }: any, payload: any): Promise<Comment> {
        const path = '/targets/comment'
        Console.logAction(`Vuex store action: < createTargetsComment > as POST ${path}`, 'post')

        try {
            return await Api.post(path, payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },

    async updateTargetsComment({ commit }: any, payload: any): Promise<Comment> {
        const path = `/targets/comment/${payload.id}`
        Console.logAction(`Vuex store action: < updateTargetsComment> as PUT ${path}`, 'put')

        try {
            return await Api.put(path, payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },

    async deleteTargetComment({ commit }: any, payload: any): Promise<string> {
        const path = `/targets/comment/${payload.id}`
        Console.logAction(`Vuex store action: < deleteTargetComment> as DELETE ${path}`, 'delete')

        try {
            return await Api.delete(path)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },

    async uploadInventary({ commit }: any, payload: any): Promise<void> {
        const path = '/inventory/upload'
        Console.magenta('Vuex store action: < uploadInventory > as put path')

        commit('xhr/isPending', true, { root: true })

        try {
            const inventory = await Api.put(path, payload)
            commit('setMonthInventory', inventory)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },

    async exportInventoryCsv({ commit }: any, payload: any): Promise<void> {
        const path = '/inventory'
        try {
            const response = await Api.post(path, payload)
            return response
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
