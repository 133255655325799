
import Vue from 'vue'

export default Vue.extend({
    name: 'AudienceDeleteModal',
    props: ['audience'],
    methods: {
        openAdvert(id: number) {
            this.$router.push(`/adverts/edit/${id}`)
        },
        confirm() {
            this.$emit('confirm')
        },
        close() {
            this.$emit('close')
        },
    },
})
