
import Vue from 'vue'
import { AREAS_REF, arrayRef } from '@/models/refs'

export default Vue.extend({
    name: 'AreaSelector',
    props: ['value'],
    data() {
        return {
            selected: 'french',
            options: arrayRef(AREAS_REF),
        }
    },
    mounted() {
        this.selected = this.value
    },
    methods: {
        change() {
            this.$emit('onChange', this.selected)
        },
    },
})
