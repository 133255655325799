
import Vue from 'vue'
import { mapState } from 'vuex'
import inputDeeplinkGen from '@/components/inputs/inputDeeplinkGen.vue'
import InputText from '@/components/inputs/InputText.vue'
import { State } from '@/store'

export default Vue.extend({
    name: 'SourceFormMedics',
    components: {
        inputDeeplinkGen,
        InputText,
    },
    computed: {
        ...mapState({
            deeplink: (state: State) => state.deeplink.deeplink,
        }),
    },
    data() {
        return {
            deeplinkData: {
                idInputValue: '',
                sectionInputValue: '',
            },
            error: {
                idInputValue: {
                    isShow: false,
                    message: "Lettres, chiffres, '-' et '_' uniquement",
                },
                sectionInputValue: {
                    isShow: false,
                    message: "Lettres, chiffres, '-' et '_' uniquement",
                },
            },
        }
    },
    created() {
        this.deeplinkData = {
            idInputValue: this.deeplink.firstParams ?? '',
            sectionInputValue: this.deeplink.secondParams ?? '',
        }
    },
    methods: {
        setInputValue(event: string, input?: string) {
            if (input === 'id') {
                this.deeplinkData.idInputValue = event
                this.$emit('isNotEmpty', this.showError(event))
                this.error.idInputValue.isShow = !this.showError(event) && event.length
            } else {
                this.deeplinkData.sectionInputValue = event
                this.error.sectionInputValue.isShow = !this.showError(event) && event.length
            }
        },
        showError(event) {
            const regexp = /^[a-zA-Z0-9_-]+$/
            return regexp.test(event)
        },
    },
})
