
import Vue from 'vue'
import { mapState } from 'vuex'
import { Target } from 'lib-ad-platform-models'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import ProjectItem from '@/views/partials/ProjectItem.vue'
import ProjectListHead from '@/views/partials/ProjectListHead.vue'
import SearchAdvertModal from '@/views/partials/SearchAdvertModal.vue'
import DelayReasonModal from '@/views/partials/DelayReasonModal.vue'
import FeedbacksDateModal from '@/views/partials/FeedbacksDateModal.vue'
import CommentSlideBar from '@/views/partials/CommentSlideBar.vue'
import Timeline from '@/components/Timeline.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import EventBus from '@/components/EventBus'

export default Vue.extend({
    name: 'ProjectEditPage',
    components: {
        ProjectListHead,
        ProjectItem,
        SearchAdvertModal,
        CommentSlideBar,
        DelayReasonModal,
        FeedbacksDateModal,
        Timeline,
        ConfirmModal,
    },
    computed: {
        ...mapState({
            loading: (state: State) => state.xhr.loading,
            project: (state: State) => state.proj.project,
            filters: (state: State) => state.proj.listFilters,
            professions: (state: State) => state.meta.professions,
        }),
    },
    data() {
        return {
            targetId: null,
            target: null,
            projectId: null,
            justChanged: null,
            isCommentsOpen: false,
            isCommentsEditOn: false,
            timelineProjectId: null,
            estimateId: null,
        }
    },
    created() {
        this.$store.dispatch('proj/loadProject', this.$route.params.id)
        if (this.$route.hash === '#comment') {
            setTimeout(() => { this.isCommentsOpen = true }, 500)
        }

        document.addEventListener(
            'click',
            () => {
                if (this.isCommentsOpen && !this.isCommentsEditOn) {
                    this.isCommentsOpen = false
                }
            },
            false,
        )
    },
    mounted() {
        EventBus.$on('openFdbksModal', (target: Target) => {
            this.target = target
            this.$modal.show('fdbks-date')
        })

        EventBus.$on('openConfirmCancelTarget', (target: Target) => {
            this.target = target
            this.$modal.show('cancelTarget')
        })

        EventBus.$on('openDelayReasonModal', (target: Target) => {
            this.target = target
            this.$modal.show('delay-reason')
        })
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        openTimeline() {
            this.timelineProjectId = this.project.id
        },
        closeTimeline() {
            this.timelineProjectId = null
        },
        deleteModal(isOpen: boolean) {
            if (isOpen) {
                this.$modal.hide('confirm-delete-project')
            } else {
                this.$modal.show('confirm-delete-project')
            }
        },
        deleteProject() {
            this.$store.dispatch('proj/deleteProject', this.$route.params.id)
            this.deleteModal(true)
        },
        openAdvertModal({ target, projectId }) {
            this.target = target
            this.projectId = projectId
            this.$modal.show('search-advert')
        },
        closeSearchAdvertModal() {
            this.$modal.hide('search-advert')
        },
        closeDelayReasonModal() {
            this.$modal.hide('delay-reason')
        },
        ifEnter(e: KeyboardEvent, field: string) {
            if (e.code !== 'Enter') {
                return
            }
            e.preventDefault()
            this.updateProject(e, field)
        },
        updateProject(e: any, field: string) {
            this.justChanged = field
            setTimeout(() => {
                this.justChanged = null
            }, 320)
            e.target.blur()
            this.$store.dispatch('proj/updateProject', {
                id: this.project.id,
                [field]: e.target.innerText,
            })
        },
        openComments(e) {
            e.stopPropagation()
            this.isCommentsOpen = true
        },
        closeComments() {
            this.isCommentsOpen = false
        },
        setCommentsEditStatus(status: boolean) {
            this.isCommentsEditOn = status
        },
        toggleEstimateModal(show: boolean) {
            return show
                ? this.$modal.show('sellsy-import')
                : this.$modal.hide('sellsy-import')
        },
        importEstimate() {
            this.$modal.hide('sellsy-import')
            this.$store
                .dispatch('proj/importEstimateForExistantProject', {
                    estimateId: this.estimateId,
                    projectId: this.project.id,
                })
                .then((res) => {
                    if (!res) {
                        Toaster.show('error', 'No estimate found with this ID', 2000)
                    }
                    Toaster.show('success', 'estimate imported with success!', 3000)

                    this.estimateId = null
                })
                .catch((error) => {
                    Toaster.show('error', error.response.data.errorMsg, 3000)
                })
        },
        cancelTarget() {
            this.target.status = '7. annulé'
            this.target.releaseDateDiff = null
            this.target.delayReason = null
            this.target.revenueDelayImpact = null

            const { status, releaseDateDiff, delayReason, revenueDelayImpact } = this.target

            this.$store.dispatch('proj/updateTargetProps', {
                id: this.target.id,
                props: {
                    status,
                    releaseDateDiff,
                    delayReason,
                    revenueDelayImpact,
                },
            })
        },
        updateTarget(props: any) {
            Object.keys(props).forEach((key) => {
                this.target[key] = props[key]
            })

            this.$store.dispatch('proj/updateTargetProps', {
                id: this.target.id,
                props,
            }).then(() => this.$modal.hide('fdbks-date'))
        },
    },
})
