
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { dayjs } from '@/utils/dayjs'
import { populationCategoriesMapToId } from '@/utils/projectFilters'
import { Toaster } from '@/components/Toaster'

Vue.component('v-select', vSelect)

export default Vue.extend({
    name: 'InventoryDisponibilityCheckModal',
    data() {
        return {
            populationCategoriesMapToId,
            isModalOpen: false,
            filters: {
                populationCategorie: '',
                startDate: '',
                endDate: '',
                soldViews: null,
            },
            formatedFilters: {
                from: '',
                to: '',
                specialty: [] as string[],
                profession: [] as string[],
                soldViews: null,
            },
            isAvailable: null,
            extraViews: null,
            isLoading: false,
            noAvaibleMonthsArray: '',
            displaNotAvaibleMonths: false,
            displayAvaibleMesage: false,
            soldViews: '',
        }
    },

    methods: {
        openModal() {
            this.isModalOpen = true
        },
        closeModal() {
            this.isModalOpen = false
            this.filters = {
                populationCategorie: '',
                startDate: '',
                endDate: '',
                soldViews: null,
            }
            this.formatedFilters = {
                from: '',
                to: '',
                specialty: [] as string[],
                profession: [] as string[],
                soldViews: null,
            }
            this.isAvailable = null
            this.displaNotAvaibleMonths = false
            this.displayAvaibleMesage = false
        },
        formate() {
            this.filters.populationCategorie = this.populationCategoriesMapToId[this.filters.populationCategorie]
        },
        formatePopulationCategorie(populationCategorie: { name: string, id: string, label: string }) {
            if (populationCategorie.label === 'Specialty') {
                this.formatedFilters.specialty = [populationCategorie.id]
            } else {
                this.formatedFilters.profession = [populationCategorie.id]
            }
        },

        formatedDate() {
            this.formatedFilters.from = `${this.filters.startDate.split('-')[0].substr(-2)}/${this.filters.startDate.split('-')[1]}`
            this.formatedFilters.to = `${this.filters.endDate.split('-')[0].substr(-2)}/${this.filters.endDate.split('-')[1]}`
        },
        formatNumberInput(event: string) {
            // keep only numbers
            let pardedString = event.replace(/[^0-9]/g, '')
            // adding space every 3 digits
            pardedString = event.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            this.filters.soldViews = (pardedString)
        },
        checkForEmptyFilterAttribute(filters) {
            const isEmpty = !Object.values(filters).some((x) => x === null || x === '')
            return (isEmpty)
        },
        checkFordatesOverlap(from : Date, to :Date) : boolean {
            return (from <= to)
        },
        isNumber(evt: KeyboardEvent): void {
            const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
            const keyPressed: string = evt.key

            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
        },
        submit() {
            if (this.checkForEmptyFilterAttribute(this.filters)) {
                if (this.checkFordatesOverlap(this.filters.startDate, this.filters.endDate)) {
                    this.isLoading = true
                    this.formatePopulationCategorie(this.filters.populationCategorie)
                    this.formatedDate()
                    this.formatedFilters.soldViews = this.filters.soldViews
                    this.$store
                        .dispatch('proj/checkInventoryAvailability', this.formatedFilters, 10)
                        .then((response) => {
                            this.isAvailable = response.every((month) => month.isAvailable)
                            if (this.isAvailable) {
                                const minMonthView = response.reduce((min, month) => ((month.viewDifference < min.viewDifference) ? month : min), response[0].viewDifference)
                                const soldViews = Number(this.filters.soldViews)
                                const totalMonths = response.length
                                if (minMonthView >= soldViews) {
                                    this.displayAvaibleMesage = false
                                    this.displaNotAvaibleMonths = false
                                } else {
                                    this.displayAvaibleMesage = true
                                    this.displaNotAvaibleMonths = false
                                    this.extraViews = minMonthView * totalMonths
                                }
                            } else {
                                this.displaNotAvaibleMonths = true
                                this.displayAvaibleMesage = false
                                this.noAvaibleMonthsArray = response.filter((month) => !month.isAvailable).map((month) => dayjs(month.date, 'YY/MM').locale('fr').format('MMMM')).join(', ')
                            }
                        })
                        .catch((error) => {
                            Toaster.show('error', error.response.data.errorMsg, 3000)
                        }).finally(() => {
                            this.isLoading = false
                        })
                } else {
                    this.isLoading = false
                    Toaster.show('error', 'La date de début ne peut-être suppérieur à la date de fin', 3000)
                }
            } else {
                this.isLoading = false
                Toaster.show('error', 'Veuillez remplir tous les champs', 3000)
            }
        },
        key() {
            return (option) => {
                return `${option.name}/${option.id}`
            }
        },
    },

})
