
import Vue from 'vue'
import { mapState } from 'vuex'
import { Target, TargetGoal } from 'lib-ad-platform-models'
import { debounce } from 'lodash'
import * as uuid from 'uniqid'
import { State } from '@/store'
import { trans } from '@/filters/trans.filter'
import { highlight } from '@/filters/highlight.filter'
import { normalDate } from '@/filters/simpday.filter'
import { replaceWord } from '@/filters/replaceWord.filter'
import ProjectActiveFilterList from '@/views/partials/ProjectActiveFilterList.vue'
import CellDatePicker from '@/components/CellDatePicker.vue'
import CellSelectPicker from '@/components/CellSelectPicker.vue'
import CellInputPicker from '@/components/CellInputPicker.vue'
import CellAdvertIdPicker from '@/components/CellAdvertIdPicker.vue'
import SelectFiltersDropdown from '@/components/SelectFiltersDropdown.vue'
import CellGoalInputPicker from '@/components/CellGoalInputPicker.vue'
import { DATE_TYPE_COLS } from '@/models/date-type-cols.model'
import { NUM_TYPE_COLS } from '@/models/num-type-cols.model'
import { SELECT_TYPE_COLS } from '@/models/select-type-cols.model'
import { HIGHLIGHTABLE_COL_HEADERS_ON_SEARCH } from '@/models/highlightable-cols.model'
import ExportToCSV from '@/components/ExportToCSV.vue'
import { Toaster } from '@/components/Toaster'
import SelectFilter, { SelectFilterSelection } from '@/components/SelectFilter.vue'

export default Vue.extend({
    name: 'ProjectListPage',
    filters: { normalDate, trans, highlight, replaceWord },
    components: {
        CellDatePicker,
        CellInputPicker,
        CellAdvertIdPicker,
        CellSelectPicker,
        CellGoalInputPicker,
        ProjectActiveFilterList,
        SelectFiltersDropdown,
        ExportToCSV,
        SelectFilter
    },
    computed: {
        ...mapState({
            loading: (state: State) => state.xhr.loading,
            targets: (state: State) => state.proj.targets,
            csm: (state: State) => state.meta.csm
        }),
        targetsWithGuid() {
            return this.targets.map((target) => {
                return { ...target,
                    key: target.id + uuid() }
            })
        },
    },
    data() {
        return {
            myPrice: 33,
            cols: [
                'projectName',
                'advertId',
                'actionCode',
                'status',
                'type',
                'populationCategory',
                'populationSample',
                'populationSize',
                'startDate',
                'endDate',
                'goal',
                'checkDate',
            ],
            DATE_TYPE_COLS,
            NUM_TYPE_COLS,
            SELECT_TYPE_COLS,
            highlightable: HIGHLIGHTABLE_COL_HEADERS_ON_SEARCH,
            userIsTypingInSearch: false,
            activeFilters: {
                status: [
                    '2. en ligne',
                    '3. stats à sortir',
                    '3.1 Urgent - Stats à sortir',
                    '3.2 stats intermédiaires',
                ],
            },
            filters: {
                order: 'DESC',
                orderBy: 'status',
                limit: 25,
                skip: 0,
                term: '',
            },
            resetCSVDataCount: 0,
            actionsToCopy: [],
        }
    },
    created() {
    // load user settings first
        this.refresh()
    },
    mounted() {
        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
                // do something
            }
        })
    },
    methods: {
        isLinkTo(type: string) {
            if (['mise en avant', 'référencement'].includes(type)) {
                return 'tool'
            }
            return 'advert'
        },
        defClass(col: string) {
            if (['advertId', 'populationSize'].indexOf(col) > -1) {
                return 'small'
            }

            if (['actionCode', 'populationCategory'].indexOf(col) > -1) {
                return 'large'
            }

            return null
        },
        getFormatedValue(value: string|number, col: string) {
            if (['goal'].includes(col)) {
                return this.getGoalValue(value)
            } if (['startDate', 'endDate', 'checkDate'].includes(col) && typeof value === 'number') {
                return Intl.DateTimeFormat('fr').format(parseInt(`${value}`) * 1000)
            }
            return value
        },
        getGoalValue(value: string) {
            const goal = new TargetGoal(JSON.parse(value))
            return `${goal.getValue()} ${goal.getType()}`
        },
        setActiveFilters(col: string, value: string) {
            this.activeFilters[col] = value
            this.refresh()
        },
        removeActiveFilters(col: string, value: string) {
            if (value) {
                this.activeFilters[col] = this.activeFilters[col].filter(
                    (item) => item !== value,
                )
            } else {
                delete this.activeFilters[col]
            }
            this.refresh()
        },
        refresh(more = false) {
            this.$store.dispatch('proj/loadTargets', {
                fields: [...this.cols, 'toolId'],
                limit: this.filters.limit,
                skip: this.filters.skip,
                term: this.filters.term,
                orderBy: this.filters.orderBy,
                order: this.filters.order,
                filters: this.activeFilters,
                _more: more,
            })
            this.resetCSVDataCount += 1
        },
        onSearchByTerm(term: string) {
            this.filters.term = term
            this.refresh()
        },
        onSearchFocus(term: string) {
            if (term !== '') {
                // highlight searchable columns
                this.userIsTypingInSearch = true
            }
        },
        onSearchBlur() {
            this.userIsTypingInSearch = false
        },
        anyFilterItemIsSelected(col: string) {
            return (
                this.activeFilters[col] !== undefined
        && this.activeFilters[col].length > 0
            )
        },
        orderBy(col: string, order: string) {
            this.filters.orderBy = col
            this.filters.order = order
            this.refresh()
        },
        onCellSelectChange(value: string, col: string, target: Target) {
            target[col] = value
            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props: { [col]: value },
            })
        },
        onCellTextChange(value: string, col: string, target: Target) {
            target[col] = value
            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props: { [col]: value },
            })
        },
        onCellGoalChange(value: TargetGoal, col: string, target: Target) {
            target[col] = value
            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props: { [col]: value },
            })
        },
        onTest(epoch: number, col: string, target: Target) {
            target[col] = epoch
            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props: { [col]: epoch },
            })
        },
        saveIfEnterOrBlur(e: any, col: string, target: Target) {
            if (e.code !== 'Enter') {
                return
            }

            e.target.blur()
            target[col] = e.target.innerText

            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props: { [col]: e.target.innerText },
            })
        },
        onScroll: debounce(function (e: any) {
            if (
                e.srcElement.offsetHeight + e.srcElement.scrollTop + 100
          >= e.srcElement.scrollHeight
        && !this.loading
        && this.targets.length % this.filters.limit === 0
            ) {
                this.filters.skip += this.filters.limit
                this.refresh(true)
            }
        }, 150),
        openProject(target: Target, withComments: boolean) {
            const routeData = withComments ? this.$router.resolve(`/project/edit/${target.projectId}#comment`) : this.$router.resolve(`/project/edit/${target.projectId}`)
            window.open(routeData.href, '_blank')
        },
        checkedTargetToCopy(actionCode: string) {
            if (this.actionsToCopy.some((item) => item === actionCode)) {
                const index = this.actionsToCopy.indexOf(actionCode)
                this.actionsToCopy.splice(index, 1)
            } else {
                this.actionsToCopy.push(actionCode)
            }
        },
        generateSheet() {
            const excelData = this.targets
                .filter(({ actionCode }) => this.actionsToCopy.some((item) => item === actionCode))
                .map((target) => {
                    return this.cols.map((col) => {
                        const value = col === 'goal'
                            ? JSON.stringify(target[col])
                            : target[col]

                        return this.getFormatedValue(value, col)
                    }).join('\t')
                })
                .join('\n')

            return excelData
        },
        onCopy() {
            Toaster.show('info', 'Actions copied', 2000)
            this.actionsToCopy = []
        },
        onError() {
            Toaster.show('error', 'Failed to copy actions', 2000)
            this.actionsToCopy = []
        },
    },
})
