
import Vue from 'vue'
import { capitalize } from '@/filters/capitalize.filter'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'GlobalToaster',
    filters: { capitalize },
    props: {
        type: { type: String, default: 'success' },
        message: { type: String, default: 'No message' },
    },
    data() {
        return {}
    },
    methods: {
        close() {
            Toaster.hide()
        },
    },
})
