
import Vue from 'vue'
import { debounce } from 'debounce'
import { mapState } from 'vuex'
import Api from '@/api/api'

export default Vue.extend({
    name: 'AudienceUserTestTool',
    components: { },
    props: ['audience'],
    data() {
        return {
            term: '',
            userSelected: null,
            searchResults: [],
            audienceResults: { audiences: [], adverts: [] },
            userDropdownVisible: false,
            testLoading: false,
            dropdownLoading: false,
            isInList: false,
        } as any
    },
    computed: mapState({

    }),
    methods:
    {
        debounceInput: debounce(function (e) {
            if (e.target.value.length < this.term.length) {
                this.unselectUser()

                if (e.target.value.length === 0) {
                    return
                }
            }

            this.dropdownLoading = true

            this.term = e.target.value
            this.userDropdownVisible = true

            Api.get(`/users?email=${this.term}`)
                .then((r) => {
                    this.searchResults = r
                    this.dropdownLoading = false
                }).catch(() => {
                    // yeah
                })
        }, 1000),
        goto(url) {
            this.$router.push(url)
        },
        toggleUserDropdown() {
            this.userDropdownVisible = false
        },
        unselectUser() {
            this.term = ''
            this.userSelected = null
            this.searchResults = []
            this.storiesResults = []
            this.audienceResults = { audiences: [], adverts: [] }
        },
        selectUser(user: any) {
            this.userSelected = user
            this.refresh()
        },
        refresh() {
            if (this.userSelected === null) {
                return
            }

            this.audienceResults = { audiences: [], adverts: [] }

            // make the post + get adverts requests (if search: post, if timeline get)
            let promise: any
            this.isInList = false
            this.userDropdownVisible = false
            this.testLoading = true
            this.term = this.userSelected.email

            if (this.audience.type === 'search') {
                const keyword = this.audience.relationships.find(({ label }) => label === 'Keyword')?.name || ''
                promise = Api.post('/advert', { userId: this.userSelected.id, type: 'search', keywords: [keyword], tab: 'all', limit: 50, withAudience: true })
            } else if (['timeline', 'tool', 'story'].includes(this.audience.type)) {
                const type = this.audience.type === 'timeline'
                    ? ['post', 'quiz', 'video']
                    : this.audience.type

                promise = Api.post('/v2/advert', { userId: this.userSelected.id, types: type })
            } else {
                promise = Promise.resolve([])
            }

            promise.then((r) => {
                this.audienceResults.audiences = this.reOrderWithCurrentAudienceFirst(r.audiences)
                this.audienceResults.adverts = r.adverts
                this.testLoading = false
            }).catch(() => {
                this.testLoading = false
            })
        },
        reOrderWithCurrentAudienceFirst(list: Array<any>) {
            const finalList = [...list]

            for (const i in list) {
                if (this.audience.id === list[i]) {
                    const currAudience = list[i]
                    finalList.splice(parseInt(i), 1)
                    finalList.unshift(currAudience)
                    this.isInList = true
                }
            }

            return finalList
        },
    },
    mounted() {
        document.addEventListener('click', () => {
            this.userDropdownVisible = false
        }, false)
    },
})
