/* eslint-disable */
import axios from 'axios'
import store from '@/store'
import router from '@/router'

export const ANONYMOUS_ROUTES = ['/login', '/connect', '/password/confirm']
export const PUBLIC_ENDPOINTS = ['/authenticate', '/password/confirm', '/password/reset', '/authenticate/merge', '/authenticate/refresh', `${process.env.VUE_APP_PULSE_API_URL}/rest/app/list`]

const arraysIntersect = (array1: Array<any>, array2: Array<any>) => {
    return array2.some((value) => array1.includes(value))
}

export default function axiosSetup() {
    // point to your API endpoint
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    // Add a request interceptor
    axios.interceptors.request.use(async (config) => {
        // Do something before request is sent
        const accessToken = store.getters['user/getAccessJwt']
        const now = Math.floor(Math.floor(new Date().getTime() / 1000))

        // Refresh token if it expires in less than 10 seconds (or if it has already expired)
        const mustRefreshToken = accessToken ? (accessToken.expiration - now) < 0 : true

        const path = config.url.replace(config.baseURL, '')

        // Don't refresh token if the user authenticates
        // const isPublicPage = path.includes('/authenticate')
        // looks at the XHR endpoint called via AJAX
        const isPublicEndpoint = arraysIntersect(PUBLIC_ENDPOINTS, path.split(','))

        if (!isPublicEndpoint) {
            config.headers.Authorization = `Bearer ${accessToken.token}`

            if (mustRefreshToken) {
                try {
                    const tokens = await store.dispatch('user/refreshToken')
                    store.commit('user/setAuthenticatedUser', tokens)
                    config.headers.Authorization = `Bearer ${tokens.accessToken.token}`
                } catch (e) {
                    // @fix: send errors somewhere
                }
            }
        }

        return config
    }, (error) => {
        return Promise.reject(error)
    })

    axios.interceptors.response.use((response) => {
        return response
    }, (error) => {
        if (error.response.status === 401 && !ANONYMOUS_ROUTES.includes(router.currentRoute.path)) {
            router.push('/login')
        }
        return Promise.reject(error)
    })
}
