
import Vue from 'vue'
import { mapState } from 'vuex'
import { highlight } from '@/filters/highlight.filter'
import { UIKitStuff } from '@/models/MiscTypes'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import { formatThousands } from '@/filters/formatThousands.filter'
import { displayInput } from './CellDisplay.vue'

declare const UIkit: UIKitStuff

export default Vue.extend({
    name: 'CellInputPicker',
    filters: { highlight, formatThousands },
    props: {
        center: {
            type: Boolean,
            default: false,
        },
        isNum: {
            type: Boolean,
            default: () => false,
        },
        isAmount: {
            type: Boolean,
            default: () => false,
        },
        value: [String, Number],
        col: String,
        highlightable: {
            type: Boolean,
            default: () => false,
        },
        autocomplete: {
            type: Boolean,
            default: () => false,
        },
        ukLabelClass: String,
    },
    computed: {
        ...mapState({
            clipboard: (state: State) => state.proj.clipboard,
        }),
    },
    data() {
        return {
            justChanged: false,
            previousValue: this.value,
            autocompleteList: [],
            ctrlDown: false,
            hover: false,
        }
    },
    mounted() {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Control' || e.key === 'Meta') {
                this.ctrlDown = true
            }
        })

        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key === 'Control' || e.key === 'Meta') {
                this.ctrlDown = false
            }
        })

        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'c' && this.ctrlDown && this.hover) {
                Toaster.show('success', 'Copy to clipboard!', 1000)
                this.$store.commit('proj/copyToClipboard', {
                    category: this.col,
                    value: this.value,
                })
            }

            if (e.key === 'v' && this.ctrlDown && this.hover && this.clipboard) {
                const { category, value } = this.clipboard

                if (category === this.col && category !== 'projectName') {
                    this.$emit('change', value)
                }
            }
        })
    },
    methods: {
        displayValue: displayInput,
        keyup(e: any) {
            if (!this.autocomplete || this.isNum) {
                return
            }

            const term = e.target.innerText.trim()

            if (term === '') {
                return
            }

            // first load data accordingly
            this.$store
                .dispatch('proj/getFilterList', {
                    label: 'Target',
                    term,
                    field: this.col,
                    limit: 5,
                })
                .then((list: any) => {
                    this.autocompleteList = list

                    if (list.length > 0) {
                        UIkit.dropdown(this.$refs.dropdownRef).show()
                    } else {
                        UIkit.dropdown(this.$refs.dropdownRef).hide()
                    }
                })
        },
        autocompleteFill(value: string) {
            UIkit.dropdown(this.$refs.dropdownRef).hide()
            this.submit(value)
        },
        onEnter(e: any) {
            if (e.code !== 'Enter') {
                return
            }

            UIkit.dropdown(this.$refs.dropdownRef).hide()
            e.preventDefault()
            e.target.blur()
            this.submit(e.target.innerText)
        },
        onBlur(e: any) {
            this.submit(e.target.innerText)
        },
        onFocus(e: any) {
            if (e.target.innerText === '--') {
                e.target.innerText = ''
                return
            }
            if (this.isNum) {
                e.target.innerText = this.isAmount
                    ? parseFloat(e.target.innerText.replace(/[€\s]/g, ''))
                    : parseInt(e.target.innerText.replace(' ', ''))
            }
        },
        submit(value: string) {
            if (value === '--') {
                return
            }

            const currentValue = value
            if (this.previousValue !== currentValue) {
                this.justChanged = true
            }

            setTimeout(() => {
                this.justChanged = false
            }, 320)

            this.$emit('change', value)
        },
    },
})
