
import Vue from 'vue'
import { mapState } from 'vuex'
import { PushPayload, PushWhereCriterias } from '@/api/PushWhereNormalizer'
import AreaSelector from '@/views/partials/push/AreaSelector.vue'
import TitleSelector from '@/views/partials/push/TitleSelector.vue'
import ProfessionSelector from '@/views/partials/push/ProfessionSelector.vue'
import SpecialtySelector from '@/views/partials/push/SpecialtySelector.vue'
import ExpertSelector from '@/views/partials/push/ExpertSelector.vue'
import OsSelector from '@/views/partials/push/OsSelector.vue'
import { State } from '@/store'

async function delay(ms: number): Promise<boolean> {
    return new Promise((resolve) => {
        setTimeout(() => resolve(true), ms)
    })
}

export default Vue.extend({
    name: 'PushNotificationCreatePage',
    components: { AreaSelector, TitleSelector, ExpertSelector, ProfessionSelector, SpecialtySelector, OsSelector },
    data() {
        return {
            offset: 0,
            limit: 1000,
            total: 0,
            iterations: 0,
            customId: 'custom01',
            notification: {
                title: 'Mise à jour en attente 🔁',
                body: 'Accédez aux dernières recommandations HAS en mettant à jour votre application.',
                navigation: 'https://360medics.app.link/e/c44vVzHdBrb',
            },
            where: {
                area: 'french',
                titles: [],
                professions: [],
                specialties: [],
                expert: 'all',
                security: 'email LIKE \'%@360medics.com\'',
                iterations: 0,
                os: [],
            } as PushWhereCriterias,
        }
    },
    computed: {
        ...mapState({
            counter: (state: State) => state.push.count,
            loading: (state: State) => state.push.loading,
            sending: (state: State) => state.push.sending,
            progress: (state: State) => state.push.progress,
        }),
    },
    methods: {
        count() {
            this.$store.dispatch('push/progress', 0)
            this.$store.dispatch('push/count', this.where)
                .then(() => {
                    this.iterations = this.countIterations()
                })
        },
        async send() {
            // this.iterations = this.countIterations()
            this.$store.dispatch('push/progress', 0)
            this.$store.dispatch('push/sendStart', true)

            const promises = []

            for (let i = 0; i < this.iterations; i += 1) {
                const payload: PushPayload = {
                    customId: this.customId,
                    notification: this.notification,
                    where: this.where,
                    limit: this.limit,
                    offset: this.offset,
                    $iteration: (i + 1),
                    $iterations: this.iterations,
                }
                /* eslint-disable-next-line */
                await delay(1800)
                promises.push(this.$store.dispatch('push/send', payload))
                this.offset += this.limit
            }

            await Promise.all(promises)
            this.$store.dispatch('push/sendDone', true)

            // warn its finished, reset stuff, and display custom ID
            // this.$store.dispatch('push/progress', 0)

            // of course, reset offset when all sends are finished !
            this.offset = 0
        },
        criteriaChanged(key: string, value: any) {
            this.where[key] = value
        },
        countIterations() {
            if (this.counter === 0) {
                return 0
            }
            return (this.counter <= this.limit) ? 1 : Math.ceil(this.counter / this.limit)
        },
    },
})
