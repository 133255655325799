
import Vue from 'vue'
import { mapState } from 'vuex'
import ConfirmModal from '@/components/ConfirmModal.vue'
import { Toaster } from '@/components/Toaster'
import ExportToCSV from '@/components/ExportToCSV.vue'

export default Vue.extend({
    name: 'GroupListPage',
    components: { ConfirmModal, ExportToCSV },
    data() {
        return {
            groupName: null,
            prevRoute: null,
            params: {
                limit: 25,
                skip: 0,
                term: null,
            },
            scrollToBottom: false,
            groupToDelete: {
                name: null,
                id: null,
            },
            resetCSVDataCount: 0,
        }
    },
    computed: mapState({
        loading: (state: any) => state.xhr.loading,
        groups: (state: any) => state.audn.groups,
    }),
    created() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.$store.dispatch('audn/loadGroups', { params: this.params })
            this.resetCSVDataCount += 1
        },
        onScroll() {
            this.scrollToBottom = (this.$refs.page.scrollTop + this.$refs.page.offsetHeight) >= this.$refs.page.scrollHeight

            if (this.scrollToBottom) {
                this.params.skip += this.params.limit
                this.scrollToBottom = false
                this.refresh()
            }
        },
        filterByTerm() {
            this.params.skip = 0
            this.params.term = this.$refs.termInput.value
            this.refresh()
        },
        resetTerm() {
            this.params.skip = 0
            this.params.term = null
            this.$refs.termInput.value = null
            this.refresh()
        },
        toggleNewGroupModal(open: boolean) {
            this.groupName = null
            return open
                ? this.$modal.show('newGroup')
                : this.$modal.hide('newGroup')
        },
        async createGroup() {
            const groupId = await this.$store.dispatch('audn/createGroup', { name: this.groupName })
            this.toggleNewGroupModal(false)
            this.$router.push(`/group/OrGroup/${groupId}`)
        },
        duplicateGroup(id: string, name: string) {
            this.$store.dispatch('audn/duplicateGroup', { id, name }).then(() => {
                this.refresh()
            })
        },
        showDeleteModal(id: string, name: string) {
            this.groupToDelete = { id, name }
            this.$modal.show('delete')
        },
        async deleteGroup() {
            const { id, name } = this.groupToDelete
            const result = await this.$store.dispatch('audn/deleteGroup', { id, name })
            Toaster.show('success', result, 2000)
            this.groupToDelete = { name: null, id: null }
        },
    },
})
