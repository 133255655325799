
import Vue from 'vue'
import uniqid from 'uniqid'
import { Advert, Article } from 'lib-ad-platform-models'
import { Toaster } from '@/components/Toaster'
import { AttachmentUpload } from '@/components/AttachmentUpload'

export default Vue.extend({
    name: 'NotificationPreview',
    props: {
        artefact: { type: Object, default: null },
        editable: { type: Boolean, default: false },
    },
    data() {
        return {
            isAdvert: false,
        }
    },
    mounted() {
        this.isAdvert = this.$route.fullPath.includes('/adverts/')
    },
    methods: {
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()
            if (!this.editable) {
                return
            }

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onLogoChanged() {
            if (this.$refs.logoInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')
            const handler = new AttachmentUpload(this.$refs.logoInput.files[0], this.artefact.id)

            handler.performUpload('logo')
                .then((r) => {
                    Toaster.show('success', 'Logo enregistré', 1300)
                    this.artefact.logo = r.webpath
                    this.$refs.logoImg.src = `${r.webpath}?d=${uniqid()}`
                }).catch((e) => {
                    Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                })
        },
        ensureLogoImg(artefact: Advert | Article) {
            return `${(artefact.logo !== 'undefined' && artefact.logo !== null) ? artefact.logo : '/images/default_logo_image.jpg'}?d=${uniqid()}`
        },
        onCoverMediaRemoved(e: MouseEvent) {
            e.stopPropagation()
            this.artefact.logo = null
            this.$refs.logoImg.src = null
        },
    },
})
