import { Advert } from 'lib-ad-platform-models'

export function advertParser(advert: Advert) {
    const parsedAdvert = {
        ...advert,
        quiz: {
            ...advert.quiz,
            questions: advert.quiz.questions.map((question) => {
                return {
                    ...question,
                    answer: question.answer.replaceAll('"', '\\"'),
                }
            }),
        },
    }

    return parsedAdvert
}
