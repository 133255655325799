import axios from 'axios'
import { PushPayload, PushWhereCriterias, PushWhereNormalizer } from '@/api/PushWhereNormalizer'

class PushService {
    headers: any = {}

    constructor() {
        this.headers = {}
    }

    send(payload: PushPayload) {
        const whereStr = PushWhereNormalizer.normalize(payload.where)

        const requestBody = {
            customId: payload.customId,
            limit: payload.limit,
            offset: payload.offset,
            notification: payload.notification,
            where: whereStr,
        }

        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.VUE_APP_PUSH_API_URL_SEND}`, requestBody, { headers: this.headers })
                .then((response: any) => {
                    resolve(response.data)
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }

    count(payload: PushWhereCriterias) {
        const whereStr = PushWhereNormalizer.normalize(payload)
        const requestBody = { where: whereStr }

        console.log(process.env.VUE_APP_PUSH_API_URL_COUNT)

        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.VUE_APP_PUSH_API_URL_COUNT}`, requestBody, { headers: this.headers })
                .then((response: any) => {
                    resolve(response.data)
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }
}

const PushApi = new PushService()
export default PushApi
