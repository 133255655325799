
import Vue from 'vue'

export default Vue.extend({
    name: 'PasswordConfirmPage',
    data() {
        return {
            loading: null,
            error: null,
            message: null,
            confirmationToken: null,
            password: null,
        }
    },
    mounted() {
        this.interceptTokenIfApplicable()
    },
    methods: {
        interceptTokenIfApplicable() {
            if (!this.$route.query.confirmationToken) {
                this.error = 'Invalid confirmation token'
            } else {
                this.confirmationToken = this.$route.query.confirmationToken
            }
        },
        changePassword() {
            this.loading = true

            this.$store
                .dispatch('user/passwordConfirm', {
                    confirmationToken: this.confirmationToken,
                    password: this.password,
                })
                .then(() => {
                    this.loading = false
                    this.message = 'Mot de passe modifié avec succès'
                    setTimeout(() => {
                        this.$router.replace('/')
                    }, 1200)
                })
                .catch((e) => {
                    this.error = e.response.data.errorMsg
                    this.loading = false
                })
        },
    },
})
