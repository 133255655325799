
import Vue from 'vue'
import { mapState } from 'vuex'
import { QuizQuestion } from 'lib-ad-platform-models'
import { State } from '@/store'

export default Vue.extend({
    name: 'AdvertEditPart3',
    mounted() {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            this.onKeyDown(e)
        })

        document.addEventListener('keyup', (e: KeyboardEvent) => {
            this.onKeyUp(e)
        })
    },
    data() {
        return {
            showAnswers: false,
            keyA: null,
            keyComboDetected: false,
        }
    },
    computed: mapState({
        advert: (state: State) => state.advt.advert,
    }),
    methods: {
        addQuestion() {
            const question: QuizQuestion = new QuizQuestion({
                id: this.advert.quiz.questions.length + 1,
                answer: '',
                correct: false,
                percentage: 0,
            })

            this.advert.quiz.questions.push(question)
        },
        rmQuestion(i: number) {
            this.advert.quiz.questions.splice(i, 1)
        },
        showAnswered() {
            this.showAnswers = true
        },
        onKeyUp() {
            this.keyA = null
        },
        onKeyDown(e: KeyboardEvent) {
            if (this.keyA === null) {
                this.keyA = e.keyCode
            }

            if (this.keyA === 17 && e.keyCode === 90) {
                this.keyComboDetected = true
                this.showAnswered()
            }
        },
    },
})
