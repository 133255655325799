export const getFileTypeFromUrl = (url: string): 'image'|'video' => {
    if (!url) {
        return null
    }

    const format = url.split('.').pop().split('?d=').shift()

    if (['jpg', 'jpeg', 'png', 'gif'].includes(format)) {
        return 'image'
    }

    if (['mp4', 'webm', 'ogg'].includes(format)) {
        return 'video'
    }

    return null
}
