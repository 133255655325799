const specialWords = {
    'contenu additionel': 'contenu add',
    eléa: 'éléå',
}

export function replaceWord(value: string) {
    if (value === null || !value) {
        return null
    }

    return specialWords[value.toLowerCase()] || value
}
