// import dotenv from "dotenv";
import Vue, { CreateElement } from 'vue'
import VModal from 'vue-js-modal'
import VueCookie from 'vue-cookie'
import VueClipboard from 'vue-clipboard2'
import VueQuillEditor from 'vue-quill-editor'
import router from '@/router'
import store from '@/store'
import axiosSetup from '@/api/axios'
import App from './App.vue'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.config.productionTip = false

Vue.use(VueCookie)
Vue.use(VModal, {
    dynamicDefaults: {
        height: 'auto',
    },
})
Vue.use(VueClipboard)

Vue.use(VueQuillEditor /* { default global options } */)

axiosSetup()
const ANONYMOUS_COMPONENTS = ['LoginPage', 'PasswordConfirmPage']

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['user/isAuthenticated']

    if (!ANONYMOUS_COMPONENTS.includes(to.name) && !isAuthenticated) {
        next({ name: 'LoginPage' })
    } else {
        // check pages special accesses
        // @todo Move to a better written service
        if (to.name === 'AudienceEditPage') {
            // only csm lead and admin can that
            if (!store.getters['user/hasAnyRole'](['CSM_LEAD', 'ADMIN'])) {
                next({ name: 'AccessDenied' })
            }
        }

        next()
    }
})

/* eslint-disable-next-line */
const app: Vue = new Vue({
    router,
    store,
    render: (h: CreateElement) => h(App),
}).$mount('#app')
