
import Vue from 'vue'
import { dayjs } from '@/utils/dayjs'
import { mapState } from 'vuex'
import { State } from '@/store'
import { isEmpty } from '@/utils/isEmpty'

export default Vue.extend({
    name: 'ArticleEditPart6',
    props: ['missingFields'],
    data() {
        return {

        }
    },
    computed: mapState({
        article: (state: State) => state.art.article,
        autoStartAt() {
            return this.article.autoStartAt
        },
    }),
    watch: {
        autoStartAt() {
            if (this.article.type === 'notification') {
                const startAt = dayjs(this.autoStartAt * 1000).local().format('YYYY-MM-DDTHH:mm')
                const autoStopAt = dayjs(startAt).add(1, 'month').unix()
                this.article.autoStopAt = autoStopAt
            }
        },
    },
    methods: {
        fromUnixTimestamp(timestamp: number) {
            return timestamp !== null
                ? dayjs(timestamp * 1000).local().format('YYYY-MM-DDTHH:mm')
                : null
        },
        toUnixTimestamp(dateStr: string) {
            return !isEmpty(dateStr)
                ? dayjs(dateStr).unix()
                : null
        },
        clear() {
            this.article.autoStopAt = null
            this.article.autoStartAt = null
        },
    },
})
