
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import InventoryAnnualViewModal from '@/components/modals/InventoryAnnualViewModal.vue'
import { populationCategoriesMapToId } from '@/utils/projectFilters'
import { mapRowSpecialityIdToName } from '@/utils/mapRowSpecialityIdToName'
import { Inventory } from '@/models/Inventory'

export default Vue.extend({
    name: 'InventoryTable',
    components: {
        InventoryAnnualViewModal,
    },
    props: {
        tableData: {
            type: Object,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },

    },

    data() {
        return {
            mapRowSpecialityIdToName,
            populationCategoriesMapToId,
            dataToDisplay: [],
            inventoryRowDetails: {
                type: Object as Vue.PropType<Inventory[]>,
            },
        }
    },

    computed: {
        ...mapState({
            quarterInventory: (state: State) => state.proj.quarterInventory,
        }),
    },

    methods: {
        // is remaining views inferior to 30%
        isBelowTheThreshold(totalView: number, consumedView: number) {
            if (totalView - consumedView <= totalView * 0.3) {
                return 'alert'
            }
            return null
        },
        openModal() {
            this.$refs.Inventorymodal.openModal()
        },
        getImageName(id: number, label: string) {
            const labelFirstLetter = label.charAt(0).toLowerCase()
            return `${labelFirstLetter}${id}`
        },

        numberWithSpaces(x: number) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        },

    },
})
