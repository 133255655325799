
import Vue from 'vue'
// import AudienceEditor from '@/componentvs/audienceV1/AudienceEditor.vue'
import AudienceEditor from '@/components/audience/AudienceEditor.vue'

export default Vue.extend({
    name: 'AudienceEditPage',
    components: {
        AudienceEditor,
    },
    computed: {
        id() {
            return this.$route.params.id
        },
    },
})
