
import Vue from 'vue'
import { mapState } from 'vuex'
import { Advert, AdvertListFilter } from 'lib-ad-platform-models'
import { State } from '@/store'
import { ellipsis } from '@/filters/ellipsis.filter'
import { capitalize } from '@/filters/capitalize.filter'
import { simpday, normalDate } from '@/filters/simpday.filter'

import AdvertPreview from '@/views/partials/AdvertPreview.vue'
import MultiSelectDropdown from '@/components/MultiSelectDropdown.vue'
import ExportToCSV from '@/components/ExportToCSV.vue'
import ListSearchLabelsRecap from '@/views/partials/ListSearchLabelsRecap.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'AdvertListPage',
    filters: { ellipsis, capitalize, simpday, normalDate },
    components: { AdvertPreview, MultiSelectDropdown, ListSearchLabelsRecap, ExportToCSV, ConfirmModal },
    data() {
        return {
            advertFocused: null,
            now: Math.round((new Date()).valueOf() / 1000),
            rolesForFilter: [{ id: 'ROLE_EXPERT', name: 'ROLE_EXPERT' }, { id: 'NOT:ROLE_EXPERT', name: 'NOT: ROLE_EXPERT' }],
            resetCSVDataCount: 0,
            activeDropdown: '',
            advertToDel: null,
        }
    },
    computed: {
        termInput() { return this.$refs.termInput },
        ...mapState({
            loading: (state: State) => state.xhr.loading,
            adverts: (state: State) => state.advt.adverts,
            filters: (state: State) => state.advt.listFilters,
            professions: (state: State) => state.meta.professions,
            specialties: (state: State) => state.meta.specialties,
            audiences: (state: State) => state.meta.audiences,
            countries: () => [{ id: '72', name: 'France' }, { id: '722', name: 'Spain' }],
        }),
    },
    created() {
        if (this.$store.state.advt.adverts.length === 0) {
            this.refresh()
        }
    },
    mounted() {
        this.$store.commit('advt/createNewAdvert', null)
    },
    methods: {
        hasAnyRole(roles: string[]) {
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        onIdHover(advert: any) {
            this.advertFocused = advert
        },
        deleteAdvert() {
            this.$store.dispatch('advt/deleteAdvert', this.advertToDel)
                .then(() => Toaster.show('success', 'La publicité a été suppirmée', 2500))
            this.advertToDel = null
        },
        updateAdvertProp(id: string, prop: string, value: string|number|boolean) {
            this.$store.dispatch('advt/updateAdvertProp', { id, prop, value })
        },
        duplicateAdvert(advert: Advert) {
            const now = Math.floor(Math.floor(new Date().getTime() / 1000))
            this.$store.dispatch('advt/duplicateAdvert', {
                ...advert,
                createdAt: now,
                updatedAt: now,
            })
        },
        gotoEditPage(id: string) {
            this.$router.push(`/adverts/edit/${id}`)
        },
        onScroll(e: any) {
            if ((e.srcElement.offsetHeight + e.srcElement.scrollTop + 1) >= e.srcElement.scrollHeight) {
                this.$store.commit('advt/setListFilter', { skip: (this.filters.skip + this.filters.limit) })
                this.$store.dispatch('advt/loadAdverts', this.filters)
            }
        },
        onSearchKeyup(e: KeyboardEvent) {
            if (e.code === 'Backspace' && this.$refs.termInput.value.trim() === '') {
                this.filterRemove({ key: 'term' })
            }
        },
        setActiveDropdown(label: string) {
            this.activeDropdown = label
        },
        filterBy(name: string, value: string = null) {
            const filter: any = {}
            filter[name] = (value === '') ? null : value

            this.$store.commit('advt/setListFilter', { ...filter, skip: 0 })
            this.refresh()
        },
        filterByTerm() {
            this.$store.commit('advt/setListFilter', { term: this.$refs.termInput.value, skip: 0 })
            this.refresh()
        },
        filterByRole(e: any) {
            if (e.length === 0) {
                this.$store.commit('advt/setListFilter', { role: null, skip: 0 })
            } else {
                this.$store.commit('advt/setListFilter', { role: e.map((r) => r.id)[0], skip: 0 })
            }

            this.refresh()
        },
        filterByProfessions(e: any) {
            this.$store.commit('advt/setListFilter', { professions: e.map((a) => a.id), skip: 0 })
            this.refresh()
        },
        filterBySpecialties(e: any) {
            this.$store.commit('advt/setListFilter', { specialties: e.map((a) => a.id), skip: 0 })
            this.refresh()
        },
        filterByAudiences(e: any) {
            this.$store.commit('advt/setListFilter', { audiences: e.map((a) => a.id), skip: 0 })
            this.refresh()
        },
        filterByCoutries(e: any) {
            this.$store.commit('advt/setListFilter', { countries: e.map((a) => a.id), skip: 0 })
            this.refresh()
        },
        filterRemove(e: any) {
            const defaultFilters = new AdvertListFilter()
            this.filters[e.key] = defaultFilters[e.key]

            if (e.key === 'professions') {
                this.$refs.m1.reset()
            }
            if (e.key === 'specialties') {
                this.$refs.m2.reset()
            }
            if (e.key === 'audiences') {
                this.$refs.m3.reset()
            }
            if (e.key === 'term') {
                this.$refs.termInput.value = ''
            }

            this.refresh()
        },
        sort(field: string, order: string) {
            this.filters.orderBy = field
            this.filters.order = order
            this.refresh()
        },
        isSorted(field: string, order: string|null = null) {
            if (order === null) {
                return (this.filters.orderBy === field)
            }
            return (this.filters.orderBy === field && this.filters.order === order)
        },
        refresh() {
            this.$store.dispatch('advt/loadAdverts', this.filters)
            this.$store.dispatch('meta/loadMeta')
            this.resetCSVDataCount += 1
        },
        create() {
            this.$router.push('/adverts/edit/new')
        },
        gotoMigrationsPage() {
            this.$router.push('/adverts/migration')
        },
        advertHasAudience(advert: any) {
            return (typeof advert._$audiences !== 'undefined' && advert._$audiences.length > 0)
        },
        selectAudienceRelByValue(advert: any, label: string, value: any) {
            if (!this.advertHasAudience(advert)) {
                return false
            }

            let result = false

            for (const i in advert._$targets) {
                if (advert._$targets[i].label === label && advert._$targets[i].name === value) {
                    result = true
                    break
                }
            }

            return result
        },
        selectAudienceRelsByLabels(advert: any, labels: Array<string>) {
            if (!this.advertHasAudience(advert)) {
                return false
            }

            const values = []

            for (const i in advert._$targets) {
                if (labels.indexOf(advert._$targets[i].label) > -1) {
                    values.push(advert._$targets[i].name)
                }
            }

            return values
        },
        showDelConfirmModal(id: string) {
            this.advertToDel = id
            this.$modal.show('delAdvert')
        },
        openAudience(audienceId: string) {
            const routeData = this.$router.resolve(`/audiences/${audienceId}`)
            window.open(routeData.href, '_blank')
        },
    },
})
