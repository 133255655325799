
import Vue from 'vue'

export default Vue.extend({
    name: 'LoginPage',
    data() {
        return {
            username: null,
            password: null,
            error: null,
            message: null,
            loading: false,
            loginMode: true,
        }
    },
    methods: {
        async login() {
            this.loading = true

            this.$store
                .dispatch('user/authenticate', {
                    username: this.username,
                    password: this.password,
                })
                .then(() => {
                    if (this.$store.getters['user/isAuthenticated']) {
                        this.$router.replace('/adverts')
                    } else {
                        this.error = 'Cannot log in'
                    }
                })
                .catch(() => {
                    this.error = 'Invalid credentials'
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async resetPassword() {
            this.loading = true
            try {
              await this.$store.dispatch('user/passwordReset', { username: this.username })
              this.message = 'Si cet e-mail est reconnu vous allez recevoir un lien pour réinitialiser votre mot de passe'
            } catch (e) {
              this.error = 'Une erreur est survenue'
            }
            this.loading = false
        },
        toggleLoginMode() {
          this.message = null
          this.error = null
          this.loginMode = !this.loginMode
        }
    },
})
