import { ArticleListFilter, Article, StoryScreen } from 'lib-ad-platform-models'
import Api from '@/api/api'
import { advertParser } from '@/utils/advertParser'
import { Console } from '@/utils/Console'

export type ArticleState = {
    article: Article,
    articles: Article[],
    listFilters: ArticleListFilter
}

const state: ArticleState = ({
    article: null,
    articles: [],
    listFilters: new ArticleListFilter({ archived: 0, isPinned: null, limit: 25, _model: 'latest', orderBy: 'updatedAt', order: 'DESC' }),
})

const getters = {}

const mutations = {
    createNewArticle(state: ArticleState, payload): void {
        state.article = payload !== null
            ? new Article(payload)
            : null
    },
    setArticle(state: ArticleState, payload: any): void {
        if (payload === null) {
            state.article = null
            return
        }
        state.article = new Article(payload)

        // also update articles list
        for (const i in state.articles) {
            if (state.articles[i].id === state.article.id) {
                state.articles[i] = state.article
            }
        }

        state.articles.sort((x, y) => {
            if (x.id === state.article.id) {
                return -1
            } if (y.id === state.article.id) {
                return 1
            }
            return 0
        })
    },
    setArticles(state: ArticleState, payload: any): void {
        state.articles = [...payload].map((item) => new Article(item))
    },
    updateArticles(state: ArticleState, payload: any): void {
        state.articles = state.articles.map((article) => {
            return article.id === payload.id
                ? new Article(payload)
                : article
        })
    },
    setMoreArticles(state: ArticleState, payload: any): void {
        state.articles.push(...payload.map((item) => new Article(item)))
    },
    setSwitchArticleStoryScreens(state: ArticleState, payload: any): void {
        const { screens } = state.article.story
        const temp = screens[payload.i]
        screens[payload.i] = screens[payload.j]
        screens[payload.j] = temp
        state.article.story.screens = [...screens]
    },
    updateArticlesFilter(state: ArticleState, payload: any): void {
        state.listFilters = { ...state.listFilters, ...payload }
    },
    resetArticlesFilters(state: ArticleState): void {
        state.listFilters = new ArticleListFilter({ archived: false, limit: 25, _model: 'latest' })
    },
    pushInArticles(state: ArticleState, payload: any): void {
        state.articles.unshift(new Article(payload))
    },
    unsetArticle(state: ArticleState, payload: string): void {
        const index = state.articles.findIndex(({ id }) => id === payload)
        state.articles.splice(index, 1)
    },
    pushArticleStoryScreen: (state: any, payload: StoryScreen) => {
        state.article.story.screens = [...state.article.story.screens, ...[payload]]
    },
    updateArticleStoryScreenIllustration: (state: any, payload: any) => {
        const { screenId, illustration } = payload

        state.article.story.screens.map((screen) => {
            if (screen.id === screenId) {
                screen.illustration = illustration
            }
            return screen
        })
    },
}

const actions = {
    async createArticle({ commit, dispatch }: any, payload: any): Promise<string> {
        const path = '/article/create'
        Console.logAction(`Vuex store action: < createArticle > as POST ${path}`, 'post')

        const article = { ...payload, published: false }

        try {
            const result = await Api.post(path, { article })
            commit('pushInArticles', result)
            commit('createNewArticle', result)
            if (result.type === 'story') {
                dispatch('lib/getLibrary', { label: 'article', id: result.id }, { root: true })
            }
            return result.id
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async duplicateArticle({ commit, dispatch }: any, payload: any): Promise<string> {
        const { id } = payload

        const path = `/article/${id}/duplicate`
        Console.logAction(`Vuex store action: < duplicateArticle > as POST ${path}`, 'post')

        const article = { ...payload, published: false }

        try {
            const result = await Api.post(path, { article })
            commit('pushInArticles', result)
            commit('createNewArticle', result)
            if (result.type === 'story') {
                dispatch('lib/getLibrary', { label: 'article', id: result.id }, { root: true })
            }
            return result.id
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async loadArticle({ commit, dispatch }: any, payload: any): Promise<void> {
        const path = `/article/${payload}`
        Console.logAction(`Vuex store action: < loadArticle > as GET ${path}`, 'get')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.get(path)
            commit('setArticle', result)
            if (result.type === 'story') {
                dispatch('lib/getLibrary', { label: 'article', id: result.id }, { root: true })
            }
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async loadArticles({ commit }: any, payload: any): Promise<void> {
        const path = '/article/list'
        Console.logAction(`Vuex store action: < loadArticles > as POST ${path}`, 'post')

        if (payload.skip === 0) {
            commit('xhr/isPending', true, { root: true })
        }

        try {
            const result = await Api.post(path, payload)
            if (payload.skip > 0) {
                commit('setMoreArticles', result)
            } else {
                commit('setArticles', result)
            }
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async updateArticle({ commit }: any, payload: any): Promise<void> {
        const path = `/article/${payload.article.id}`
        Console.logAction(`Vuex store action: < updateArticle > as PUT ${path}`, 'put')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.put(path, { article: advertParser(payload.article) })
            commit('setArticle', result)
            commit('updateArticles', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async updateArticleProp({ commit }: any, payload: any): Promise<void> {
        const path = `/article/${payload.id}/${payload.prop}`
        Console.logAction(`Vuex store action: < updateArticleProp > as PUT ${path}`, 'put')

        try {
            const result = await Api.put(path, payload)
            commit('setArticle', result)
            commit('updateArticles', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async addArticleStoryScreen({ commit }: any, payload: any): Promise<void> {
        const { artefact, screen } = payload
        const path = `/article/${artefact.id}/screen`
        Console.logAction(`Vuex store action: < addArticleStoryScreen > as POST ${path}`, 'post')

        try {
            const result = await Api.post(path, { article: advertParser(artefact), screen })
            commit('pushArticleStoryScreen', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteArticle({ commit }: any, id: string): Promise<void> {
        const path = `/article/${id}`
        Console.logAction(`Vuex store action: < deleteArticle > as DELETE ${path}`, 'delete')

        try {
            const result = await Api.delete(path, id)
            commit('unsetArticle', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteArticleStoryScreen({ commit }: any, payload: any): Promise<void> {
        const path = `/article/${payload.article.id}/screen`
        Console.logAction(`Vuex store action: < deleteArticleStoryScreen > as DELETE ${path}`, 'delete')

        try {
            const result = await Api.delete(path, { article: advertParser(payload.article), screenId: payload.screenId })
            commit('setArticle', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async migrateArticleToAdvert({ commit }: any, payload: any): Promise<string> {
        const path = `/article/${payload.id}/migrate`
        Console.logAction(`Vuex store action: < migrateArticleToAdvert > as PUT ${path}`, 'put')

        try {
            const result = await Api.put(path)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
