
import Vue from 'vue'
import { Toaster } from '@/components/Toaster'
import Header from '@/components/Header.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import GlobalToaster from '@/components/GlobalToaster.vue'
import GlobalModal from '@/components/modals/GlobalModal.vue'
import EventBus from '@/components/EventBus'

export default Vue.extend({
    // eslint-disable-next-line vue/no-reserved-component-names
    components: { Header, ConfirmModal, GlobalToaster, GlobalModal },
    data() {
        return {
            toaster: Toaster,
            env: process.env.VUE_APP_ENV,
            previousPath: null,
            currentPath: null,
            globalModalComponent: null,
            globalModalParams: null,
        } as any
    },
    created() {
        this.$store.dispatch('meta/loadMeta')
    },
    mounted() {
        this.previousPath = this.$route.fullPath
        this.currentPath = this.$route.fullPath

        EventBus.$on('openGlobalModal', (component: string, params: any) => {
            this.globalModalComponent = component
            this.globalModalParams = params
            this.$modal.show('global-modal')
        })
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters['user/isAuthenticated']
        },
    },
    watch: {
        $route(to, from) {
            this.previousPath = from.fullPath
            this.currentPath = to.fullPath
        },
    },
    methods: {
        logout() {
            this.$store.commit('user/clearUserData')
        },
    },
})
