
import Vue from 'vue'
import _ from 'lodash'
import Api from '@/api/api'
import { AppSearch } from '@/models/app-search'

export default Vue.extend({
    name: 'SearchAppModal',
    props: {
        isAsync: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            search: '',
            results: [],
            isOpen: false,
            baseUrl: '',
            selectedUrl: '',
            arrowCounter: -1,
        }
    },
    created() {
        this.debounceGetResults = _.debounce(this.getResults, 300)
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
        openModal(): void {
            this.$modal.show('searchApp')
        },
        closeModal(actionBtn: string): void {
            if (actionBtn === 'create') {
                this.$emit('search-url-selected', this.selectedUrl)
            }
            this.$modal.hide('searchApp')
        },
        handleClickOutside(event) {
            if (this.$el.contains(event.target)) {
                this.arrowCounter = -1
                this.isOpen = false
            }
        },
        onChange() {
            this.debounceGetResults()
        },
        onArrowDown() {
            if (this.arrowCounter <= this.results.length) {
                this.arrowCounter += 1
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter -= 1
            }
        },
        onEnter() {
            this.setResult(this.results[this.arrowCounter])
        },
        async getResults() {
            if (this.search.length >= 3) {
                this.$emit('input', this.search)
                this.results = await this.searchAppsByTerm(this.search)
                this.isOpen = true
            } else {
                this.isOpen = false
            }
        },
        async searchAppsByTerm(term?: string): Promise<AppSearch[]> {
            try {
                const apps = await Api.searchAppBack360(term)
                return apps.map((app) => ({ id: app.id, name: app.name, source: app.source }))
            } catch (error) {
                console.error('Failed to search app', error)
                return []
            }
        },
        setResult(result) {
            this.search = result.name
            this.isOpen = false
            this.baseUrl = result.source
            this.selectedUrl = result.source
        },
    },
})
