
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import AudienceSelect from '@/components/audience/AudienceSelect.vue'

export default Vue.extend({
    name: 'AdvertEditPart1',
    components: { AudienceSelect },
    data() {
        return {
            selectedAudienceId: null,
        }
    },
    computed: {
        ...mapState({
            advert: (state: State) => state.advt.advert,
            advertRels: (state: State) => state.advt.advertRels,
            audiences: (state: State) => state.advt.advert._$audiences,
        }),
    },
    methods: {
        openAudience(id: string) {
            const routeData = this.$router.resolve(`/audiences/${id}`)
            window.open(routeData.href, '_blank')
        },
        unlinkAudience(e: any, audience: any) {
            e.stopPropagation()
            this.onAudienceSelected(audience)
        },
        onAudienceSelected(audience: any) {
            this.$store.commit('advt/setAdvertAudience', audience)
            this.$emit('updateAudience', { audience })
        },
    },
})
