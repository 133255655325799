
import Vue from 'vue'
import { mapState } from 'vuex'
import { TargetGoal } from 'lib-ad-platform-models'
import { State } from '@/store'
import { UIKitStuff } from '@/models/MiscTypes'
import { Toaster } from '@/components/Toaster'
import { formatThousands } from '@/filters/formatThousands.filter'
import { displayGoal } from './CellDisplay.vue'

declare const UIkit: UIKitStuff

export default Vue.extend({
    name: 'CellGoalInputPicker',
    props: {
        value: TargetGoal,
        autocomplete: {
            type: Boolean,
            default: true,
        },
        center: {
            type: Boolean,
            default: false,
        },
        col: String,
    },
    computed: {
        ...mapState({
            clipboard: (state: State) => state.proj.clipboard,
        }),
    },
    data() {
        return {
            justChanged: false,
            autocompleteList: [],
            ctrlDown: false,
            hover: false,
        }
    },
    mounted() {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Control' || e.key === 'Meta') {
                this.ctrlDown = true
            }
        })

        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key === 'Control' || e.key === 'Meta') {
                this.ctrlDown = false
            }
        })

        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'c' && this.ctrlDown && this.hover) {
                Toaster.show('success', 'Copy to clipboard!', 1000)
                this.$store.commit('proj/copyToClipboard', {
                    category: this.col,
                    value: this.value,
                })
            }

            if (e.key === 'v' && this.ctrlDown && this.hover && this.clipboard) {
                const { category, value } = this.clipboard

                if (category === this.col) {
                    this.$emit('change', value)
                }
            }
        })
    },
    methods: {
        displayValue: displayGoal,
        guessFromInput(strUpper: string) {
            // extract number from string
            const str = strUpper.toLowerCase()
            const object: any = { views: 0, days: 0, shipments: 0 }

            const value = parseInt(str.replace(/\D/g, ''))
            const unit = str.replace(/[0-9]/g, '').trim()

            if (value && unit) {
                // detect days/months in french
                if (unit[0] === 'j' || unit.includes('jr') || unit.includes('jour')) {
                    object.days = value
                    object.views = 0
                    object.shipments = 0
                    return object
                }
                if (unit[0] === 'm' || unit.includes('mois') || unit.includes('month')) {
                    object.days = value * 30
                    object.views = 0
                    object.shipments = 0
                    return object
                }
                if (unit[0] === 'v' || unit.includes('vue') || unit.includes('view')) {
                    object.days = 0
                    object.views = value
                    object.shipments = 0
                    return object
                }
                if (unit[0] === 'e' || unit.includes('envois') || unit.includes('shipment')) {
                    object.days = 0
                    object.views = 0
                    object.shipments = value
                    return object
                }
            } else {
                // only value and no unit, make defaults as "views"
                object.days = 0
                object.views = value
                object.shipments = 0
                return object
            }

            return false
        },
        keyup(e: any) {
            if (!this.autocomplete) {
                return
            }

            this.autocompleteList = []
            const term = e.target.innerText.trim()

            if (term === '') {
                return
            }

            const isOnlyNum = /^\d+$/.test(term)
            if (!isOnlyNum) {
                this.autocompleteList = []
                return
            }

            const matches = term.match(/\d+/g)
            if (matches && matches[0]) {
                const num = parseInt(matches[0])
                this.autocompleteList = [
                    {
                        number: num,
                        type: 'views',
                        text: `${formatThousands(num)} vues`,
                    },
                    {
                        number: num,
                        type: 'days',
                        text: `${formatThousands(num)} jours`,
                    },
                    {
                        number: num,
                        type: 'month',
                        text: `${num} mois`,
                    },
                    {
                        number: num,
                        type: 'shipments',
                        text: `${formatThousands(num)} envois`,
                    },
                ]
            } else {
                this.autocompleteList = []
            }

            if (this.autocompleteList.length > 0) {
                UIkit.dropdown(this.$refs.dropdownRef).show()
            } else {
                UIkit.dropdown(this.$refs.dropdownRef).hide()
            }
        },
        autocompleteFill(item: any) {
            this.$refs.inputRef.innerText = item.text
            this.submit()
        },
        ifEnter(e: KeyboardEvent) {
            if (e.code !== 'Enter') {
                return
            }

            e.preventDefault()
            UIkit.dropdown(this.$refs.dropdownRef).hide()
            this.submit(e)
        },
        onFocus(e: any) {
            if (e.target.innerText === '--') {
                e.target.innerText = ''
            }
        },
        submit() {
            // interpret or convert to correct values
            const object = this.guessFromInput(this.$refs.inputRef.innerText)

            // if (!object) {
            //     alert('Wrong format')
            // }

            this.$refs.inputRef.blur()
            this.justChanged = true

            setTimeout(() => {
                this.justChanged = false
            }, 320)

            this.$emit('change', new TargetGoal(object))
        },
    },
})
