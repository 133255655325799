
import Vue from 'vue'
import { mapState } from 'vuex'
import editorOptions from '@/utils/editor-options'
import { State } from '@/store'
import { UIKitStuff } from '@/models/MiscTypes'
import { PROJECT_BRIEFING } from '@/models/project-briefing.models'
import { KEY_ACCOUNT_LIST } from '@/models/keyAccountList.model'
import { capitalize } from '@/filters/capitalize.filter'
import { normalDate } from '@/filters/simpday.filter'
import { Toaster } from '@/components/Toaster'

declare let UIkit: UIKitStuff

export default Vue.extend({
    name: 'CommentSlideBar',
    filters: { capitalize, normalDate },
    props: {
        projectId: {
            type: String,
        },
        isOpen: {
            type: Boolean,
            default: () => false,
        },
        briefing: {
            type: Object,
        },
        comments: {
            default: () => [],
        },
    },
    computed: {
        inputStyle() {
            return this.newChatMessage ? this.inputHeight : 54
        },
        commentEditModeStyle() {
            return this.commentInputHeight
        },
        defaultTabClass() {
            return this.$route.hash === '#comment' ? 'uk-active' : ''
        },
        ...mapState({
            userId: (state: State) => state.user.userId,
        }),
    },
    data() {
        return {
            activeTab: 'briefing',
            briefingEditMode: false,
            commentEditMode: null,
            commentEditMessage: null,
            newBriefing: null,
            editorOptions,
            newChatMessage: null,
            inputHeight: 0,
            commentInputHeight: 0,
            KEY_ACCOUNT_LIST,
            isShiftPressed: false,
            showConfirmDelete: false,
        }
    },
    created() {
        if (this.$route.hash === '#comment') {
            this.activeTab = 'comments'
        }
    },
    mounted() {
        this.newBriefing = this.briefing.content || PROJECT_BRIEFING

        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Shift') {
                this.isShiftPressed = true
            }
        })

        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key === 'Shift') {
                this.isShiftPressed = false
            }
        })
    },
    watch: {
        showConfirmDelete(val) {
            if (val) {
                setTimeout(() => {
                    this.showConfirmDelete = false
                }, 5000)
            }
        },
        isOpen(val) {
            if (val === false) {
                this.cancelComment()
            }
        },
        newChatMessage() {
            this.inputHeight = !this.newChatMessage
                ? 0
                : this.$refs.input.scrollHeight
        },
        commentEditMessage() {
            if (this.commentEditMessage) {
                this.commentInputHeight = this.commentEditMessage === this.comments[this.commentEditMode].content
                    ? this.$el.querySelector(`#bubble${this.commentEditMode}`).offsetHeight
                    : this.$el.querySelector(`#inputCom${this.commentEditMode}`).scrollHeight
            }
        },
        activeTab() {
            if (this.activeTab === 'comments' && this.comments.length) {
                this.scrollToBottom()
            }
        },
    },
    methods: {
        editComment(commentId:number, content: string) {
            this.commentEditMode = commentId
            this.commentEditMessage = content
        },
        abortCommentEditMode() {
            this.commentEditMode = null
            this.commentEditMessage = null
        },
        setActiveTab(tab: string) {
            this.activeTab = tab
        },
        closeCommentsSidebar() {
            this.cancelComment()
            this.$emit('close')
        },
        setBriefingEditMode(e) {
            this.briefingEditMode = true
            this.$emit('setEdit', true)
            e.stopPropagation()
        },
        updateComment(comment) {
            if (this.commentEditMessage !== comment.content) {
                comment.content = this.commentEditMessage

                this.$store.dispatch('proj/updateComment', comment)
            }
            this.abortCommentEditMode()
        },
        saveComment(type: string) {
            if (type === 'briefing') {
                if (!this.briefing.id) {
                    this.$store.dispatch('proj/createBriefing', { comment: this.newBriefing, projectId: this.projectId })
                        .then((r) => { this.newBriefing = r })
                } else if (this.briefing.content) {
                    this.$store.dispatch('proj/updateBriefing', { ...this.briefing, content: this.newBriefing })
                        .then((r) => { this.newBriefing = r })
                }
            }

            if (type === 'comment') {
                this.$store.dispatch('proj/addCommentToProject', { comment: this.newChatMessage, projectId: this.projectId })
                    .then(() => {
                        this.newChatMessage = null
                        this.scrollToBottom()
                    })
            }

            this.$emit('setEdit', false)
        },
        sendMessage() {
            if (this.isShiftPressed) return

            this.saveComment('comment')
        },
        cancelComment(type: string) {
            if (type === 'briefing') {
                this.newBriefing = this.briefing.content || PROJECT_BRIEFING
                this.briefingEditMode = false
            }
            this.$emit('setEdit', false)
        },
        async deleteMessage(comment: any) {
            try {
                await this.$store.dispatch('proj/deleteTargetComment', comment)
                this.$store.commit('proj/removeProjectComments', comment)
            } catch (error) {
                Toaster.show('error', error, 3000)
            } finally {
                this.showConfirmDelete = false
                UIkit.dropdown(this.$refs[comment.id]).hide()
            }
        },
        scrollToBottom() {
            const commentExist = setInterval(() => {
                const el = this.$el.querySelector(`#comment-${this.comments.length - 1}`)
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
                }
                clearInterval(commentExist)
            }, 100)
        },
    },
})
