import { Article } from 'lib-ad-platform-models'

export interface PreviewHistory {
    title?: string,
    description?: string,
    author?: string,
    tagline?: string
    logo?: string,
    images?: string[],
    isActive: boolean
}

export type PreviewHistoryState = {
    initValues: PreviewHistory
}

const state: PreviewHistoryState = {
    initValues: {
        title: undefined,
        description: undefined,
        author: undefined,
        tagline: undefined,
        logo: undefined,
        images: undefined,
        isActive: false,
    },
}

const getters = {
    getPreviewHistory() {
        return state.initValues
    },
}
const mutations = {
    setPreviewHistory(state: PreviewHistoryState, preview: Article) {
        const { title, description, author, tagline, logo, images } = preview
        state.initValues = {
            title,
            description,
            author,
            tagline,
            logo,
            images,
            isActive: false,
        }
    },
    resetPreviewHistory(state: PreviewHistoryState) {
        state.initValues = {
            title: undefined,
            description: undefined,
            author: undefined,
            tagline: undefined,
            logo: undefined,
            images: [],
            isActive: false,
        }
    },
    activePreview(state: PreviewHistoryState) {
        state.initValues.isActive = true
    },
    deactivatePreview(state: PreviewHistoryState) {
        state.initValues.isActive = false
    },
}
export default {
    namespaced: true,
    mutations,
    getters,
    state,
}
