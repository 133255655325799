import Api from '@/api/api'
import { Console } from '@/utils/Console'

type AlphonseUserAccount = {
    id: string,
    username: string
}

type AlphonseTag = {
    content: string,
    id: string,
    level: string,
    link: string,
    linkTitle: string,
    name: string,
    params: string,
    query: string,
    type: string,
    usersCount: number
}

type AlphonseProject = {
    id: string,
    name: string,
    pending: boolean,
    tags?: AlphonseTag[],
    userAccounts?: AlphonseUserAccount[],
    usersCount?: number
}

export type AlphonseState = {
    projectsList: AlphonseProject[],
    project: AlphonseProject,
}

const state = ({
    projectsList: [],
    project: null,
})

const getters = {}

const mutations = {
    setAlphonseProject(state: AlphonseState, payload: any): void {
        const tags = payload.tags.map((tag) => {
            return {
                ...tag,
                query: atob(tag.query || ''),
                params: atob(tag.params || ''),
            }
        })

        state.project = {
            ...payload,
            tags,
        }
    },
    setAlphonseProjectsList(state: AlphonseState, payload: any): void {
        if (payload.length) {
            state.projectsList = payload
        }
    },
    setAlphonseTags(state: AlphonseState, payload: any): void {
        state.project.tags = payload.map((tag) => {
            return {
                ...tag,
                query: atob(tag.query || ''),
                params: atob(tag.params || ''),
            }
        })
    },
    alphonseUnsetAlphonseTag(state: AlphonseState, payload: any): void {
        state.project.tags = state.project.tags.filter(({ id }) => id !== payload)
    },
    setNewAlphonseProject(state: AlphonseState, payload: any): void {
        state.projectsList.push(payload)
    },
    removeAlphonseProject(state: AlphonseState, payload: any): void {
        state.projectsList = state.projectsList.filter(({ id }) => id !== payload)
    },
    setAlphonseProjectUserAccounts(state: AlphonseState, payload: any): void {
        state.project.userAccounts.push(...payload)
    },
    removeAlphonseProjectUserAccounts(state: AlphonseState, payload: any): void {
        const index = state.project.userAccounts.findIndex(({ id }) => id === payload)
        state.project.userAccounts.splice(index, 1)
    },
}

const actions = {
    async loadAlphonseProject({ commit }: any, id: string): Promise<void> {
        const path = `/alphonse/project/${id}`
        Console.logAction(`Vuex store action: < loadAlphonseProject > as GET ${path}`, 'get')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.get(path)
            commit('setAlphonseProject', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async loadAlphonseProjects({ commit }: any): Promise<void> {
        const path = '/alphonse/projects'
        Console.logAction(`Vuex store action: < loadAlphonseProjects > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })

        try {
            const projects = await Api.post(path)
            commit('setAlphonseProjectsList', projects)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async createAlphonseProject({ commit }: any, payload: any): Promise<AlphonseProject> {
        const path = '/alphonse/project'
        Console.logAction(`Vuex store action: < createAlphonseProject > as POST ${path}`, 'post')

        try {
            const project = await Api.post(path, payload)
            commit('setNewAlphonseProject', project)
            return project
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
    async updateAlphonseProject({ commit }: any, payload: any): Promise<AlphonseProject> {
        const path = `/alphonse/project/${payload.id}`
        Console.logAction(`Vuex store action: < updateAlphonseProject > as PUT ${path}`, 'put')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.put(path, payload)
            commit('setAlphonseProject', result)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async createAlphonseTag({ commit }: any, payload: any): Promise<void> {
        const path = `/alphonse/project/${payload.projectId}/tag`
        Console.logAction(`Vuex store action: < createAlphonseTag > as POST ${path}`, 'post')

        try {
            const tags = await Api.post(path, payload)
            commit('setAlphonseTags', tags)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteAlphonseTag({ commit }: any, payload: any): Promise<void> {
        const path = `/alphonse/project/${payload.projectId}/tag`
        Console.logAction(`Vuex store action: < deleteAlphonseTag > as DELETE ${path}`, 'delete')

        try {
            const project = await Api.delete(path, { tagId: payload.tagId })
            commit('setAlphonseProject', project)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteAlphonseProject({ commit }: any, payload: any): Promise<string> {
        const path = `/alphonse/project/${payload.id}`
        Console.logAction(`Vuex store action: < deleteAlphonseProject > as DELETE ${path}`, 'delete')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.delete(path, { name: payload.name })
            commit('removeAlphonseProject', payload.id)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async addAlphonseUserAccounts({ commit }: any, payload: any): Promise<void> {
        const path = '/alphonse/accounts'
        Console.logAction(`Vuex store action: < addAlphonseUserAccounts > as PUT ${path}`, 'put')

        try {
            const userAccounts = await Api.put(path, payload)
            commit('setAlphonseProjectUserAccounts', userAccounts)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async removeAlphonseUserAccount({ commit }: any, payload: any): Promise<void> {
        const path = '/alphonse/account'
        Console.logAction(`Vuex store action: < removeAlphonseUserAccount > as DELETE ${path}`, 'delete')

        try {
            const userAccount = await Api.delete(path, payload)
            commit('removeAlphonseProjectUserAccounts', userAccount)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
