
import Vue from 'vue'
import { DELAY_REASONS } from 'lib-ad-platform-models'

export default Vue.extend({
    name: 'DelayReasonModal',
    props: ['target'],
    data() {
        return {
            DELAY_REASONS,
        }
    },
    methods: {
        getReasonParts(reason: string) {
            return reason.split(' - ')
        },
        setDelayReason(reason: string) {
            this.target.delayReason = reason
            this.$store.dispatch('proj/updateTargetProps', {
                id: this.target.id,
                props: {
                    delayReason: reason,
                },
            })
            this.$emit('close')
        },
    },

})
