
import Vue from 'vue'
import { TargetGoal } from 'lib-ad-platform-models'
import { normalDate } from '@/filters/simpday.filter'
import { formatThousands } from '@/filters/formatThousands.filter'

export function displayGoal(goal: TargetGoal) {
    if (goal === null) {
        return null
    }
    let text: string
    if (goal.views > 0) {
        text = `${formatThousands(goal.views)} vues`
    } else if (goal.shipments) {
        text = `${formatThousands(goal.shipments)} envois`
    } else if (goal.days > 0 && goal.days < 30) {
        text = `${goal.days} jours`
    } else if (goal.days >= 30) {
        text = `${Math.round((goal.days / 30.4167) * 2) / 2} mois`
    } else {
        text = '--'
    }

    return text
}

export function displayInput(value: any, isNum: boolean, isAmount: boolean) {
    if (!value) {
        return '--'
    }

    if (isAmount) {
        const amount = typeof value === 'string'
            ? parseFloat(value).toFixed(2)
            : value.toFixed(2)

        return `${formatThousands(amount)} €`
    }

    if (isNum) {
        return formatThousands(value)
    }

    return value
}

export function displayDuration(value: number) {
    return value ? `${value} jours` : '--'
}

export default Vue.extend({
    name: 'CellDisplay',
    filters: { normalDate, formatThousands },
    props: {
        type: String,
        ukLabelClass: { type: String, default: '' },
        value: [String, Number, Object, Boolean],
        center: {
            type: Boolean,
            default: () => false,
        },
        isNum: {
            type: Boolean,
            default: () => false,
        },
        isAmount: {
            type: Boolean,
            default: () => false,
        },
        displayGoal: {
            default() {
                return displayGoal
            },
        },
        displayInput: {
            default() {
                return displayInput
            },
        },
        displayDuration: {
            default() {
                return displayDuration
            },
        },
        editable: {
            type: Boolean,
            default: () => true,
        },
    },
    methods: {
    // displayGoal: displayGoal,
        onHover() {
            if (this.editable) {
                this.$emit('enable', true)
            }
        },
    },
})
