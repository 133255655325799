
import Vue from 'vue'
import * as _ from 'lodash'
import { mapState } from 'vuex'
import { State } from '@/store'
import { AudienceType } from '@/models/Audience'
import { Toaster } from '../Toaster'

export default Vue.extend({
    name: 'AudienceSelect',
    props: {
        audiences: {
            required: true,
        },
        advert: {
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            term: null,
            limit: 120,
            searchDropdownVisible: false,
        } as any
    },
    computed: {
        isShiftPressed() { return this.shiftPressed },
        ...mapState({
            results: (state: State) => state.audn.audienceSelectSearchResults,
        }),
        isSalesAccount() {
            return this.$store.getters['user/isSalesAccount']
        },
        unlinkedAudiences() {
            return this.results.filter(({ id }) => !this.audiences.map((adAudience) => adAudience.id).includes(id))
        },
    },
    methods: {
        hasAnyRole(roles: string[]) {
            // user can have only ONE of the roles specified, but needs at least one
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        close() {
            this.searchDropdownVisible = false
        },
        onSearch: _.debounce(async function (this: any) {
            if (this.term.length > 1) {
                let type: string
                if (['post', 'quiz', 'video'].includes(this.advert.type)) {
                    type = 'timeline'
                } else if (['tool'].includes(this.advert.type)) {
                    type = 'search'
                } else {
                    type = this.advert.type
                }

                try {
                    this.isLoading = true
                    await this.$store.dispatch('audn/selectAudienceSearch', { term: this.term, limit: this.limit, type })
                    this.searchDropdownVisible = true
                    this.isLoading = false
                } catch (error) {
                    Toaster.show('error', error, 3000)
                }
            } else {
                this.searchDropdownVisible = false
            }
        }, 320),
        selectAudience(audience: AudienceType) {
            this.term = null
            this.searchDropdownVisible = false
            this.$emit('audienceSelected', audience)
        },
    },
    mounted() {
        document.addEventListener('click', this.close, false)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close)
        this.$store.commit('advt/setAdvert', null)
    },
})
