
import Vue from 'vue'
import { mapState } from 'vuex'
import inputDeeplinkGen from '@/components/inputs/inputDeeplinkGen.vue'
import InputText from '@/components/inputs/InputText.vue'
import { State } from '@/store'

export default Vue.extend({
    name: 'SourceFormContent',
    components: { inputDeeplinkGen, InputText },
    computed: {
        ...mapState({
            deeplink: (state: State) => state.deeplink.deeplink,
        }),
    },
    data() {
        return {
            contentsChoice: [
                {
                    name: 'Story',
                    url: 'medics://story?m_id=',
                },
                {
                    name: 'Contenu pub/sponso',
                    url: 'medics://ad?m_id=',
                },
                {
                    name: 'Post Contenu (non sponso)',
                    url: 'medics://content?m_id=',
                },
                {
                    name: 'Résumé d\'article',
                    url: 'medics://article?m_id=',
                }],
            contentsInput: ['Identifiant de la story', 'Identifiant de la publicité', 'Identifiant du post', 'Identifiant de l\'article'],
            activeContent: {
                index: 0,
                url: 'medics://story?m_id=',
            },
            deeplinkData: {
                inputValue: '',
            },
            isError: false,
            error: {
                isShow: false,
                message: "Lettres, chiffres, '-' et '_' uniquement",
            },
        }
    },
    created() {
        this.deeplinkData = {
            inputValue: this.deeplink.firstParams ?? '',
        }
    },
    methods: {
        setActiveContent(index: number, url: string) {
            this.activeContent.index = index
            this.activeContent.url = url
        },
        setValue(event: string) {
            this.deeplinkData.inputValue = event
            const regexp = /^[a-zA-Z0-9_-]+$/
            const eventIsValid = regexp.test(event)
            this.error.isShow = !eventIsValid && event.length
            this.$emit('isNotEmpty', eventIsValid)
        },
    },
})
