
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import ConfirmModal from '@/components/ConfirmModal.vue'
import AlphonseChart from '@/components/AlphonseChart.vue'
import AlphonseProjectTags from '@/views/partials/alphonse/AlphonseProjectTags.vue'
import AlphonseAccessManager from '@/views/partials/alphonse/AlphonseAccessManager.vue'

export default Vue.extend({
    name: 'AlphonseProjectPage',
    components: {
        ConfirmModal,
        AlphonseChart,
        AlphonseProjectTags,
        AlphonseAccessManager,
    },
    data() {
        return {
            activeTab: 'tags',
            editedTag: null,
            loadingStatus: 'Chargement',
            datacollection: null,
        }
    },
    computed: {
        ...mapState({
            project: (state: State) => state.alph.project,
            loading: (state: State) => state.xhr.loading,
        }),
        understandTags() {
            return this.project.tags.filter(({ level }) => level === 'UNDERSTAND')
        },
        convinceTags() {
            const tags = this.project.tags.filter(({ level }) => level === 'CONVINCE').sort((a, b) => a.name.localeCompare(b.name))
            this.fillData(tags)
            return tags
        },
        surpriseTag() {
            return this.project.tags.filter(({ level }) => level === 'SURPRISE')[0]
        },
    },
    created() {
        this.$store.dispatch('alph/loadAlphonseProject', this.$route.params.id)
    },
    methods: {
        fillData(tags: any) {
            const colors = {
                Environnement: '#59ce90',
                Efficacité: '#e15562',
                'Bon Usage': '#3686ff',
            }

            const data = []
            const backgroundColor = []
            const labels = []

            tags.forEach(({ name, type, usersCount }) => {
                data.push(usersCount)
                backgroundColor.push(colors[type])
                labels.push(name)
            })

            this.datacollection = {
                datasets: [{
                    data,
                    backgroundColor,
                }],
                labels,
            }
        },
        getRandomInt() {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5
        },
        setActiveTab(tab:string) {
            this.activeTab = tab
        },
        async save() {
            const tags = [
                ...this.understandTags,
                ...this.convinceTags,
                this.surpriseTag,
            ].map((tag) => {
                return {
                    ...tag,
                    query: btoa(tag.query || ''),
                    params: btoa(tag.params || ''),
                }
            })

            try {
                this.loadingStatus = 'Enregistrement'
                await this.$store.dispatch('alph/updateAlphonseProject', {
                    project: {
                        ...this.project,
                        tags,
                    },
                })
                Toaster.show('success', 'Projet enregistré', 2000)
                this.loadingStatus = 'Chargement'
            } catch (error) {
                Toaster.show('error', 'Une erreur est survenue', 2000)
            }
        },
        editTagQuery(tag:any) {
            this.editedTag = tag
            this.$modal.show('query-modal')
        },
        submitTagQuery() {
            this.save()
            this.$modal.hide('query-modal')
            this.editedTag = null
        },
        openDeleteTagModal(tagId: string) {
            this.tagToDelete = tagId
            this.$modal.show('deleteTag')
        },
        deleteTag() {
            this.$store.dispatch('alph/deleteAlphonseTag', { projectId: this.project.id, tagId: this.tagToDelete })
                .then(() => {
                    this.$modal.hide('deleteTag')
                    Toaster.show('success', 'Tag supprimé', 2000)
                }).catch(() => {
                    this.$modal.hide('deleteTag')
                    Toaster.show('error', 'Une erreur est survenue', 2000)
                })
        },
    },
})
