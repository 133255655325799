
import Vue from 'vue'
import { mapState } from 'vuex'
import { dayjs } from '@/utils/dayjs'
import { State } from '@/store'
import FunnelAdvertEditModal from '@/components/FunnelAdvertEditModal.vue'

export default Vue.extend({
    name: 'AdvertEditFunnel',
    props: ['advertId'],
    components: { FunnelAdvertEditModal },
    data() {
        return {
            visibleArrowDropdown: -1
        }
    },
    mounted() {
        this.$store.dispatch('advt/fetchFunnel', this.advertId)
        document.addEventListener('click', this.closeDropdowns, false)
    },
    computed: mapState({
        advert: (state: State) => state.advt.advert,
        funnel: (state: State) => state.advt.funnel,
        error: (state: State) => state.err.error
    }),
    methods: {
        create(advertId: string) {
            this.$store.dispatch('advt/createFunnel', advertId)
        },
        del(advertId: string, funnelUuid: string) {
            this.$store.dispatch('advt/deleteFunnel', { advertId: advertId, funnelUuid: funnelUuid })
        },
        showModal() {
            this.$modal.show('funnelAdvertEditModal')
        },
        modalConfirmed(event: any) {
            if (event?.advertId && event.advertId.trim() !== '') {
                const lastAdvert = this.funnel.adverts[this.funnel.adverts.length - 1]
                this.$store.dispatch('advt/appendFunnel', { advertId: lastAdvert.id, funnelUuid: this.funnel.uuid, newAdvertId: event.advertId })
            }

            this.$modal.hide('funnelAdvertEditModal')
        },
        showArrowDropdown(index: number, event: any) {
            this.visibleArrowDropdown = index
            event.stopPropagation()
        },
        toMs(index: number, event: any) {
            const days = event.target.value
            const value = dayjs.duration({ days }).asMilliseconds()
            this.funnel.adverts[index].gracePeriodInMs = value
            return value
        },
        toDays(ms: number) {
            return dayjs.duration(ms).days()
        },
        closeDropdowns() {
            this.visibleArrowDropdown = -1
        },
        stopPropagation(event: any) {
            event.stopPropagation()
        },
        setMinViews(index: number, minViews: number) {
          if (index+1 >= this.funnel.adverts.length) {
            return
          }
          const startAdvertId = this.funnel.adverts[index].id
          const nextAdvertId = this.funnel.adverts[index+1].id
          const funnelUuid = this.funnel.uuid
          const payload = { startAdvertId, nextAdvertId, funnelUuid, minViews }
          this.$store.dispatch('advt/updateFunnelMinViews', payload)
        }
    },
})
