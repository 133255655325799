
import Vue from 'vue'
import { Advert } from 'lib-ad-platform-models'
import StoryScreenEditor from '@/views/partials/story/StoryScreenEditor.vue'
import StoryRowIconEditor from '@/views/partials/story/StoryRowIconEditor.vue'
import PostPreview from './previews/PostPreview.vue'
import QuizPreview from './previews/QuizPreview.vue'
import QuizFlowPreview from './previews/QuizFlowPreview.vue'
import SearchPreview from './previews/SearchPreview.vue'
import NotificationPreview from './previews/NotificationPreview.vue'
import ToolPreview from './previews/ToolPreview.vue'
import BannerPreview from './previews/BannerPreview.vue'

export default Vue.extend({
    name: 'AdvertPreview',
    components: {
        PostPreview,
        QuizPreview,
        QuizFlowPreview,
        SearchPreview,
        ToolPreview,
        BannerPreview,
        NotificationPreview,
        StoryScreenEditor,
        StoryRowIconEditor,
    },
    props: {
        input: { type: Object, default: null },
        editable: { type: Boolean, default: false },
        isDeploy: { type: Boolean, default: false },
        scale: { type: Number, default: 1 },
        showVideoControls: { type: Boolean, default: false },
        videoDatas: { type: Object, default: null },
    },
    data() {
        return {
            advert: null,
            currentScreen: null,
        }
    },
    watch: {
        input: {
            handler(value: Advert) {
                this.advert = value
            },
            immediate: true,
        },
    },
    mounted() {
        if (this.advert.type === 'story') {
            [this.currentScreen] = this.advert.story.screens
        }
    },
    methods:
    {
        addScreen() {
            this.$emit('addScreen')
        },
        updateAdvert(props: string, value: string|number) {
            this.advert[props] = value
        },
        openImagesEditorModal() {
            this.$emit('openImagesEditorModal')
        },
    },
})
