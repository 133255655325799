export function klass(element: any, className: string) {
    if (!element.classList.contains(className)) {
        element.classList.add(className)
    }
}

export function unklass(element: any, className: string) {
    if (element.classList.contains(className)) {
        element.classList.remove(className)
    }
}
