
import Vue from 'vue'
import * as _ from 'lodash'
import { mapState } from 'vuex'
import { Relation } from 'lib-ad-platform-models'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import { klass } from '@/utils/klass'
import { contains } from '@/utils/contains'
import { audiencesCategories } from '@/utils/audiencesCategories'

export default Vue.extend({
    name: 'AudienceSearchBar',
    props: ['id', 'groupEdited'],
    data() {
        return {
            term: '',
            limit: 25,
            loading: false,
            labels: [],
            searchDropdownVisible: false,
            hiddenGroups: [],
            shiftPressed: false,
            nothingClickedYet: true,
            currentLabelFilter: null,
        } as any
    },
    computed: {
        isLoading() {
            return this.loading
        },
        isShiftPressed() {
            return this.shiftPressed
        },
        ...mapState({
            audience: (state: State) => state.audn.audience,
            searchResults: (state: State) => state.audn.searchResults,
        }),
    },
    watch: {
        audience(newVal) {
            this.labels = [null, ...audiencesCategories[newVal.type]]
        },
    },
    methods: {
        focusOnSearch() {
            this.searchDropdownVisible = true
        },
        textBoldTerm(result: string) {
            let expression = this.term

            if (this.currentLabelFilter && this.term.includes(':')) {
                expression = this.term.split(':')[1].trim()
            }
            const regEx = new RegExp(expression, 'ig')
            const boldTerm = result.match(regEx)

            if (expression.length >= 3 && boldTerm) {
                return boldTerm
                    .map((word) => {
                        return result.replace(word, `<b>${word}</b>`)
                    })
                    .join('')
            }

            return result
        },
        resetSearch() {
            if (this.term === '') {
                this.searchDropdownVisible = null
            } else {
                this.term = ''
                this.currentLabelFilter = null
                this.onSearch()
            }
        },
        filterByLabel(label: string) {
            let cleanTerm = ''
            const list = this.term.split(':')
            if (list.length > 1) {
                list.shift()
                cleanTerm = list.join('').trim()
            } else {
                cleanTerm = this.term.trim()
            }

            if (label === null) {
                this.currentLabelFilter = null
                this.term = cleanTerm
            } else {
                this.currentLabelFilter = label
                this.term = `${label}: ${cleanTerm}`
            }

            this.loading = true
            this.onSearch()
        },
        close() {
            this.searchDropdownVisible = false
        },
        onSearch: _.debounce(function (this: any) {
            this.loading = true
            this.$store.commit('audn/setSearchNodesResult', [])

            const patterns = [',', "'", '(', ')']

            if (
                ![null, '', ':'].includes(this.term)
        && !this.term.startsWith(':')
        && !contains(this.term, patterns)
            ) {
                this.$store
                    .dispatch('audn/searchNodes', { term: this.term, limit: this.limit, commitResult: true })
                    .then(() => {
                        this.loading = false
                        this.searchDropdownVisible = true
                    })
            } else {
                this.loading = false
                this.$store.commit('audn/setSearchNodesResult', [])
            }
        }, 320),
        addItem(e: any, item: any) {
            item._$justAdded = true
            this.nothingClickedYet = false
            klass(e.currentTarget, 'just-added')

            const source = this.audience
            const target = item

            if (target.label === 'Keyword' && this.audience.type !== 'search') {
                alert(
                    'Impossible d\'ajouter des mots clefs sur un audience de type "Timeline"',
                )
                return
            }

            const relation: Relation = new Relation({
                type: 'AND',
                kind: 'required',
                event: this.audience.type === 'search' && target.label === 'Keyword'
                    ? 'search'
                    : null,
                caping: null,
            })

            this.$store
                .dispatch('audn/addAudienceRel', {
                    source,
                    target: item,
                    relationship: relation,
                })
                .then(() => {
                    Toaster.show('success', 'Saved.', 1300)
                })

            this.currentLabelFilter = ''
            this.term = ''

            if (!this.isShiftPressed) {
                this.searchDropdownVisible = false
                this.$store.commit('audn/setSearchNodesResult', [])
            }

            this.$emit('someEvent')
        },
    },
    mounted() {
        this.labels = [null, ...audiencesCategories[this.audience.type]]

        document.addEventListener('click', this.close, false)

        document.addEventListener(
            'keyup',
            (e: any) => {
                if (
                    this.shiftPressed
          && e.code === 'ShiftLeft'
          && !this.nothingClickedYet
                ) {
                    this.searchDropdownVisible = false
                    this.$store.commit('audn/setSearchNodesResult', [])
                    this.nothingClickedYet = true
                }

                this.shiftPressed = false
            },
            false,
        )

        document.addEventListener(
            'keydown',
            (e: any) => {
                if (e.code === 'ShiftLeft') {
                    this.shiftPressed = true
                }
            },
            false,
        )
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close)
    },
})
