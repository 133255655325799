class ConsoleSingleton {
    $off = false

    Reset = '\x1b[0m';
    Bright = '\x1b[1m';
    Dim = '\x1b[2m';
    Underscore = '\x1b[4m';
    Blink = '\x1b[5m';
    Reverse = '\x1b[7m';
    Hidden = '\x1b[8m';

    FgBlack = '\x1b[30m';
    FgRed = '\x1b[31m';
    FgGreen = '\x1b[32m';
    FgYellow = '\x1b[33m';
    FgBlue = '\x1b[34m';
    FgMagenta = '\x1b[35m';
    FgCyan = '\x1b[36m';
    FgWhite = '\x1b[37m';
    FgLitGray = '\x1b[90m';

    BgBlack = '\x1b[40m';
    BgRed = '\x1b[41m';
    BgGreen = '\x1b[42m';
    BgYellow = '\x1b[43m';
    BgBlue = '\x1b[44m';
    BgMagenta = '\x1b[45m';
    BgCyan = '\x1b[46m';
    BgWhite = '\x1b[47m';

    log(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[37m%s\x1b[0m', text)
    }

    bgYellow(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[43m%s\x1b[0m', text)
    }

    white(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[37m%s\x1b[0m', text)
    }

    red(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[31m%s\x1b[0m', text)
    }

    info(text: string) {
        this.lite(text)
    }

    green(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[32m%s\x1b[0m', text)
    }

    greenbold(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[42m\x1b[37m%s\x1b[0m', text)
    }

    warn(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[33m%s\x1b[0m', text)
    }

    magenta(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[35m%s\x1b[0m', text)
    }

    logAction(text: string, color = 'get') {
        const [describe, action, path] = text
            .split('<')
            .map((x) => x
                .split('>')
                .map((y) => y
                    .split('as'))
                .flat())
            .flat()
            .filter((x) => x !== ' ')

        const colors = {
            get: '#61AFFE',
            post: '#4ACB91',
            put: '#FBA12F',
            delete: '#F93F3E',
        }

        console.log(
            `%c${describe} %c${action} %c=> %c${path}`,
            'color: #A142F4',
            `color: ${colors[color]}`,
            'color: #A142F4',
            `color: ${colors[color]}`,
        )
    }

    comment(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[35m%s\x1b[0m', text)
    }

    cyan(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[36m%s\x1b[0m', text)
    }

    blue(text: string, inv = false) {
        if (inv) {
            if (this.$off === true) { return }// inverse background and foreground
            console.log('\x1b[44m\x1b[37m%s\x1b[0m', text)
        } else {
            if (this.$off === true) { return }
            console.log('\x1b[44m%s\x1b[0m', text)
        }
    }

    gray(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[97m%s\x1b[0m', text)
    }

    lite(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[90m%s\x1b[0m', text)
    }

    error(text: string) {
        if (this.$off === true) { return }
        console.log('\x1b[31m%s\x1b[0m', text)
    }

    exception(error: any, verbose = 0) {
        if (this.$off === true) { return } const errorStr = error.toString()
        console.log('\x1b[41m%s\x1b[0m', errorStr)

        if (verbose > 0) {
            throw new Error(errorStr)
        }
    }
}

export const Console = new ConsoleSingleton()
