
import Vue from 'vue'
import { mapState } from 'vuex'

import { State } from '@/store'
import { LibraryMediaUpload } from '@/components/AttachmentUpload'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'StoryLibraryModal',
    props: {
        params: {
            type: Object,
            required: true,
        },
        isModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            itemToDelete: null,
        }
    },
    computed: {
        ...mapState({
            library: (state: State) => state.lib.library,
        }),
    },
    methods: {
        getItemFormat(item: string) {
            const format = item.split('.').pop().split('?d=').shift()

            if (['jpg', 'jpeg', 'png', 'gif'].includes(format)) {
                return 'image'
            }

            if (['mp4', 'webm', 'ogg'].includes(format)) {
                return 'video'
            }

            return null
        },
        getVideoIndex(item: string) {
            return parseInt(item.split('_part').pop().split('.mp4').shift()) + 1
        },
        selectItem(url: string) {
            const { label } = this.params
            if (!this.itemToDelete) {
                if (label === 'advert') {
                    this.$store.commit('advt/updateAdvertStoryScreenIllustration', {
                        illustration: url,
                        screenId: this.params.screenId,
                    })
                }

                if (label === 'article') {
                    this.$store.commit('art/updateArticleStoryScreenIllustration', {
                        illustration: url,
                        screenId: this.params.screenId,
                    })
                }
            }
        },
        setItemToDelete(e, url: string) {
            e.stopPropagation()
            this.itemToDelete = url
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onMediaChanged() {
            if (this.$refs.mediaInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')

            const file = this.$refs.mediaInput.files[0]

            const handler = new LibraryMediaUpload(file, this.library.id)

            const isSizeOk = (file.size / (1024 * 1024)) < 10

            if (!isSizeOk) {
                Toaster.show('error', 'Fichier trop volumineux. Taille maximale 10Mo', 5000)
            } else {
                Toaster.show('chargement', 'Traitement en cours...')

                handler.performUpload()
                    .then((r) => {
                        this.$store.commit('lib/setLibrary', {
                            ...this.library,
                            content: [...this.library.content, ...r.webpaths],
                        })
                        Toaster.show('success', 'Media uploadé avec succès', 2000)
                    }).catch((e) => {
                        Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                    })
            }
        },
        async deleteItem(e, item) {
            e.stopPropagation()
            Toaster.show('info', 'Patientez...')

            try {
                await this.$store.dispatch('lib/deleteLibraryItem', {
                    id: this.library.id,
                    item,
                })
                this.library.content = this.library.content.filter((i: string) => i !== item)
                Toaster.show('success', 'Media supprimé avec succès', 2000)
            } catch (error) {
                Toaster.show('error', `Une erreur est survenue: ${error.toString()}`, 2500)
            }
            this.itemToDelete = null
        },
        onDragStart(item: string) {
            this.$store.commit('lib/setLibraryDraggedItem', item)
        },
        onDragEnd() {
            this.$store.commit('lib/setLibraryDraggedItem', null)
        },
    },
})
