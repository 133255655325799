
import Vue from 'vue'

export default Vue.extend({
    name: 'AdminCreateUserModal',
    props: {
        name: { type: String, default: null, required: true },
    },
    data() {
        return {
            user: {
                name: '',
                username: '',
                nickname: '',
                roles: [],
            },
        }
    },
    methods: {
        cancel() {
            this.$modal.hide(this.name)
        },
        create() {
            this.$store.dispatch('admin/createAccount', this.user)
            this.$modal.hide(this.name)
        },
    },
})
