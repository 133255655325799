export const targetStatus = [
    '1. offline - préparation',
    '2. en ligne',
    '3. stats à sortir',
    '3.1 Urgent - Stats à sortir',
    '3.2 stats intermédiaires',
    '4. feedback à préparer',
    '5. feedback prêt',
    '6. feedback réalisé',
]

export const types = [
    'post',
    'video',
    'quiz',
    'search',
    'story',
    'story video',
    'contenu additionel',
    'notification',
    'mise en avant',
    'nl hebdo',
    'référencement',
    'sondage',
    'emailling dédié',
    'medacademy',
    'conseil associé',
    'datas',
    'flash notif',
]

export const actors = [
    'Banques et assurances',
    'DM',
    'Laboratoire',
    'Santé animale',
    'Start-up',
]

export const populationCategories = [
    'Médecin',
    'Médecine générale',
    'Autre profession de santé',
    'Dentiste',
    'Infirmier/Infirmière',
    'Kinésithérapeute',
    'Patient',
    'Pharmacien',
    'Préparateur en pharmacie',
    'Pédicure - Podologue',
    'Sage Femme',
    'Secrétaire médicale',
    'Allergologie',
    'Anatomie et cytologie pathologique',
    'Anesthésie-réanimation',
    'Autre',
    'Biologie médicale',
    'Cardiologie et maladies vasculaires',
    'Chirurgie de la face et du cou',
    'Chirurgie générale',
    'Chirurgie maxillo-faciale',
    'Chirurgie orale',
    'Chirurgie orthopédique et traumatologique',
    'Chirurgie plastique reconstructrice et esthétique',
    'Chirurgie pédiatrique',
    'Chirurgie thoracique et cardio-vasculaire',
    'Chirurgie vasculaire',
    'Chirurgie viscérale et digestive',
    'Dermatologie et vénérologie',
    'Endocrinologie-diabétologie-nutrition',
    'Gynécologie médicale',
    'Gynécologie-obstétrique',
    'Génétique médicale',
    'Gériatrie',
    'Hématologie',
    'Hépato-gastro-entérologie',
    'Maladies infectieuses et tropicales',
    "Médecine d'urgence",
    'Médecine et Santé au travail',
    'Médecine intensive-réanimation',
    'Médecine interne',
    'Médecine légale et expertises médicales',
    'Médecine nucléaire',
    'Médecine physique et de réadaptation',
    'Médecine vasculaire',
    'Neurochirurgie',
    'Neurologie',
    'Néphrologie',
    'Onco-hématologie',
    'Oncologie médicale',
    'Oncologie radiothérapie',
    'Ophtalmologie',
    'Oto-rhino-laryngologie et chirurgie cervico-faciale',
    'Pathologies cardio-vasculaires',
    'Pneumologie',
    'Psychiatrie',
    'Pédiatrie',
    'Radio-diagnostic et imagerie médicale',
    'Rhumatologie',
    'Santé publique et médecine sociale',
    'Sport and exercise medicine',
    'Urologie',
]

export const populationCategoriesMapToId = [
    {
        id: '1',
        label: 'Specialty',
        name: 'Anesthésie-réanimation',
    },
    {
        id: '11',
        label: 'Specialty',
        name: 'Cardiologie et maladies vasculaires',
    },
    {
        id: '35',
        label: 'Specialty',
        name: 'Chirurgie de la face et du cou',
    },
    {
        id: '4',
        label: 'Specialty',
        name: 'Chirurgie générale',
    },
    {
        id: '34',
        label: 'Specialty',
        name: 'Chirurgie maxillo-faciale',
    },
    {
        id: '6',
        label: 'Specialty',
        name: 'Chirurgie orale',
    },
    {
        id: '36',
        label: 'Specialty',
        name: 'Chirurgie orthopédique et traumatologique',
    },
    {
        id: '33',
        label: 'Specialty',
        name: 'Chirurgie pédiatrique',
    },
    {
        id: '37',
        label: 'Specialty',
        name: 'Chirurgie plastique reconstructrice et esthétique',
    },
    {
        id: '38',
        label: 'Specialty',
        name: 'Chirurgie thoracique et cardio-vasculaire',
    },
    {
        id: '40',
        label: 'Specialty',
        name: 'Chirurgie vasculaire',
    },
    {
        id: '41',
        label: 'Specialty',
        name: 'Chirurgie viscérale et digestive',
    },
    {
        id: '5',
        label: 'Profession',
        name: 'Dentiste',
    },
    {
        id: '12',
        label: 'Specialty',
        name: 'Dermatologie et vénérologie',
    },
    {
        id: '9',
        label: 'Specialty',
        name: 'Endocrinologie',
    },
    {
        id: '42',
        label: 'Specialty',
        name: 'Gériatrie',
    },
    {
        id: '7',
        label: 'Specialty',
        name: 'Gynécologie médicale',
    },
    {
        id: '32',
        label: 'Specialty',
        name: 'Gynécologie-obstétrique',
    },
    {
        id: '43',
        label: 'Specialty',
        name: 'Hématologue',
    },
    {
        id: '13',
        label: 'Specialty',
        name: 'Hépato-gastro-entérologue',
    },
    {
        id: '3',
        label: 'Profession',
        name: 'Infirmier/Infirmère',
    },
    {
        id: '44',
        label: 'Specialty',
        name: 'Maladies infectieuses et tropicales',
    },
    {
        id: '45',
        label: 'Specialty',
        name: "Médecine d'urgence",
    },
    {
        id: '26',
        label: 'Specialty',
        name: 'Médecine générale',
    },
    {
        id: '50',
        label: 'Specialty',
        name: 'Médecine intensive-réanimation',
    },
    {
        id: '15',
        label: 'Specialty',
        name: 'Médecine interne',
    },
    {
        id: '16',
        label: 'Specialty',
        name: 'Médecine nucléaire',
    },
    {
        id: '17',
        label: 'Specialty',
        name: 'Médecine physique et de réadaptation',
    },
    {
        id: '47',
        label: 'Specialty',
        name: 'Médecine vasculaire',
    },
    {
        id: '5',
        label: 'Specialty',
        name: 'Neurochirurgien',
    },
    {
        id: '19',
        label: 'Specialty',
        name: 'Neurologie',
    },
    {
        id: '18',
        label: 'Specialty',
        name: 'Néphrologue',
    },
    {
        id: '4',
        label: 'Profession',
        name: 'Pharmacien',
    },
    {
        id: '10',
        label: 'Profession',
        name: 'Préparateur en pharmacie',
    },
    {
        id: '6',
        label: 'Profession',
        name: 'Sage-Femme',
    },
    {
        id: '30',
        label: 'Specialty',
        name: 'Onco-hématologue',
    },
    {
        id: '10',
        label: 'Specialty',
        name: 'Oncologie médicale',
    },
    {
        id: '31',
        label: 'Specialty',
        name: 'Oncologie radiothérapie',
    },
    {
        id: '3',
        label: 'Specialty',
        name: 'Oto-rhino-laryngologie et chirurgie cervico-faciale',
    },
    {
        id: '27',
        label: 'Specialty',
        name: 'Pédiatrie',
    },
    {
        id: '21',
        label: 'Specialty',
        name: 'Pneumologue',
    },
    {
        id: '28',
        label: 'Specialty',
        name: 'Psychiatre',
    },
    {
        id: '23',
        label: 'Specialty',
        name: 'Rhumatologue',
    },
    {
        id: '39',
        label: 'Specialty',
        name: 'Urologue',
    },
]
