
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import ConfirmModal from '@/components/ConfirmModal.vue'
import { Toaster, ToasterType } from '@/components/Toaster'
import { normalDate } from '@/filters/simpday.filter'
import { State } from '@/store'
import { isEmpty } from '@/utils/isEmpty'
import { articleReqFields } from '@/models/artefact-required-fields.models'
import ArticleEditPart0 from '@/views/partials/article/ArticleEditPart0.vue'
import ArticleEditPart1 from '@/views/partials/article/ArticleEditPart1.vue'
import ArticleEditPart2 from '@/views/partials/article/ArticleEditPart2.vue'
import ArticleEditPart3 from '@/views/partials/article/ArticleEditPart3.vue'
import ArticleEditPart4 from '@/views/partials/article/ArticleEditPart4.vue'
import ArticleEditPart6 from '@/views/partials/article/ArticleEditPart6.vue'
import ArticleEditPart7 from '@/views/partials/article/ArticleEditPart7.vue'
import ArticlePreview from '@/views/partials/article/ArticlePreview.vue'
import ImagesEditor from '@/components/ImagesEditor.vue'
import StoryCreator from '@/views/partials/StoryCreator.vue'
import ConfirmButton from '@/components/ConfirmButton.vue'
import { AttachmentUpload } from '@/components/AttachmentUpload'
import { convertToFile } from '@/utils/base64'

export default Vue.extend({
    name: 'ArticleEditPage',
    filters: { date: normalDate },
    components: {
        ArticleEditPart0,
        ArticleEditPart1,
        ArticleEditPart2,
        ArticleEditPart3,
        ArticleEditPart4,
        ArticleEditPart6,
        ArticleEditPart7,
        ArticlePreview,
        ConfirmButton,
        ConfirmModal,
        ImagesEditor,
        StoryCreator,
    },
    data() {
        return {
            isNew: false,
            missingFields: [],
            validationMsgs: [],
            isPreviewDeployed: false,
            previewScale: 1,
            isPending: false,
        }
    },
    mounted() {
        this.resetPreviewHistory()
    },
    computed: {
        ...mapState({
            article: (state: State) => state.art.article,
            loading: (state: State) => state.xhr.loading,
            initValues: (state: State) => state.previewHistory.initValues,
        }),
        shouldDisplayPreview() {
            return !this.isNew || this.initValues.isActive
        },
    },
    async created() {
        this.$store.commit('art/setArticle', null)
        if (this.$route.params.id === 'new') {
            this.isNew = true
            this.$store.commit('art/createNewArticle', {
                type: 'post',
                name: '',
            })
        } else {
            await this.$store.dispatch('art/loadArticle', this.$route.params.id)
        }
        this.$store.commit('previewHistory/setPreviewHistory', { ...this.article })
    },
    methods: {
        ...mapMutations({
            resetPreviewHistory: 'previewHistory/resetPreviewHistory',
        }),
        async back() {
            if (!this.validate(this.article.published)) {
                Toaster.show('warning', this.validationMsgs.join(' / '), 3000)
                setTimeout(() => { this.missingFields = [] }, 3000)
                return
            }

            this.submit().then(() => this.$router.push('/articles'))
        },
        async submit() {
            Toaster.show('info', 'Saving...')

            if (!this.validate(this.article.published)) {
                Toaster.show('warning', this.validationMsgs.join(' / '), 3000)
                setTimeout(() => { this.missingFields = [] }, 3000)
                return
            }

            try {
                const now = Math.floor(Math.floor(new Date().getTime() / 1000))
                if (this.isNew) {
                    const newContentId = await this.createArticle(now)
                    await this.uploadPreviewImages(now, newContentId)
                    this.$router.push(`/articles/edit/${newContentId}`)
                    this.isNew = false
                } else {
                    await this.uploadPreviewImages(now, this.article.id)
                    await this.$store.dispatch('art/updateArticle', {
                        article: {
                            ...this.article,
                            updatedAt: now,
                        },
                    })
                }
                this.$store.commit('previewHistory/setPreviewHistory', { ...this.article })
                Toaster.show('success', 'Enregistré', 1300)
            } catch (error) {
                Toaster.show('error', `Une erreur est survenue lors de  l'upload ${error.toString()}`, 2500)
            }
        },
        async createArticle(now: number) {
            return this.$store.dispatch('art/createArticle', {
                ...this.article,
                createdAt: now,
                updatedAt: now,
            })
        },
        async uploadFile(file: File, type: 'cover'|'logo', newContentId: number) {
            const uploadResult = await (new AttachmentUpload(file, newContentId).performUpload(type)) as { webpath: string }
            this.$emit('update')
            return uploadResult.webpath
        },
        async uploadPreviewImages(now: number, newContentId: number) {
            const imageUrl = this.article.image
            const logoUrl = this.article.logo
            if ((imageUrl || logoUrl) && this.initValues.isActive) {
                if (imageUrl) {
                    try {
                        const coverFile = await convertToFile(imageUrl, 'cover')
                        const coverPath = await this.uploadFile(coverFile, 'cover', newContentId)
                        this.article.images.shift()
                        this.article.images.unshift(coverPath)
                        this.article.image = undefined
                    } catch (e) {
                        console.error(e)
                        this.article.image = undefined
                        this.article.images.shift()
                        await this.showToast('warning', "L'image de ce site ne peut être uploadée", 2500)
                    }
                }
                if (logoUrl) {
                    try {
                        const logoFile = await convertToFile(logoUrl, 'logo')
                        this.article.logo = await this.uploadFile(logoFile, 'logo', newContentId)
                    } catch (e) {
                        console.error(e)
                        this.article.logo = this.initValues.logo
                        await this.showToast('warning', 'Le logo de ce site ne peut être uploadé', 2500)
                    }
                }
                await this.$store.dispatch('art/updateArticle', {
                    article: {
                        ...this.article,
                        updatedAt: now,
                    },
                })
            }
        },
        async showToast(type: ToasterType, message: string, duration: number) {
            return new Promise((resolve) => {
                Toaster.show(type, message, duration)
                setTimeout(resolve, duration)
            })
        },
        submitFromImagesEditor() {
            if (!this.article.images.length) {
                this.article.image = null
            }
            this.submit()
        },
        isArticleValidForPublication() {
            if (!this.article.published && this.article.type === 'video' && !this.article.video) {
                return 'Vous devez ajouter une vidéo avant de publier la publicité'
            }
            return null
        },
        async publishContent(value: boolean) {
            if (this.isArticleValidForPublication()) {
                Toaster.show('error', this.isArticleValidForPublication(), 3200)
                return
            }

            if (!this.validate(!this.article.published)) {
                Toaster.show('warning', this.validationMsgs.join(' / '), 3000)
                setTimeout(() => { this.missingFields = [] }, 3000)
                return
            }

            this.isPending = true

            try {
                await this.submit()
                await this.$store.dispatch('art/updateArticleProp', {
                    id: this.article.id,
                    prop: 'published',
                    value,
                })

                const successMessage = value
                    ? 'Le contenu est en ligne'
                    : 'Le contenu est offline'

                Toaster.show('success', successMessage, 2500)
            } catch (error) {
                Toaster.show('error', error, 3000)
            } finally {
                this.isPending = false
            }
        },
        validate(isForPublication = false) {
            const {
                name,
                autoStartAt,
                autoStopAt,
                autoPublishOptions: { emails },
            } = this.article
            let valid = true
            this.validationMsgs = []

            const errorsFormatter = [
                {
                    status: !isEmpty(autoStartAt) && isEmpty(emails),
                    name: ['email'],
                    message: 'Au moins un email doit être renseigné pour la publication automatique',
                },
                {
                    status: isEmpty(name),
                    name: ['name'],
                    message: 'Le nom du contenu est requis',
                },
                {
                    status: isEmpty(autoStartAt) && !isEmpty(autoStopAt),
                    name: ['autoStartAt'],
                    message: 'La date de publication est requise',
                },
                {
                    status: !isEmpty(autoStartAt) && isEmpty(autoStopAt),
                    name: ['autoStopAt'],
                    message: 'La date de dé-publication est requise',
                },
                {
                    status: this.article.type === 'story' && this.checkStoryColors(),
                    name: ['Story color'],
                    message: "Il y a une erreur dans le format d'une couleur de story",
                },
                {
                    status: !this.article._$audiences.some(({ label }) => label === 'Area'),
                    name: ['Area'],
                    message: "L'Area de l'audience est requise",
                },
            ]

            if (isForPublication) {
                errorsFormatter.push({
                    status: articleReqFields[this.article.type].some((key: string) => isEmpty(this.article[key])),
                    name: articleReqFields[this.article.type].filter((key: string) => isEmpty(this.article[key])),
                    message: `Les propriétés [${articleReqFields[this.article.type].filter((key: string) => isEmpty(this.article[key])).join(', ')}] sont requises`,
                })
            }

            errorsFormatter.forEach(({ status, name, message }) => {
                if (!status) {
                    return
                }

                valid = false
                this.missingFields.push(...name)
                this.validationMsgs.push(message)
            })

            return valid
        },
        checkStoryColors() {
            const { screens } = this.article.story
            const regex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i

            return screens.some(({ backgroundColor, footerBgColor, footerButtonTextColor, footerChevronColor, subtitleColor, titleColor }) => {
                return [backgroundColor, footerBgColor, footerButtonTextColor, footerChevronColor, subtitleColor, titleColor].some((item) => !item.match(regex))
            })
        },
        async convertToAdvert() {
            try {
                const id = await this.$store.dispatch('art/migrateArticleToAdvert', { id: this.article.id })
                this.$router.push(`/adverts/edit/${id}`)
            } catch (error) {
                Toaster.show('error', error, 3000)
            }
        },
        togglePreview() {
            this.isPreviewDeployed = !this.isPreviewDeployed
        },
        setPreviewScale(action: string) {
            if (action === '+' && this.previewScale <= 0.95) {
                this.previewScale += 0.05
            }

            if (action === '-' && this.previewScale >= 0.5) {
                this.previewScale -= 0.05
            }
        },
        displayImagesEditor(display: boolean) {
            this.$modal[display ? 'show' : 'hide']('imagesEditor')
        },
    },
})
