// base64 href looks like this : "data:image/png;base64, ivBO...Alkjlk"
export function stringIsBase64(base64: string) : boolean {
  const base64Regex = /^data:image\/[a-z-+.]+;base64,/
  return base64Regex.test(base64.toLowerCase())
}

// This method is used solely to provide the filename on the CDN, which subsequently forms the filename in the URL path,
// such as 'https://cdn-url/filename.extension'
// Therefore, we need to remove the '+xml' part from the file extensions to ensure that it doesn't disrupt the rendering of the image
export function extractImageExtension(base64: string): string {
  const matches = base64.toLowerCase().match(/^data:(image\/[a-z-+.]+);base64,/)
  if (matches && matches.length > 1) {
      const mimeType = matches[1]
      const ext = mimeType.split('/')[1]
      return ext.replace('+xml', '')
  }
  return undefined
}

export async function convertToFile(base64: string, type: 'cover'|'logo'): Promise<File> {
  const sanitizedBase64 = base64.split('?')[0]
  const fileName = `${type}.${extractImageExtension(sanitizedBase64)}`
  // Buffer objet can't be used on client side so we use fetch
  // https://ionic.io/blog/converting-a-base64-string-to-a-blob-in-javascript
  const fetchImage = await fetch(sanitizedBase64, {
      method: 'GET',
      headers: {
          accept: 'application/octet-stream',
      },
  })
  const blob = await fetchImage.blob()
  return new File([blob], fileName, { type: blob.type })
}
