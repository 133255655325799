
import Vue from 'vue'
import { dayjs } from '@/utils/dayjs'
import { mapState } from 'vuex'
import { DELAY_REASONS } from 'lib-ad-platform-models'
import { State } from '@/store'
import { isEmpty } from '@/utils/isEmpty'

export default Vue.extend({
    name: 'AdvertEditPart6',
    props: ['missingFields'],
    data() {
        return {
            showDelayReason: false,
            DELAY_REASONS,
            targetProps: {},
        }
    },
    computed: mapState({
        advert: (state: State) => state.advt.advert,
        autoStartAt() {
            return this.advert.autoStartAt
        },
    }),
    watch: {
        autoStartAt(newVal, oldVal) {
            const { _$target } = this.advert
            if (newVal && newVal !== oldVal && _$target && _$target.initialQuotationDate) {
                const oneDay = 86400
                const dateDiff = Math.round((newVal - _$target.initialQuotationDate) / oneDay)

                if (dateDiff > 15) {
                    this.showDelayReason = true
                } else {
                    this.showDelayReason = false
                    this.targetProps = {
                        delayReason: null,
                        releaseDateDiff: null,
                        revenueDelayImpact: null,
                    }
                    this.$emit('updateTargetProps', this.targetProps)
                }
            }

            if (this.advert.type === 'notification') {
                const startAt = dayjs(this.autoStartAt * 1000).local().format('YYYY-MM-DDTHH:mm')
                const autoStopAt = dayjs(startAt).add(7, 'days').unix()
                this.advert.autoStopAt = autoStopAt
            }
        },
    },
    methods: {
        fromUnixTimestamp(timestamp: number) {
            return timestamp !== null
                ? dayjs(timestamp * 1000).local().format('YYYY-MM-DDTHH:mm')
                : null
        },
        toUnixTimestamp(dateStr: string) {
            return !isEmpty(dateStr)
                ? dayjs(dateStr).valueOf() / 1000
                : null
        },
        clear() {
            this.advert.autoStopAt = null
            this.advert.autoStartAt = null
        },
        getReasonParts(reason: string) {
            return reason.split(' - ')
        },
        setDelayReason(reason: string) {
            const { _$target } = this.advert

            const oneDay = 86400
            const dateDiff = Math.round((this.advert.autoStartAt - _$target.initialQuotationDate) / oneDay)
            const broadcastDaysDuration = Math.round((_$target.endDate - this.advert.autoStartAt) / oneDay)

            this.targetProps = {
                delayReason: reason,
                releaseDateDiff: dateDiff,
                revenueDelayImpact: dateDiff <= 15 && _$target.revenue ? 0 : (_$target.revenue * (dateDiff - 15)) / broadcastDaysDuration,
            }

            this.$emit('updateTargetProps', this.targetProps)
        },
    },
})
