import PushApi from '@/api/push'
import { PushPayload, PushWhereCriterias } from '@/api/PushWhereNormalizer'

export type PushState = {
    count: number,
    progress: number,
    loading: boolean,
    sending: boolean,
    error: any
}

const state: PushState = ({
    count: 0,
    progress: 0,
    loading: false,
    sending: false,
    error: null,
})

const getters = {}

const mutations = {
    loading(state: PushState, loading: boolean): void {
        state.loading = loading
    },
    sending(state: PushState, sending: boolean): void {
        state.sending = sending
    },
    setError(state: PushState, error: string): void {
        state.error = error
    },
    setPushCount(state: PushState, counter: number): void {
        state.count = counter
    },
    setProgress(state: PushState, progress: number): void {
        state.progress = progress
    },
}

const actions = {
    async count({ commit }: any, payload: PushWhereCriterias): Promise<void> {
        commit('loading', true)

        return new Promise((resolve, reject) => {
            PushApi.count(payload)
                .then((result: any) => {
                    commit('setPushCount', result.counter)
                    commit('loading', false)
                    resolve(result.counter)
                }).catch((e: any) => {
                    commit('setError', e.toString())
                    commit('loading', false)
                    reject()
                })
        })
    },
    async send({ commit }: any, payload: PushPayload): Promise<boolean> {
        commit('sending', true)

        return new Promise((resolve, reject) => {
            PushApi.send(payload)
                .then(() => {
                    commit('setProgress', payload.$iteration)
                    resolve(true)
                }).catch((e: any) => {
                    commit('setError', e.toString())
                    reject(e)
                })
        })
    },
    sendStart: ({ commit }: any) => {
        commit('sending', true)
    },
    sendDone: ({ commit }: any) => {
        commit('sending', false)
    },
    progress: ({ commit }: any, payload: number) => {
        commit('setProgress', payload)
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
