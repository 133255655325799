import Vue from 'vue'
import Vuex from 'vuex'

import AdvertStore, { AdvertState } from '@/store/modules/advert.store'
import AlphonseStore, { AlphonseState } from '@/store/modules/alphonse.store'
import ArticleStore, { ArticleState } from '@/store/modules/article.store'
import AudienceStore, { AudienceState } from '@/store/modules/audience.store'
import ErrorStore, { ErrorState } from '@/store/modules/error.store'
import LibraryStore, { LibraryState } from '@/store/modules/library.store'
import LoadingStore, { LoadingState } from '@/store/modules/loading.store'
import MetaStore, { MetaState } from '@/store/modules/meta.store'
import ProjectStore, { ProjectState } from '@/store/modules/project.store'
import PushStore, { PushState } from '@/store/modules/push.store'
import UserStore, { UserState } from '@/store/modules/user.store'
import AdminStore, { AdminState } from '@/store/modules/admin.store'
import DeeplinkStore, { DeeplinkState } from '@/store/modules/deeplink.store'
import PreviewHistoryStore, { PreviewHistoryState } from '@/store/modules/preview-history.store'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        advt: AdvertStore,
        alph: AlphonseStore,
        art: ArticleStore,
        audn: AudienceStore,
        err: ErrorStore,
        lib: LibraryStore,
        xhr: LoadingStore,
        meta: MetaStore,
        proj: ProjectStore,
        push: PushStore,
        user: UserStore,
        admin: AdminStore,
        deeplink: DeeplinkStore,
        previewHistory: PreviewHistoryStore,
    },
})

export interface State {
    advt: AdvertState,
    alph: AlphonseState,
    art: ArticleState,
    audn: AudienceState,
    err: ErrorState,
    lib: LibraryState,
    xhr: LoadingState,
    meta: MetaState,
    proj: ProjectState,
    push: PushState,
    user: UserState,
    admin: AdminState,
    deeplink: DeeplinkState,
    previewHistory: PreviewHistoryState
}
