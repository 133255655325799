
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'

export default Vue.extend({
    name: 'AdvertEditPart7',
    data() {
        return {

        }
    },
    computed: mapState({
        advert: (state: State) => state.advt.advert,
    }),
    methods: {
        open(link: string) {
            if (!link) {
                return
            }
            window.open(link, '_blank')
        },
    },
})
