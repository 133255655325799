
import Vue from 'vue'

export default Vue.extend({
    name: 'InputText',
    props: {
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
        error: {
            type: Object,
            required: false,
        },
        defaultValue: {
            type: String,
            required: false,
        },
    },
    methods: {
        setValue(event: string) {
            this.$emit('inputValue', event)
        },
    },
})
