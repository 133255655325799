
import Vue from 'vue'
import { mapState } from 'vuex'
import { Advert, ArticleListFilter } from 'lib-ad-platform-models'
import { debounce } from 'debounce'
import ConfirmModal from '@/components/ConfirmModal.vue'
import MultiSelectDropdown from '@/components/MultiSelectDropdown.vue'
import { Toaster } from '@/components/Toaster'
import { ellipsis } from '@/filters/ellipsis.filter'
import { capitalize } from '@/filters/capitalize.filter'
import { simpday, normalDate } from '@/filters/simpday.filter'
import { State } from '@/store'
import ArticlePreview from '@/views/partials/article/ArticlePreview.vue'
import ListSearchLabelsRecap from '@/views/partials/ListSearchLabelsRecap.vue'

export default Vue.extend({
    name: 'ArticleListPage',
    components: {
        ConfirmModal,
        ListSearchLabelsRecap,
        MultiSelectDropdown,
        ArticlePreview,
    },
    filters: { ellipsis, capitalize, simpday, normalDate },
    data() {
        return {
            now: Math.round((new Date()).valueOf() / 1000),
            articleToDel: null,
            activeDropdown: '',
            articleFocused: null,
        }
    },
    computed: {
        ...mapState({
            loading: (state: State) => state.xhr.loading,
            articles: (state: State) => state.art.articles,
            filters: (state: State) => state.art.listFilters,
            professions: (state: State) => state.meta.professions,
            specialties: (state: State) => state.meta.specialties,
        }),
    },
    created() {
        if (this.$store.state.art.articles.length === 0) {
            this.refresh()
        }
    },
    methods: {
        refresh() {
            this.$store.dispatch('art/loadArticles', this.filters)
            this.$store.dispatch('meta/loadMeta')
        },
        create() {
            this.$router.push('/articles/edit/new')
        },
        sort(field: string, order: string) {
            this.$store.commit('art/updateArticlesFilter', { orderBy: field, order })
            this.refresh()
        },
        isSorted(field: string, order: string|null = null) {
            return order
                ? this.filters.orderBy === field && this.filters.order === order
                : this.filters.orderBy === field
        },
        filterBy(name: string, value: string | number = null) {
            const filter: any = {}
            filter[name] = (value === '') ? null : value

            this.$store.commit('art/updateArticlesFilter', { ...filter, skip: 0 })
            this.refresh()
        },
        filterByProfessions(e: any) {
            this.$store.commit('art/updateArticlesFilter', { professions: e.map((a) => a.id), skip: 0 })
            this.refresh()
        },
        filterBySpecialties(e: any) {
            this.$store.commit('art/updateArticlesFilter', { specialties: e.map((a) => a.id), skip: 0 })
            this.refresh()
        },
        filterByAreas(e: any) {
            this.$store.commit('art/updateArticlesFilter', { areas: e.map((a) => a.id), skip: 0 })
            this.refresh()
        },
        filterByCountries(e: any) {
            this.$store.commit('art/updateArticlesFilter', { countries: e.map((a) => a.id), skip: 0 })
            this.refresh()
        },
        isNewContent(article: any) {
            return Object.keys(article).includes('isNew')
        },
        filterRemove(e: any) {
            const defaultFilters = new ArticleListFilter()
            this.filters[e.key] = defaultFilters[e.key]

            if (e.key === 'professions') {
                this.$refs.m1.reset()
            }
            if (e.key === 'specialties') {
                this.$refs.m2.reset()
            }
            if (e.key === 'areas') {
                this.$refs.m3.reset()
            }
            if (e.key === 'countries') {
                this.$refs.m4.reset()
            }

            this.refresh()
        },
        onSearchKeyup(e: KeyboardEvent) {
            if (e.code === 'Backspace' && this.$refs.termInput.value.trim() === '') {
                this.filterRemove({ key: 'term' })
            }
        },
        onIdHover(article: any) {
            this.articleFocused = article
        },
        setActiveDropdown(label: string) {
            this.activeDropdown = label
        },
        filterByTerm() {
            this.$store.commit('art/updateArticlesFilter', { term: this.$refs.termInput.value, skip: 0 })
            this.refresh()
        },
        onScroll: debounce(function (e: any) {
            if ((e.srcElement.offsetHeight + e.srcElement.scrollTop + 1000) >= e.srcElement.scrollHeight) {
                this.$store.commit('art/updateArticlesFilter', { skip: (this.filters.skip + this.filters.limit) })
                this.$store.dispatch('art/loadArticles', this.filters)
            }
        }, 250),
        gotoEditPage(id: string) {
            this.$router.push(`/articles/edit/${id}`)
        },
        updateContentProp(id: string, prop: string, value: string|number|boolean) {
            this.$store.dispatch('art/updateArticleProp', { id, prop, value })
        },
        duplicateContent(article: Advert) {
            const now = Math.floor(Math.floor(new Date().getTime() / 1000))
            this.$store.dispatch('art/duplicateArticle', {
                ...article,
                createdAt: now,
                updatedAt: now,
            })
        },
        showDelConfirmModal(id: string) {
            this.articleToDel = id
            this.$modal.show('delContent')
        },
        deleteContent() {
            this.$store.dispatch('art/deleteArticle', this.articleToDel)
                .then(() => Toaster.show('success', 'Le post contenu a été supprimé', 2500))
            this.articleToDel = null
        },
        getAudienceResume(audiences: any[]) {
            const sortingArr = ['Profession', 'Specialty']

            const value = audiences
                .filter(({ label }) => ['Profession', 'Specialty'].includes(label))
                .sort((a, b) => sortingArr.indexOf(a.label) - sortingArr.indexOf(b.label))
                .map(({ name }) => (name.length > 20 ? `${name.substring(0, 20)}...` : name))
                .join('\n')

            return value
        },
    },
})
