
import Vue from 'vue'

export default Vue.extend({
    name: 'MultiSelectDropdown',
    props: {
        options: {},
        selected: { type: Array, default() { return [] } },
        label: { type: String, default: 'Titre' },
        multiple: { type: Boolean, default: true },
        showSearchInput: { type: Boolean, default: false },
        isActive: { type: Boolean, default: false },
    },
    data() {
        return {
            selectedOptions: [],
            shiftPressed: false,
            term: '',
            filteredOptions: this.options,
        }
    },
    created() {
        document.addEventListener('click', () => {
            this.$emit('activeDropdown', '')
        }, false)

        if (this.multiple !== false) {
            document.addEventListener('keydown', (e: KeyboardEvent) => {
                this.shiftPressed = (e.key === 'Shift')
            }, false)
            document.addEventListener('keyup', () => {
                this.shiftPressed = false
            }, false)
        }

        this.selectedOptions = this.selected
    },
    watch: {
        options() {
            this.filteredOptions = this.options
        },
    },
    methods: {
        stop(e) {
            e.stopPropagation()
        },
        filterOptions() {
            if (this.term.length > 0) {
                if (!this.options) {
                    this.searchList()
                } else {
                    this.filteredOptions = Object.keys(this.options).reduce((acc, value) => {
                        const formatedString = (text: string) => text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                        const { name } = this.options[value]

                        if (this.term.length < 3 && formatedString(name).startsWith(formatedString(this.term))) {
                            return [...acc, this.options[value]]
                        }

                        if (this.term.length >= 3 && formatedString(name).includes(formatedString(this.term))) {
                            return [...acc, this.options[value]]
                        }

                        return acc
                    }, [])
                }
            } else {
                this.filteredOptions = this.options
            }
        },
        searchList() {
            this.$store
                .dispatch('proj/getFilterList', {
                    label: this.label,
                    term: this.term,
                    limit: 10,
                    skip: 0,
                })
                .then((list: any) => {
                    this.filteredOptions = list
                })
        },
        show(e: any) {
            e.stopPropagation()

            if (this.isActive) {
                this.$emit('activeDropdown', '')
            } else {
                this.$emit('activeDropdown', this.label)
                this.term = ''
                this.filteredOptions = this.options
            }
        },
        reset() {
            this.selectedOptions = []

            for (const i in this.$refs.optCheck) {
                this.$refs.optCheck[i].checked = false
            }
        },
        checked(option: any) {
            const selectedIds = this.selectedOptions.map((o) => o.id)
            const searchIndex = selectedIds.indexOf(option.id)

            return (searchIndex > -1)
        },
        nothing(e: MouseEvent) {
            if (this.shiftPressed) { e.stopPropagation() }
        },
        toggleOption(option: any, i: number, e: MouseEvent) {
            if (this.shiftPressed) { e.stopPropagation() }

            if (option === null) {
                this.selectedOptions = []
                this.$refs.optCheck.foreach((c: any) => { c.checked = false })
                this.$emit('change', [])
                return
            }

            const selectedIds = this.selectedOptions.map((o) => o.id)
            const searchIndex = selectedIds.indexOf(option.id)

            if (this.multiple === false) {
                this.unckeckAll()
            }

            if (this.checked(option, i)) {
                // option selected, deselect it
                this.$refs.optCheck[i].checked = false
                this.selectedOptions.splice(searchIndex, 1)
            } else {
                // option not selected, select it
                this.$refs.optCheck[i].checked = true
                this.selectedOptions.push(option)
            }

            this.$emit('change', this.selectedOptions)
        },
        unckeckAll() {
            this.selectedOptions = []

            for (const i in this.$refs.optCheck) {
                this.$refs.optCheck[i].checked = true
            }
        },
    },
})
