export const getFirstAndLastMonthOfYear = (year: number) :{formatedStartDate: string, formaredEndDate: string} => {
    const actualDate = new Date()
    actualDate.setFullYear(year)
    actualDate.setMonth(0)
    const month = `${actualDate.getMonth() < 9 ? '0' : ''}${actualDate.getMonth() + 1}`
    const yearLastTwoDigits = actualDate.getFullYear().toString().substr(-2)
    const formatedStartDate = `${yearLastTwoDigits}/${month}`
    const formaredEndDate = `${yearLastTwoDigits}/12`
    return { formatedStartDate, formaredEndDate }
}
