export const KEY_ACCOUNT_LIST = {
    5: 'romain',
    171571: 'coco',
    386390: 'éléa',
    421519: 'liza',
    373030: 'ophélie',
    421518: 'pitch',
    314842: 'tessa',
    150616: 'clotilde',
    429398: 'charles',
    512406: 'constance',
    512407: 'david',
    552801: 'selima',
    581670: 'julien',
    587299: 'mélanie',
    583911: 'carole-anne',
    608571: 'amandine',
    658941: 'carolina',
    648876: 'siham',
    656780: 'céline',
    641257: 'luca',
    '1e0363e0-96db-4dcf-b55f-e91fdc7ae730': 'mathilde',
}
