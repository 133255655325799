
import Vue from 'vue'
import { mapState } from 'vuex'
import { Node, Relation, REL_ACTION_OPTIONS } from 'lib-ad-platform-models'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import { isGroup } from '@/utils/isGroup'

export default Vue.extend({
    name: 'RelationshipGroup',
    props: {
        group: { type: Array, default: null },
        label: { type: String, default: null },
        index: { type: Number },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            isGroup,
            hiddenGroups: [],
            specDropdownOpened: null,
            groupUnderEdition: null,
        } as any
    },
    computed: mapState({
        audience: (state: State) => state.audn.audience,
    }),
    mounted() {
        document.addEventListener(
            'click',
            () => {
                this.specDropdownOpened = null
            },
            false,
        )
    },
    methods: {
        hasAnyRole(roles: string[]) {
            // user can have only ONE of the roles specified, but needs at least one
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        relActions(node: any) {
            const { label } = node
            return typeof REL_ACTION_OPTIONS[label] !== 'undefined'
                ? REL_ACTION_OPTIONS[label]
                : [null]
        },
        specDropdownToggle(i: number) {
            if (this.disabled) {
                return
            }

            if (this.specDropdownOpened === i) {
                this.specDropdownOpened = null
            } else {
                this.specDropdownOpened = i
            }
        },
        changeSpec(rel: any, event: string) {
            const source = { id: this.audience.id, label: this.audience.label }
            const target = { id: rel.id, label: rel.label }

            rel.event = event
            const relation: Relation = new Relation(rel)

            this.specDropdownOpened = null
            this.$store
                .dispatch('audn/updateAudienceRel', {
                    source,
                    target,
                    relationship: relation,
                })
                .then(() => {
                    Toaster.show('success', 'Saved.', 1300)
                })

            this.$emit('someEvent')
        },
        toggleGroup(index: number) {
            if (this.groupIsHidden(index)) {
                this.showGroup(index)
            } else {
                this.hideGroup(index)
            }
        },
        showGroup(index: number) {
            if (this.groupIsHidden(index)) {
                const idx = this.hiddenGroups.indexOf(index)
                if (idx > -1) {
                    this.hiddenGroups.splice(idx, 1)
                }
            }
        },
        hideGroup(index: number) {
            if (this.groupIsVisible(index)) {
                this.hiddenGroups.push(index)
            }
        },
        groupIsHidden(index: number) {
            return this.hiddenGroups.indexOf(index) > -1
        },
        groupIsVisible(index: number) {
            return !this.groupIsHidden(index)
        },
        removeItem(item: any) {
            if (this.disabled) {
                return
            }

            const payload = {
                source: new Node(this.audience),
                target: new Node(item),
            }

            this.$store.dispatch('audn/rmAudienceRel', payload).then(() => {
                Toaster.show('success', 'Saved.', 1300)
            })

            this.$emit('someEvent')
        },
        dettachItem(item: any) {
            const payload = { source: this.audience, target: item }
            this.$store.dispatch('audn/detachAudienceRel', payload).then(() => {
                Toaster.show('success', 'Saved.', 1300)
            })
        },
    },
})
