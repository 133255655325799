
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'

export default Vue.extend({
    name: 'ArticleEditPart0',
    props: ['missingFields'],
    data() {
        return {
        }
    },
    computed: mapState({
        article: (state: State) => state.art.article,
    }),
    methods: {
        openPinnedArticle(id: string) {
            const path = this.$router.resolve({ path: `/articles/edit/${id}` })
            window.open(path.href, '_blank')
        },
    },
})
