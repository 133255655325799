export function contains(target: string, pattern: Array<string>): boolean {
    let value = false
    pattern.forEach((word) => {
        if (target.includes(word)) {
            value = true
        }
    })

    return value
}
