
import Vue from 'vue'
import { Advert, Article } from 'lib-ad-platform-models'
import { mapMutations } from 'vuex'
import sourceMethodsMixin from '@/views/partials/deeplink/sourceMethodsMixin'
import SourceFormSearch from '@/views/partials/deeplink/SourceFormSearch.vue'
import SourceFormApplication from '@/views/partials/deeplink/SourceFormApplication.vue'
import SourceFormContent from '@/views/partials/deeplink/SourceFormContent.vue'
import SourceFormMedics from '@/views/partials/deeplink/SourceFormMedics.vue'
import SourceFormAppParts from '@/views/partials/deeplink/SourceFormAppParts.vue'
import { Deeplink } from '@/models/deeplink'

export default Vue.extend({
    name: 'DeeplinkGenModal',
    mixins: [sourceMethodsMixin],
    components: {
        SourceFormSearch,
        SourceFormApplication,
        SourceFormContent,
        SourceFormMedics,
        SourceFormAppParts,
    },
    props: {
        deeplinkParent: [Article, Advert],
        deeplink: {
            type: Object as () => Deeplink,
            required: null,
        },
    },
    data() {
        return {
            activeSourceIndex: 0,
            activeByDefaultIndexes: [0, 4],
            sourcesChoice: ['Action search', 'Contenu', 'Fiche medics', 'Application', 'Partie de l\'app'],
            isCompleted: true,
        }
    },
    methods: {
        ...mapMutations({
            resetDeeplink: 'deeplink/resetDeeplink',
        }),
        openModal() {
            this.$modal.show('newDeeplink')
        },
        closeModal(actionBtn: string) {
            if (actionBtn === 'cancel') {
                this.$modal.hide('newDeeplink')
                this.setValues(null, this.deeplinkParent)
            } else if (this.isCompleted) {
                this.setValues(this.deeplink.url, this.deeplinkParent)
                this.$modal.hide('newDeeplink')
            }
        },
        setActiveSource(index: number) {
            this.resetDeeplink()
            this.activeSourceIndex = index
            // index 0 and 4 are valid by default
            this.isCompleted = this.activeByDefaultIndexes.includes(index)
        },
        toggleCompleted(isCompleted: boolean) {
            this.isCompleted = isCompleted
        },
    },
})

