
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'

export default Vue.extend({
    name: 'AdvertEditPart4',
    data() {
        return {

        }
    },
    computed: mapState({
        advert: (state: State) => state.advt.advert,
    }),
})
