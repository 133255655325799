
import Vue from 'vue'
import { highlight } from '@/filters/highlight.filter'

export default Vue.extend({
    name: 'CellAdvertIdPicker',
    filters: { highlight },
    props: {
        center: {
            type: Boolean,
            default: false,
        },
        value: [String, Number],
        ukLabelClass: String,
        type: String,
        status: String,
    },
    computed: {
        classColor() {
            const color = {
                '1. offline - préparation': 'status1',
                '2. en ligne': 'status2',
                '3. stats à sortir': 'status3',
                '3.1 Urgent - Stats à sortir': 'status31',
                '3.2 stats intermédiaires': 'status32',
                '4. feedback à préparer': 'status4',
                '5. feedback prêt': 'status5',
                '6. feedback réalisé': 'status6',
                '7. annulé': 'status7',
            }

            return color[this.status]
        },
    },
    methods: {
        openAdvert() {
            if (this.type === 'advert' && this.value) {
                const routeData = this.$router.resolve(`/adverts/edit/${this.value}`)
                window.open(routeData.href, '_blank')
            } else if (this.type === 'tool' && this.value) {
                window.open(`https://360medics.com/sonata/medical/core/app/${this.value}/edit`, '_blank')
            }
        },
        hideAdvertPreview() {
            this.$store.commit('advt/setAdvertPreview', null)
        },
        showAdvertPreview() {
            if (this.type === 'advert' && this.value) {
                this.$store.dispatch('advt/loadAdvertForPreview', this.value)
            }
        },
    },
})
