
import Vue from 'vue'
import Papa from 'papaparse'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'CsvImportButton',
    props: {
        requiredKeys: {
            required: false,
            default: [],
        },
        actionCalled: {
            required: true,
        },
    },
    data() {
        return {
            formData: null,
        }
    },
    methods: {
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        async onCsvImport() {
            if (this.$refs.csvFile.files.length === 0) {
                return
            }

            const [csvFile] = this.$refs.csvFile.files

            try {
                const missingKeys = await this.checkCSVKeys(csvFile)
                const isAllDatesValide = await this.checkCSVDates(csvFile)

                if (missingKeys.length) {
                    Toaster.show('error', `Missing columns : ${missingKeys.join(' | ')}`, 4000)
                    this.$refs.csvFile.value = null
                    return
                }

                if (!isAllDatesValide) {
                    Toaster.show('error', 'Wrong format of dates, they should be formated like this: YY/MM (ex: 22/01)', 4000)
                    this.$refs.csvFile.value = null
                    return
                }

                this.formData = new FormData()
                this.formData.append('file', csvFile)

                Toaster.show('chargement', 'CSV en cours de traitement')

                await this.$store.dispatch(this.actionCalled, this.formData)

                Toaster.show('success', 'CSV uploadé avec succès', 3000)
            } catch (error) {
                Toaster.show('error', error, 3000)
            }
        },
        async checkCSVKeys(file) {
            const reader = new FileReader()
            reader.readAsText(file)

            const result :string | ArrayBuffer = await new Promise((resolve) => {
                reader.onload = () => {
                    resolve(reader.result)
                }
            })

            const tempResult = Papa.parse(result, { header: true }).data

            return (this.requiredKeys.filter((key) => !(key in tempResult[0])))
        },

        async checkCSVDates(file) {
            const reader = new FileReader()
            reader.readAsText(file)

            const result :string | ArrayBuffer = await new Promise((resolve) => {
                reader.onload = () => {
                    resolve(reader.result)
                }
            })

            const tempResult = Papa.parse(result, { header: true }).data[0]

            const dataToDelete = ['Profession', 'Specialty', 'Nom']
            dataToDelete.forEach((e: string) => { delete tempResult[e] })

            const arrayOfDates = Object.keys(tempResult)

            const isDateValide = (input: string) => {
                const regex = /^[0-9]{2}[/]{1}[0-9]{2}/g
                return regex.test(input)
            }

            return arrayOfDates.every((date) => {
                if (!isDateValide(date)) {
                    return false
                }
                return true
            })
        },
    },
})
