
import Vue from 'vue'
import GroupEditor from '@/components/audience/GroupEditor.vue'

export default Vue.extend({
    name: 'AudienceGroupEditPage',
    components: {
        GroupEditor,
    },
    computed:
    {
        label() {
            return this.$route.params.label
        },
        id() {
            return this.$route.params.id
        },
    },
})
