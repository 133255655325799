
import Vue from 'vue'
import { dayjs } from '@/utils/dayjs'
import { isEmpty } from 'lodash'

export default Vue.extend({
    name: 'FeedbacksDateModal',
    props: ['target'],
    data() {
        return {
            props: null,
        }
    },
    computed: {
        checkDate() {
            if (this.target.checkDate !== null) {
                return dayjs(this.target.checkDate * 1000).local().format('YYYY-MM-DD')
            }
            return null
        },
    },
    methods: {
        submitDate(dateStr: string) {
            let updatedEpoch: number

            if (isEmpty(dateStr)) {
                updatedEpoch = null
            } else {
                updatedEpoch = dayjs(dateStr).unix()
            }

            this.props = {
                checkDate: updatedEpoch,
                status: '3.2 stats intermédiaires',
            }
        },
        updateProps() {
            if (!this.props) {
                this.$emit('updateProps', {
                    checkDate: this.target.checkDate,
                    status: '3.2 stats intermédiaires',
                })
            } else {
                this.$emit('updateProps', this.props)
            }
        },
    },

})
