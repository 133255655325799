
import Vue from 'vue'
import uniqid from 'uniqid'
import { Advert } from 'lib-ad-platform-models'
import { Toaster } from '@/components/Toaster'
import { AttachmentUpload } from '@/components/AttachmentUpload'

export default Vue.extend({
    name: 'BannerPreview',
    props: {
        advert: { type: Object, default: null },
        editable: { type: Boolean, default: false },
    },
    methods:
    {
        ensureCoverImg(advert: Advert) {
            const defImg = (advert.type === 'post') ? '/images/default_cover_image.png' : '/images/default_cover_image.png'
            return `${(advert.image !== 'undefined' && advert.image !== null) ? advert.image : defImg}?d=${uniqid()}`
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()
            if (!this.editable) {
                return
            }

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onCoverMediaChanged() {
            if (this.$refs.coverInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')
            const destination = 'cover'

            const handler = new AttachmentUpload(this.$refs.coverInput.files[0], this.advert.id)

            handler.performUpload(destination)
                .then((r) => {
                    Toaster.show('success', 'Bannière enregistrée', 1300)

                    this.advert.image = r.webpath
                    this.$refs.coverMedia.src = r.webpath
                }).catch((e) => {
                    Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                })
        },
    },
})
