
import Vue from 'vue'
import { dayjs } from '@/utils/dayjs'
import uniqid from 'uniqid'
import Api from '@/api/api'
import ConfirmModal from '@/components/ConfirmModal.vue'
import EventBus from '@/components/EventBus'
import { CdnItem } from '@/models/CdnItemType'
import CdnObjectItem from '@/views/partials/CdnObjectItem.vue'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'CdnDocumentUpload',
    components: { CdnObjectItem, ConfirmModal },
    data() {
        return {
            items: [],
            files: [],
            uploading: false,
            selectedItem: null,
            pdfViewerUrl: process.env.VUE_APP_PDF_VIEWER_URL,
            cdnUrlDg: process.env.VUE_APP_CDN_DOMAIN,
            cdnUrl: process.env.VUE_APP_CDN_DOMAIN_ALIAS,
            folderPrefix: '',
            liveAddFolderInput: null,
            fileToDelete: null,
        }
    },
    mounted() {
        this.loadItems()

        EventBus.$on('fileClicked', (item: any) => {
            this.files = []
            this.selectedItem = { ...item }
        })

        EventBus.$on('folderClicked', (item: any) => {
            this.selectedItem = item
            this.reloadFolder(item)
        })

        EventBus.$on('folderUnselected', (item: any) => {
            this.selectedItem = null
            item.children = []
        })
    },
    methods: {
        getPdfViewerUrl(url:string) {
            return `${this.pdfViewerUrl}/pdfviewer/index.html?f=${url}?${uniqid()}=${new Date().valueOf()}`
        },
        openDeleteFileModal(filePath: string) {
            this.fileToDelete = filePath
            this.$modal.show('confirm-delete-file')
        },
        async deleteFile() {
            try {
                Toaster.show('info', 'Patientez...')
                await Api.delete('/f/document/delete', { filePath: this.fileToDelete })
                Toaster.show('success', 'Fichier supprimé avec succès', 2500)

                this.uploading = true

                if (this.selectedItem === null || typeof this.selectedItem.Prefix === 'undefined') {
                    this.loadItems()
                } else {
                    this.reloadFolder(this.selectedItem)
                }

                this.selectedItem = null
                this.fileToDelete = null
                this.uploading = false
            } catch (error) {
                Toaster.show('error', `Une erreur est survenue: ${error.toString()}`, 2500)
            }
        },
        close() {
            this.editedUser = null
            this.success = false
        },
        openNewFolderModal(selectedItem: CdnItem) {
            this.$modal.show('add-folder-modal', { selectedItem })
        },
        reloadFolder(item: CdnItem) {
            // item.loading = true
            Api.get(`/f/cdn/list?folder=${item.Prefix}`)
                .then((result: any) => {
                    this.files = [...result.files]
                    item.children = [...result.files, ...result.folders]
                }).catch((e) => {
                    Toaster.show('error', e, 2500)
                })
        },
        loadItems(folder: string = null) {
            const path = (folder !== null) ? `/f/cdn/list?folder=${folder}` : '/f/cdn/list'

            Api.get(path)
                .then((result: any) => {
                    this.items = [...result.files, ...result.folders]
                }).catch((e) => {
                    Toaster.show('error', e, 2500)
                })
        },
        path(itemKey: string) {
            return `${this.cdnUrl}/${itemKey}`
        },
        rmDir(path: string) {
            return path.split('\\').pop().split('/').pop()
        },
        getFormatedDate(date:string) {
            return dayjs(date).format('DD/MM/yyyy')
        },
        isFolder(path: string) {
            return (path.split('.').length === 1)
        },
        isPdf(path: string) {
            const uris = path.split('.')
            return uris[uris.length - 1] === 'pdf'
        },
        isImg(path: string) {
            const uris = path.split('.')
            return ['png', 'jpg', 'jpeg', 'tiff'].indexOf(uris[uris.length - 1]) > -1
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        addFolderConfirm() {
            let finalPath: string

            // check aggressively if NO slashes are here !
            // and that its not empty when you select a file

            if (this.selectedItem === null || typeof this.selectedItem.Prefix === 'undefined') {
                finalPath = `assets/clients/${this.$refs.addFolderInput.value}`
            } else {
                finalPath = `${this.selectedItem.Prefix}${this.$refs.addFolderInput.value}`
            }

            const formData = new FormData()
            formData.append('path', `${finalPath}`)
            formData.append('file', new File([''], '.keep'))
            this.uploading = true

            Api.upload('/f/document/upload', formData)
                .then(() => {
                    if (this.selectedItem === null || typeof this.selectedItem.Prefix === 'undefined') {
                        this.loadItems()
                    } else {
                        this.reloadFolder(this.selectedItem)
                    }

                    this.uploading = false
                    this.$modal.hide('add-folder-modal')
                    // this.items = [...result.files, ...result.folders]
                }).catch(() => {
                    this.uploading = false
                    this.$modal.hide('add-folder-modal')
                })
        },
        upload() {
            let cdnPath: string

            // if (null === this.selectedItem) {
            //    alert('Gotta chosse a folder')
            //    return
            // }

            if (this.selectedItem !== null) {
                cdnPath = `${this.selectedItem.Prefix}`
            } else {
                cdnPath = 'assets/clients'
            }

            if (this.folderPrefix !== '') {
                cdnPath = `${cdnPath}/${this.folderPrefix}`
            }

            if (!this.isFolder(cdnPath)) {
                console.log(`Path ${cdnPath} is not a folder`)
                return
            }

            const formData = new FormData()
            formData.append('path', cdnPath)
            formData.append('file', this.$refs.filesBrowser.files[0])
            this.uploading = true

            // yeah
            Api.upload('/f/document/upload', formData)
                .then(() => {
                    this.reloadFolder(this.selectedItem)
                    this.uploading = false
                    // this.items = [...result.files, ...result.folders]
                }).catch(() => {
                    this.uploading = false
                })
        },
        openItemInNewTab(url:string) {
            window.open(url, '_blank')
        },
    },
})
