
import Vue from 'vue'
import * as htmlToImage from 'html-to-image'
import uniqId from 'uniqid'
import { StoryScreen } from 'lib-ad-platform-models'

import Api from '@/api/api'
import Library from '@/components/library/Library.vue'
import { Toaster } from '@/components/Toaster'
import { advertParser } from '@/utils/advertParser'
import StoryScreenEditor from '@/views/partials/story/StoryScreenEditor.vue'
import StoryRowIconEditor from '@/views/partials/story/StoryRowIconEditor.vue'

export default Vue.extend({
    name: 'StoryCreator',
    components: {
        Library,
        StoryScreenEditor,
        StoryRowIconEditor,
    },
    props: ['artefact', 'label'],
    data() {
        return {
            currentScreen: null,
            isFullWidth: false,
            previewScale: 1,
            params: null,
            previewLoading: false,
        }
    },
    computed: {
        filtering() {
            return 1
        },
    },
    watch: {
        artefact: {
            handler() {
                if (this.currentScreen) {
                    const { id } = this.currentScreen
                    this.currentScreen = this.artefact.story.screens.find((screen) => parseInt(screen.id) === parseInt(id)) || this.artefact.story.screens[0]
                } else if (this.artefact.story.screens.length) {
                    [this.currentScreen] = this.artefact.story.screens
                }
            },
            deep: true,
        },
    },
    mounted() {
        [this.currentScreen] = this.artefact.story.screens
        this.params = {
            label: this.label,
            id: this.artefact.id,
        }
    },
    methods: {
        toggleFullWidth() {
            this.isFullWidth = !this.isFullWidth
            this.previewScale = this.isFullWidth ? 0.9 : 1
        },
        navToScreen(index: number) {
            this.currentScreen = this.artefact.story.screens[index]
        },
        setPreviewScale(action: string) {
            if (action === '+' && this.previewScale <= 0.95) {
                this.previewScale += 0.05
            }

            if (action === '-' && this.previewScale >= 0.5) {
                this.previewScale -= 0.05
            }
        },
        gotoBatPage() {
            this.$router.push(`/advert/bat/${this.artefact.id}`)
        },
        async generateScreenshot() {
            Toaster.show('chargement', 'Création des snapshots en cours')
            this.previewLoading = true

            try {
                this.$emit('convertPreview')

                const formData = new FormData()
                formData.append('advertId', this.artefact.id)
                await Promise.all(Array.from(document.getElementsByClassName('bat-preview')).map(async (item: any, i) => {
                    const blob = await htmlToImage.toBlob(item)
                    const file = new File([blob], `name${i}.png`, { lastModified: new Date().getTime(), type: blob.type })

                    formData.append(`file${i}`, file)
                }))

                this.$emit('deletePreview')

                const result: any = await Api.upload('/bat/screenshot/upload', formData)

                result.webpath.forEach((img) => {
                    console.log(img)
                })

                Toaster.show('success', "Votre capture est disponible dans l'onglet BAT", 3000)
                this.previewLoading = false
            } catch (error) {
                Toaster.show('error', error, 3500)
                this.previewLoading = false
            }
        },
        addScreen() {
            if (this.label === 'advert') {
                this.$store.dispatch('advt/addStoryScreen', {
                    artefact: this.artefact,
                    screen: {
                        ...this.artefact.story.screens[0] ?? new StoryScreen(),
                        uuid: uniqId(),
                        id: null,
                        illustration: null,
                    },
                })
                    .then(() => {
                        this.$store.dispatch('advt/updateAdvert', { advert: this.artefact })
                    })
                    .then(() => {
                        this.$store.commit('advt/setAdvert', advertParser(this.artefact))
                    })
            } else if (this.label === 'article') {
                this.$store.dispatch('art/addArticleStoryScreen', {
                    artefact: this.artefact,
                    screen: {
                        ...this.artefact.story.screens[0] ?? new StoryScreen(),
                        uuid: uniqId(),
                        id: null,
                        illustration: null,
                    },
                })
            }
        },
        async deleteScreen(visibleScreen: number, screen: any) {
            try {
                this.navToScreen(visibleScreen === 0 ? 1 : visibleScreen - 1)
                if (this.label === 'advert') {
                    await this.$store.dispatch('advt/rmStoryScreen', { index: visibleScreen, advert: this.artefact, screen })

                    const { screens } = this.artefact.story

                    const remainingScreens = screens.filter(({ id }) => id !== screen.id)

                    const updatedAdvert = {
                        ...this.artefact,
                        story: {
                            ...this.artefact.story,
                            screens: remainingScreens,
                        },
                    }

                    await this.$store.dispatch('advt/updateAdvert', { advert: updatedAdvert })
                } else if (this.label === 'article') {
                    await this.$store.dispatch('art/deleteArticleStoryScreen', { article: this.artefact, screenId: screen.id.toString() })
                }

                Toaster.show('success', 'Ecran supprimé', 3500)
            } catch (error) {
                Toaster.show('error', error, 3500)
            }
        },
    },
})
