
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'

export default Vue.extend({
    name: 'ProjectListHead',
    components: {},
    props: ['mode'],
    computed: {
        ...mapState({
            projects: (state: State) => state.proj.projects,
            filters: (state: State) => state.proj.listFilters,
        }),
        sort() {
            return {
                orderBy: this.filters.orderBy,
                order: this.filters.order,
            }
        },
    },
    data() {
        return {
            sortFilters: {
                name: 'Projet',
                clientName: 'Client',
                id: 'Id',
            },
            options: {
                name: 'Projet',
                clientName: 'Client',
                id: 'Id',
            },
        }
    },
    methods: {
        toggleOption(label: string) {
            this.$store.commit('proj/setProjectFilter', {
                category: label,
                value: this.sort[label],
                property: 'name',
            })
            this.$emit('sort')
        },
    },
})
