
import Vue from 'vue'

export default Vue.extend({
    name: 'inputSelect',
    props: {
        options: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        defaultValue: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            optionSelected: this.defaultValue ?? '',
        }
    },
})
