
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import { normalDate } from '@/filters/simpday.filter'

export default Vue.extend({
    name: 'HeaderAlerts',
    filters: { date: normalDate },
    data() {
        return {}
    },
    mounted() {
        this.$store.dispatch('user/getAutopublishReport')
    },
    computed: {
        ...mapState({
            alerts: (state: State) => state.user.alerts,
        }),
    },
})
