
import Vue from 'vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { Target, Project, Comment } from 'lib-ad-platform-models'
import { uniqBy } from 'lodash'
import { debounce } from 'debounce'

dayjs.extend(isBetween)

interface dateObject {
  epoch: number,
  text: string
}

export default Vue.extend({
    name: 'Timeline',
    filters: {
        shortYear: (str: string) => {
            const whereDot = str.indexOf('/')
            const toRm = str.substring(whereDot, whereDot + 3)
            return str.replace(toRm, '/')
        },
    },
    props: {
        id: [String, Number],
    },
    data() {
        return {
            pos1: 0,
            pos2: 0,
            pos3: 0,
            pos4: 0,
            loading: true,
            monthes: [],
            projects: [],
            indexedProjects: [],
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        filteredTargets(targets: Array<Target>) {
            return uniqBy(targets, 'actionCode')
        },
        isBetween(date: dateObject, target: Target) {
            const curr = dayjs.unix(date.epoch)
            const start = dayjs.unix(target.startDate || target.endDate || null)
            const end = dayjs.unix(target.endDate || target.startDate + 2629743 || null) // .add(1, "month");
            return curr.isBetween(start, end)
        },
        isSameMonth(date: dateObject, target: Target) {
            const curr = dayjs.unix(date.epoch)
            const start = dayjs.unix(target.startDate || target.endDate || null)
            const end = dayjs.unix(target.endDate || target.startDate + 2629743 || null) // .add(1, "month");

            if (curr.isSame(start, 'month')) {
                return 1
            } if (curr.isSame(end, 'month')) {
                return 2
            }
            return 0
        },
        isFirstMonth(date: dateObject, target: Target) {
            const curr = dayjs.unix(date.epoch)
            const start = dayjs.unix(target.startDate || target.endDate || null)

            return curr.isSame(start, 'month')
        },
        generateTimeline(firstDate: number, lastDate: number) {
            // generate monthes from current date...
            const monthes = {}

            const startDate = dayjs.unix(firstDate).subtract(3, 'month')
            const endDate = dayjs.unix(lastDate).add(3, 'month')

            const diff = endDate.diff(startDate, 'month')

            // foreach month, do create a entry in list
            let monthNum = startDate.month() + 1
            let yearNum = startDate.year()

            //  let year1 =
            for (let i = 0; i <= diff; i++) {
                monthes[`${monthNum}/${yearNum}`] = {
                    text: `${monthNum}/${yearNum}`,
                    epoch:
            new Date(`${yearNum}-${monthNum}-15 00:00:00`).valueOf() / 1000,
                }

                if (monthNum === 12) {
                    monthNum = 0
                    yearNum += 1
                }

                monthNum += 1
            }

            // set active items with default active listFilters
            return monthes
        },
        getDeadlines(timeline: Array<Project>) {
            const allDates = timeline.reduce((acc, value) => {
                const test = value._targets.map(({ startDate, endDate }) => {
                    return [startDate, endDate]
                })
                return [...acc, ...test].flat()
            }, []).filter((item: number) => item !== null)

            return {
                min: Math.min(...allDates),
                max: Math.max(...allDates),
            }
        },
        refresh() {
            this.loading = true
            this.$store
                .dispatch('proj/loadTimeline', { ids: [this.id] })
                .then((timeline: Array<Project>) => {
                    const firstDatetime = this.getDeadlines(timeline).min
                    const lastDatetime = this.getDeadlines(timeline).max
                    this.monthes = this.generateTimeline(firstDatetime, lastDatetime)
                    this.projects = timeline
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        dragElement() {
            this.pos1 = 0
            this.pos2 = 0
            this.pos3 = 0
            this.pos4 = 0
        },
        close() {
            this.$emit('close')
        },
        dragMouseDown(e: DragEvent) {
            // e = e || window.event
            e.preventDefault()
            // get the mouse cursor position at startup:
            this.pos3 = e.clientX
            this.pos4 = e.clientY
            document.onmouseup = this.closeDragElement
            // call a function whenever the cursor moves:
            document.onmousemove = this.elementDrag
        },
        elementDrag(e: DragEvent) {
            // e = e || window.event
            e.preventDefault()
            // calculate the new cursor position:
            this.pos1 = this.pos3 - e.clientX
            this.pos2 = this.pos4 - e.clientY
            this.pos3 = e.clientX
            this.pos4 = e.clientY
            // set the element's new position:
            this.$refs.timelineRef.style.top = `${this.$refs.timelineRef.offsetTop - this.pos2}px`
            this.$refs.timelineRef.style.left = `${this.$refs.timelineRef.offsetLeft - this.pos1}px`
        },
        closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null
            document.onmousemove = null
        },
        updateProjectTargets(projectId: number, actionCode: string, comment: Comment) {
            this.projects
                .find(({ id }) => id === projectId)._targets
                .filter((target) => target.actionCode === actionCode)
                .forEach((element) => {
                    element._comment = comment
                })
        },
        keyup: debounce(async function keyup(e: any, actionCode: string, comment: Comment, projectId: number) {
            if (comment) {
                const result = await this.$store.dispatch(
                    'proj/updateTargetsComment',
                    { id: comment.id, content: e.target.innerText },
                )
                this.updateProjectTargets(projectId, actionCode, result)
            } else {
                const result = await this.$store.dispatch(
                    'proj/createTargetsComment',
                    { actionCode, content: e.target.innerText },
                )
                this.updateProjectTargets(projectId, actionCode, result)
            }
        }, 500),
        isCurrent(timestamp) {
            const now = `${dayjs().month() + 1}/${dayjs().year()}`
            return timestamp === now
        },
        getGuidelineWidth(target: Target, isSameMonth: number) {
            let timestamp

            if (isSameMonth === 1) {
                timestamp = target.startDate
            } else if (isSameMonth === 2) {
                timestamp = target.endDate || target.startDate + 2629743
            } else {
                return ''
            }

            const [day] = Intl.DateTimeFormat('fr')
                .format(timestamp * 1000)
                .split('/')

            const ratio = Math.round(((Number(day) - 1) * 100) / 30)

            const value = isSameMonth === 1
                ? 100 - ratio
                : ratio

            return `width: calc(${value}% + 1px)`
        },
        hideAdvertPreview() {
            this.$store.commit('advt/setAdvertPreview', null)
        },
        showAdvertPreview(target: Target) {
            if (target.advertId) {
                this.$store.dispatch('advt/loadAdvertForPreview', target.advertId)
            }
        },
    },
})
