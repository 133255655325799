
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import inputDeeplinkGen from '@/components/inputs/inputDeeplinkGen.vue'
import InputSelect from '@/components/inputs/InputSelect.vue'
import InputText from '@/components/inputs/InputText.vue'

export default Vue.extend({
    name: 'SourceFromSearch',
    components: {
        inputDeeplinkGen,
        InputSelect,
        InputText,
    },
    computed: {
        ...mapState({
            deeplink: (state: State) => state.deeplink.deeplink,
        }),
    },
    data() {
        return {
            tabs: {
                all: 'Tous (option par défault)',
                rcp: 'rcp',
                article: 'article',
                tool: 'tool',
                drive: 'drive',
                image: 'image',
            },
            deeplinkData: {
                selectedTab: 'all',
                termInputValue: '',
            },
            error: {
                isShow: false,
                message: '',
            },
        }
    },
    created() {
        const { firstParams, secondParams } = this.deeplink
        this.deeplinkData = {
            selectedTab: firstParams ?? 'all',
            termInputValue: secondParams ?? '',
        }
    },
    methods: {
        setTabSelected(event: string) {
            this.deeplinkData.selectedTab = event
        },
        setTermValue(event: string) {
            this.deeplinkData.termInputValue = event
        },
    },
})
