export const targetFields = {
    projectName: {
        label: 'Projet',
        item: null,
        format: 'string',
    },
    clientName: {
        label: 'Client',
        item: null,
        format: 'string',
    },
    advertId: {
        label: 'Publicité',
        item: 'target',
        format: 'number',
    },
    actionCode: {
        label: 'Code action',
        item: 'target',
        format: 'string',
    },
    status: {
        label: 'Statut',
        item: 'target',
        format: 'string',
    },
    type: {
        label: 'Type',
        item: 'target',
        format: 'string',
    },
    populationCategory: {
        label: 'Spé',
        item: 'target',
        format: 'string',
    },
    populationSample: {
        label: 'Ciblage',
        item: 'target',
        format: 'string',
    },
    populationSize: {
        label: 'Taille Cible',
        item: 'target',
        format: 'number',
    },
    startDate: {
        label: 'Date de début',
        item: 'target',
        format: 'date',
    },
    checkDate: {
        label: 'Date de feedbacks intermédiaires',
        item: 'target',
        format: 'date',
    },
    endDate: {
        label: 'Date de fin estimée',
        item: 'target',
        format: 'date',
    },
    goal: {
        label: 'Objectif',
        item: 'target',
        format: 'goal',
    },
    revenue: {
        label: 'CA HT',
        item: 'target',
        format: 'number',
    },
    quotationSignedMonth: {
        label: 'Date de Signature',
        item: 'target',
        format: 'date',
    },
    initialQuotationDate: {
        label: 'Date initiale du devis',
        item: 'target',
        format: 'date',
    },
    releaseDateDiff: {
        label: 'Ecart dates',
        item: 'target',
        format: 'delay',
    },
    delayReason: {
        label: 'Raison du retard',
        item: 'target',
        format: 'string',
    },
    revenueDelayImpact: {
        label: 'Impact CA',
        item: 'target',
        format: 'number',
    },
    actor: {
        label: 'Acteur',
        item: 'target',
        format: 'string',
    },
}
