import Api from '@/api/api'
import { Console } from '@/utils/Console'

export type LibraryState = {
    library: {
        id: string,
        content: string[]
    },
    draggedItem: string
}

const state: LibraryState = ({
    library: {
        id: null,
        content: [],
    },
    draggedItem: null,
})

const getters = {}

const mutations = {
    setLibrary(state: LibraryState, payload: any): void {
        state.library = {
            ...payload,
            content: payload.content.sort(),
        }
    },
    setLibraryDraggedItem(state: LibraryState, item: string): void {
        state.draggedItem = item
    },
}

const actions = {
    async getLibrary({ commit }: any, payload: any): Promise<void> {
        const path = `/library/${payload.label}/${payload.id}`
        Console.logAction(`Vuex store action: < getLibrary > as GET ${path}`, 'get')

        try {
            const result = await Api.get(path)
            commit('setLibrary', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async updateLibrary({ commit }: any, payload: any): Promise<void> {
        const path = `/library/${payload.label}/${payload.id}`
        Console.logAction(`Vuex store action: < updateLibrary > as PUT ${path}`, 'put')

        try {
            const result = await Api.put(path, { library: payload.library })
            commit('setLibrary', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteLibraryItem({ commit }: any, payload: any): Promise<void> {
        const path = `/library/${payload.id}/item`
        Console.logAction(`Vuex store action: < deleteLibraryItem > as DELETE ${path}`, 'delete')

        try {
            await Api.delete(path, { item: payload.item })
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
