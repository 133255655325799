
import Vue from 'vue'

export default Vue.extend({
    name: 'ArrayPicker',
    props: {
        options: { type: Array, required: true, default: () => [] },
        values: { type: Array, required: true, default: () => [] },
        disableIfIfTrue: { type: Object, required: false, default: () => { return { values: [], if: false } } },
    },
    data() {
        return {
            list: [],
            dropdownVisible: false,
        }
    },
    mounted() {
        this.list = this.values
        document.addEventListener('click', this.close)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close)
    },
    watch: {
        values(value) {
            this.list = [...value]
        },
    },
    methods: {
        onChange() {
            this.$emit('change', [...this.list])
        },
        close(e: any) {
            if (!this.$el.contains(e.target)) {
                this.dropdownVisible = false
            }
        },
        display(list: string[]) {
            return `${list.length} Roles`
        },
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible
        },
        toggle(option: string) {
            const list = [...this.list]
            const index = list.indexOf(option)

            if (index > -1) {
                list.splice(index, 1)
            } else {
                list.push(option)
            }

            this.list = [...list]
        },
        checked(list: string[], option: string) {
            return list.indexOf(option) > -1
        },
        disable(option: string) {
            return (this.disableIfIfTrue.if && this.disableIfIfTrue.values.includes(option))
        },
    },
})
