
import Vue from 'vue'
import { GridLayout, GridItem } from 'vue-grid-layout'
import VueHtml2pdf from 'vue-html2pdf'
import { orderBy } from 'lodash'

export default Vue.extend({
    name: 'Html2Pdf',
    props: ['bat'],
    components: {
        GridLayout,
        GridItem,
        VueHtml2pdf,
    },
    data() {
        return {
            pages: null,
            htmlToPdfOptions: {
                margin: 0,

                filename: 'bat.pdf',

                enableLinks: true,

                html2canvas: {
                    scale: 3,
                    useCORS: true,
                },

                jsPDF: {
                    unit: 'px',
                    format: [1000, 708],
                    orientation: 'landscape',
                },
            },
        }
    },
    mounted() {
        if (!this.bat) {
            return
        }

        this.pages = this.bat.pages
            .sort((a, b) => a.page - b.page)
            .map((page) => {
                return {
                    ...page,
                    layers: orderBy(page.layers, ['level'], ['asc']),
                }
            })
    },
    methods: {
        exportBat() {
            this.$refs.html2Pdf.generatePdf()
        },
    },

})
