
import Vue from 'vue'
import * as _ from 'lodash'
import { mapState } from 'vuex'
import { Relation } from 'lib-ad-platform-models'
import { State } from '@/store'
import { klass } from '@/utils/klass'
import { Toaster } from '../Toaster'

export default Vue.extend({
    name: 'GroupSearchBar',
    props: ['group'],
    data() {
        return {
            term: '',
            splitTerm: '',
            limit: 25,
            loading: false,
            labels: [
                null, 'Profession', 'Specialty', 'Feed', 'Role', 'Title', 'App', 'Document', 'Keyword', 'Advert', 'Area', 'Country', 'Zip', 'Zipcode', 'Uga', 'Workplace', 'User'],
            searchDropdownVisible: false,
            hiddenGroups: [],
            shiftPressed: false,
            nothingClickedYet: true,
            currentLabelFilter: null,
            documents: [],
            selectedDocuments: [],
        } as any
    },
    computed: {
        isLoading() { return this.loading },
        isShiftPressed() { return this.shiftPressed },
        ...mapState({
            searchResults: (state: State) => state.audn.searchResults,
        }),
    },
    methods: {
        focusOnSearch() {
            this.searchDropdownVisible = true
        },
        textBoldTerm(result: string) {
            let expression = this.term

            if (this.currentLabelFilter && this.term.includes(':')) {
                expression = this.term.split(':')[1].trim()
            }

            const regEx = new RegExp(expression, 'ig')
            const boldTerm = result.match(regEx)

            if (expression.length >= 3 && boldTerm) {
                return boldTerm.map((word) => {
                    return result.replace(word, `<b>${word}</b>`)
                }).join('')
            }

            return result
        },
        resetSearch() {
            if (this.term === '') {
                this.searchDropdownVisible = null
            } else {
                this.term = ''
                this.currentLabelFilter = null
                this.onSearch()
            }
        },
        back() {
            this.$router.go(-1)
        },
        filterByLabel(label: string) {
            let cleanTerm = ''
            const list = this.term.split(':')
            if (list.length > 1) {
                list.shift()
                cleanTerm = list.join('').trim()
            } else {
                cleanTerm = this.term.trim()
            }

            if (label === null) {
                this.currentLabelFilter = null
                this.term = cleanTerm
            } else {
                this.currentLabelFilter = label
                this.term = `${label}: ${cleanTerm}`
            }

            this.onSearch()
        },
        close() {
            this.searchDropdownVisible = false
        },
        onSearch: _.debounce(function (this: any) {
            this.loading = true
            this.$store.commit('audn/setSearchNodesResult', [])

            this.splitTerm = this.term.split(': ').length > 1
                ? this.term.split(': ')[1].trim()
                : ''

            if (![null, '', ':'].includes(this.term)) {
                this.$store.dispatch('audn/searchNodes', { term: this.term, limit: this.limit, isGroup: true, commitResult: true })
                    .then(() => {
                        this.loading = false
                    })
            } else {
                this.loading = false
                this.$store.commit('audn/setSearchNodesResult', [])
            }
        }, 320),
        addItemToGroup(item: any, e: any) {
            const { relationships } = this.group
            const alreadyInGroup = relationships ? relationships.map(({ id, label }) => id + label) : []

            const relation: Relation = new Relation({
                type: 'SHOULD_TARGET',
                kind: 'required',
                event: null,
                caping: null,
            })

            item._$justAdded = true
            this.nothingClickedYet = false
            klass(e.currentTarget, 'just-added')

            this.currentLabelFilter = null
            this.term = ''

            if (!this.isShiftPressed) {
                this.searchDropdownVisible = false
                this.$store.commit('audn/setSearchNodesResult', [])
            }

            if (!alreadyInGroup.includes(item.id + item.label)) {
                this.$store.dispatch('audn/addAudienceGroupRel', { source: this.group, target: item, relationship: relation })
            }
        },
        toggleDocumentsModal(show: boolean) {
            if (show) {
                this.$store.dispatch('audn/searchNodes', { term: this.term, limit: 100, isGroup: true, commitResult: false })
                    .then((result) => {
                        this.documents = result
                        this.selectedDocuments = result.map(({ id }) => id)
                        this.$modal.show('documents')
                    })
            } else {
                this.$modal.hide('documents')
            }
        },
        updateSelectedDocuments(id: number) {
            if (this.selectedDocuments.some((item) => item === id)) {
                const index = this.selectedDocuments.indexOf(id)
                this.selectedDocuments.splice(index, 1)
            } else {
                this.selectedDocuments.push(id)
            }
        },
        addDocuments() {
            this.$store.dispatch('audn/addGroupDocumentsRel', {
                groupId: this.group.id,
                documents: this.documents.filter(({ id }) => this.selectedDocuments.includes(id)),
            }).then(() => {
                this.toggleDocumentsModal(false)
                Toaster.show('info', `${this.selectedDocuments.length} ajoutés`)
                this.documents = []
                this.selectedDocuments = []
            })
        },
    },
    mounted() {
        document.addEventListener('click', this.close, false)

        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (this.shiftPressed && e.code === 'ShiftLeft' && !this.nothingClickedYet) {
                this.searchDropdownVisible = false
                this.$store.commit('audn/setSearchNodesResult', [])
                this.nothingClickedYet = true
            }

            this.shiftPressed = false
        }, false)

        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code === 'ShiftLeft') {
                this.shiftPressed = true
            }
        }, false)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close)
    },
})
