import { Advert, StoryScreen, AdvertListFilter } from 'lib-ad-platform-models'

import Api from '@/api/api'
import { Toaster } from '@/components/Toaster'
import { advertParser } from '@/utils/advertParser'
import { Console } from '@/utils/Console'

type AdvertSearchResult = {
    id: string,
    label: string,
    name: string,
    type: string
}

type AdvertRel = {
    id: string,
    label: string,
    name: string
}

type Layer = {
    id: string,
    level: number,
    content: any[]
}

type page = {
    id: string,
    page: string,
    layers: Layer[]
}

type ScreenShot = {
    id: string,
    imgPath: string
}

type Bat = {
    id: string,
    pages: page[],
    screenshots: ScreenShot[]
}

export type AdvertState = {
    advert: Advert,
    bat: Bat,
    advertRels: AdvertRel[],
    adverts: Advert[],
    advertSearchResults: AdvertSearchResult[],
    listFilters: AdvertListFilter,
    newAdvertTargetId: string,
    previewAdvert: Advert,
    funnel: any
}

const state: AdvertState = ({
    advert: null,
    bat: null,
    advertRels: [],
    adverts: [],
    advertSearchResults: [],
    listFilters: new AdvertListFilter({
        archived: '0',
        limit: 25,
        _model: 'latest',
        orderBy: 'updatedAt',
        order: 'DESC',
    }),
    newAdvertTargetId: null,
    previewAdvert: null,
    funnel: {},
})

const getters = {}

const mutations = {
    setFunnel(state: AdvertState, payload: any): void {
        state.funnel = payload
    },
    createNewAdvert(state: AdvertState, payload: any): void {
        state.advert = payload !== null
            ? new Advert(payload)
            : null
    },
    pushStoryScreen(state: AdvertState, payload: StoryScreen): void {
        state.advert.story.screens = [...state.advert.story.screens, ...[payload]]
    },
    spliceStoryScreen(state: AdvertState, payload: any): void {
        state.advert.story.screens.splice(payload.index, 1)
    },
    setSwitchStoryScreen(state: AdvertState, payload: any): void {
        const { screens } = state.advert.story
        const temp = screens[payload.i]
        screens[payload.i] = screens[payload.j]
        screens[payload.j] = temp
        state.advert.story.screens = [...screens]
    },
    setAdvertAudience(state: AdvertState, payload: any): void {
        const payloadIndex = state.advert._$audiences.findIndex(({ id }) => id === payload.id)

        if (payloadIndex === -1) {
            state.advert._$audiences.push(payload)
        } else {
            state.advert._$audiences.splice(payloadIndex, 1)
        }
    },
    unsetAdvert(state: AdvertState, id: string): void {
        let delIndex = null
        const list = state.adverts

        // also update adverts list
        for (const i in list) {
            if (list[i].id === id) {
                delIndex = i
            }
        }

        list.splice(delIndex, 1)
        state.adverts = [...list]
        state.advert = null
    },
    setBat(state: AdvertState, payload: any): void {
        state.bat = payload
    },
    setBatPages(state: AdvertState, payload: any): void {
        state.bat.pages = payload
    },
    pushUpdatedBatPage(state: AdvertState, payload: any): void {
        state.bat.pages.find(({ id }) => id === payload.id).layers = payload.layers
    },
    setBatScreenshots(state: AdvertState, payload: any): void {
        const index = state.bat.screenshots.map(({ id }) => id).indexOf(payload)
        state.bat.screenshots.splice(index, 1)
    },
    setAdvert(state: AdvertState, payload: any): void {
        if (payload === null) {
            state.advert = null
            return
        }

        state.advert = new Advert(payload)

        // also update adverts list
        for (const i in state.adverts) {
            if (state.adverts[i].id === state.advert.id) {
                state.adverts[i] = state.advert
            }
        }

        state.adverts.sort((x, y) => {
            if (x.id === state.advert.id) {
                return -1
            } if (y.id === state.advert.id) {
                return 1
            }
            return 0
        })
    },
    setAdvertPreview(state: AdvertState, payload: any): void {
        if (!payload) {
            state.previewAdvert = null
        } else if (payload._model === 'latest') {
            state.previewAdvert = payload
        }
    },
    setAdvertProps(state: AdvertState, payload: any): void {
        if (state.advert === null) {
            return
        }
        const { advert } = state

        for (const prop in payload) {
            advert[prop] = payload[prop]
        }

        state.advert = new Advert(advert)
    },
    setAdverts(state: AdvertState, payload: any): void {
        state.adverts = [...payload].map((c) => new Advert(c))
    },
    pushInAdverts(state: AdvertState, payload: any): void {
        state.adverts = [
            ...[new Advert(payload)],
            ...state.adverts.filter(({ id }) => id !== payload.id),
        ]
    },
    setMoreAdverts(state: AdvertState, payload: any): void {
        for (const i in payload) {
            state.adverts.push(new Advert(payload[i]))
        }
    },
    setAdvertRels(state: AdvertState, payload: any): void {
        state.advertRels = [...payload]
    },
    setSearchAdvertsResult(state: AdvertState, payload: any): void {
        state.advertSearchResults = [...payload]
    },
    setListFilter(state: AdvertState, payload: any): void {
        state.listFilters = { ...state.listFilters, ...payload }
    },
    updateAdvertStoryScreenIllustration(state: any, payload: any): void {
        const { screenId, illustration } = payload

        state.advert.story.screens.map((screen) => {
            if (screen.id === screenId) {
                screen.illustration = illustration
            }
            return screen
        })
    },
}

const actions = {
    async fetchFunnel({ commit }: any, advertId: string): Promise<void> {
        const path = `/advert/${advertId}/funnel`
        Console.logAction(`Vuex store action: < fetchFunnel > as GET ${path}`, 'get')
        commit('setFunnel', null)

        try {
            const result = await Api.get(path)
            commit('setFunnel', result)
        } catch (error) {
            console.log(error)
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async createFunnel({ commit }: any, advertId: string): Promise<void> {
        const path = `/advert/${advertId}/funnel/create`
        Console.logAction(`Vuex store action: < createFunnel > as POST ${path}`, 'post')
        commit('setFunnel', null)

        try {
            const result = await Api.post(path)
            commit('setFunnel', result)
        } catch (error) {
            console.log(error)
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteFunnel({ commit }: any, payload: { advertId: string, funnelUuid: string }): Promise<void> {
        const path = `/advert/${payload.advertId}/funnel`
        Console.logAction(`Vuex store action: < deleteFunnel > as DELETE ${path}`, 'delete')

        try {
            await Api.delete(path, { funnelUuid: payload.funnelUuid })
            commit('setFunnel', null)
        } catch (error) {
            const message = error.response.data.error
            commit('err/fetchFailed', { message }, { root: true })
        }
    },
    async appendFunnel({ commit }: any, payload: { advertId: string, funnelUuid: string, newAdvertId: string }): Promise<void> {
        const path = `/advert/${payload.advertId}/funnel/append`
        Console.logAction(`Vuex store action: < appendFunnel > as POST ${path}`, 'post')

        try {
            const result = await Api.post(path, payload)
            commit('setFunnel', result)
        } catch (error) {
            const message = error.response.data.error
            commit('err/fetchFailed', { message }, { root: true })
        }
    },
    async updateFunnelMinViews({ commit }: any, payload: { startAdvertId: string, nextAdvertId, funnelUuid: string, minViews: number }): Promise<void> {
      const path = `/advert/${payload.startAdvertId}/funnel/update/minviews`
      Console.logAction(`Vuex store action: < updateFunnel > as POST ${path}`, 'post')

      try {
          const result = await Api.post(path, payload)
          commit('setFunnel', result)
      } catch (error) {
          const message = error.response.data.error
          commit('err/fetchFailed', { message }, { root: true })
      }
  },
    async loadBat({ commit }: any, advertId: string): Promise<void> {
        const path = `/bat/fetch/${advertId}`
        Console.logAction(`Vuex store action: < loadBat > as GET ${path}`, 'get')

        commit('setBat', null)

        try {
            const result = await Api.get(path)
            commit('setBat', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async saveBatLayers({ commit }: any, payload: any): Promise<void> {
        const path = `/bat/save/${payload.advertId}`
        Console.logAction(`Vuex store action: < saveBatLayout > as POST ${path}`, 'post')

        try {
            await Api.post(path, payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteBatScreenshot({ commit }: any, payload: any): Promise<void> {
        const path = `/bat/screenshot/${payload.advertId}/${payload.screenshotId}`
        Console.logAction(`Vuex store action: < deleteBatScreenshot > as DELETE ${path}`, 'delete')

        try {
            await Api.delete(path)
            commit('setBatScreenshots', payload.screenshotId)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async addBatNewPage({ commit }: any, payload: any): Promise<void> {
        const path = `/bat/newpage/${payload.advertId}`
        Console.logAction(`Vuex store action: < addBatNewPage > as POST ${path}`, 'post')

        try {
            const result = await Api.post(path, payload)
            commit('setBatPages', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteBatPage({ commit }: any, payload: any): Promise<void> {
        const path = `/bat/page/${payload.advertId}`
        Console.logAction(`Vuex store action: < deleteBatPage > as DELETE ${path}`, 'delete')

        try {
            const result = await Api.delete(path, payload)
            commit('setBatPages', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async addBatNewLayer({ commit }: any, payload: any): Promise<void> {
        const path = `/bat/newlayer/${payload.advertId}`
        Console.logAction(`Vuex store action: < addBatNewLayer > as POST ${path}`, 'post')

        try {
            const result = await Api.post(path, payload)
            commit('pushUpdatedBatPage', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteBatLayer({ commit }: any, payload: any): Promise<void> {
        const path = `/bat/layer/${payload.advertId}`
        Console.logAction(`Vuex store action: < deleteBatLayer > as DELETE ${path}`, 'post')

        try {
            const result = await Api.delete(path, payload)
            commit('pushUpdatedBatPage', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async addStoryScreen({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const { artefact } = payload

        const path = `/stories/${artefact.id}/screen`
        Console.logAction(`Vuex store action: < addStoryScreen > as POST ${path}`, 'post')

        try {
            const result = await Api.post(path, payload)
            commit('pushStoryScreen', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async rmStoryScreen({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = `/stories/${payload.advert.id}/screen/${payload.screen.id}`
        Console.logAction(`Vuex store action: < rmStoryScreen > as DELETE ${path}`, 'delete')

        try {
            await Api.delete(path, payload)
            commit('spliceStoryScreen', payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async resetStoryExpiration({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = `/stories/${payload.advertId}/reset`
        Console.logAction(`Vuex store action: < resetStoryExpiration > as PUT ${path}`, 'put')

        try {
            const result = await Api.put(path)
            Toaster.show('success', result, 4500)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async deleteAdvert({ commit, rootGetters }: any, id: number): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = `/advert/${id}`
        Console.logAction(`Vuex store action: < deleteAdvert > as DELETE ${path}`, 'delete')

        try {
            await Api.delete(path, id)
            commit('setAdvertRels', [])
            commit('unsetAdvert', id)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async createAdvert({ commit, dispatch, rootGetters }: any, payload: any): Promise<string> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = '/advert/create'
        Console.logAction(`Vuex store action: < createAdvert > as POST ${path}`, 'post')

        const advert = { ...new Advert(payload), published: false }

        const body = {
            advert,
            targetId: window.localStorage.getItem('targetId') || null,
        }

        window.localStorage.removeItem('targetId')

        try {
            const result = await Api.post(path, body)
            commit('setAdvertRels', [])
            commit('setAdvert', result)
            commit('pushInAdverts', result)
            if (result.type === 'story') {
                dispatch('lib/getLibrary', { label: 'advert', id: result.id }, { root: true })
            }
            return result.id
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return null
        }
    },
    async duplicateAdvert({ commit, dispatch, rootGetters }: any, payload: any): Promise<string> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const { id } = payload

        const path = `/advert/${id}/duplicate`
        Console.logAction(`Vuex store action: < duplicateAdvert > as POST ${path}`, 'post')

        const advert = { ...new Advert(payload), published: false }

        try {
            const result = await Api.post(path, { advert })
            commit('pushInAdverts', result)
            if (result.type === 'story') {
                dispatch('lib/getLibrary', { label: 'advert', id: result.id }, { root: true })
            }
            return result.id
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return null
        }
    },
    async loadAdvert({ commit, dispatch }: any, id: string): Promise<void> {
        const path = `/v2/advert/${id}`
        Console.logAction(`Vuex store action: < loadAdvert > as GET ${path}`, 'get')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.get(path)
            commit('setAdvertRels', result._$audiences)
            commit('setAdvert', result)
            if (result.type === 'story') {
                dispatch('lib/getLibrary', { label: 'advert', id: result.id }, { root: true })
            }
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            commit('setAdvertRels', [])
            commit('setAdvert', null)
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async loadAdvertForPreview({ commit }: any, id: number): Promise<void> {
        const path = `/v2/advert/${id}`
        Console.logAction(`Vuex store action: < loadAdvertForPreview > as GET ${path}`, 'get')

        try {
            const result = await Api.get(path)
            commit('setAdvertPreview', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async loadAdverts({ commit }: any, payload: any): Promise<void> {
        const path = '/adverts'
        Console.logAction(`Vuex store action: < loadAdverts > as POST ${path}`, 'post')

        if (payload.skip === 0) {
            commit('xhr/isPending', true, { root: true })
        }

        try {
            const result = await Api.post(path, payload)
            if (payload.skip > 0) {
                commit('setMoreAdverts', result)
            } else {
                commit('setAdverts', result)
            }
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async updateAdvert({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = `/v2/advert/${payload.advert.id}`
        Console.logAction(`Vuex store action: < updateAdvert > as PUT ${path}`, 'put')

        commit('xhr/isPending', true, { root: true })

        try {
            await Api.put(path, advertParser(payload.advert))
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async updateAdvertProp({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = `/v2/advert/${payload.id}/${payload.prop}`
        Console.logAction(`Vuex store action: < updateAdvertProp > as PUT ${path}`, 'put')

        try {
            await Api.put(path, payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async searchAdverts({ commit }: any, payload: any): Promise<AdvertSearchResult[]> {
        const path = '/search/Advert'
        Console.logAction(`Vuex store action: < searchAdverts > as GET ${path}`, 'get')

        commit('xhr/isPending', true, { root: true })

        try {
            const result = await Api.get(path, { term: payload.term })
            commit('setSearchAdvertsResult', result)
            return result
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async attachAdvertToAudience({ commit, rootGetters }: any, payload: any): Promise<void> {
        if (rootGetters['user/hasAnyRole'](['SALES']) && !rootGetters['user/hasAnyRole'](['ADMIN'])) {
            Toaster.show('error', 'User not allowed', 3000)
            throw new Error('User not allowed')
        }

        const path = '/advert/attach'
        Console.logAction(`Vuex store action: < attachAdvertToAudience > as POST ${path}`, 'post')

        commit('xhr/isPending', true, { root: true })
        try {
            await Api.post(path, payload)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        } finally {
            commit('xhr/isPending', false, { root: true })
        }
    },
    async publishAdvert({ commit }: any, payload: any): Promise<void> {
        const path = `/v2/advert/${payload.advert.id}/publish`
        Console.logAction(`Vuex store action: < publishAdvert > as PUT ${path}`, 'put')

        try {
            const result = await Api.put(path, payload)
            commit('setAdvertProps', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
    async migrateAdvertToArticle({ commit }: any, payload: any): Promise<string> {
        const path = `/advert/${payload.id}/migrate`
        Console.logAction(`Vuex store action: < migrateAdvertToArticle > as PUT ${path}`, 'put')

        try {
            const id = await Api.put(path)
            return id
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
            return error
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
