
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import HeaderAlerts from '@/components/HeaderAlerts.vue'

export default Vue.extend({
    components: { HeaderAlerts },
    data() {
        return {
            env: process.env.VUE_APP_ENV,
            appCreatorUrl: process.env.VUE_APP_TOOLS_CREATOR_URL,
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters['user/isAuthenticated']
        },
        ...mapState({
            roles: (state: State) => state.user.roles,
        }),
    },
    watch: {
        $route(to, from) {
            this.previousPath = from.fullPath
            this.currentPath = to.fullPath
        },
    },
    methods: {
        hasAnyRole(roles: string[]) {
            // user can have only ONE of the roles specified, but needs at least one
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        hasAllRoles(roles: string[]) {
            // user need to have ALL the roles specified to match (see user store getter)
            return this.$store.getters['user/hasAllRoles'](roles)
        },
        resetView() {
            if (this.previousPath === this.currentPath) {
                this.$router.go()
            } else {
                this.previousPath = this.currentPath
            }
        },
        showLogoutModal() {
            this.$modal.show('logout')
        },
        logout() {
            this.$store.commit('user/clearUserData')
        },
        isActive(paths: string[]) {
            return paths.some((path) => this.$route.fullPath.includes(path))
        },
    },
})
