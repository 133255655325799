
import Vue from 'vue'
import { mapState } from 'vuex'
import { dayjs } from '@/utils/dayjs'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import { populationCategoriesMapToId } from '@/utils/projectFilters'
import { Inventory, InventoryItem } from '@/models/Inventory'
import InventoryDisponibilityCheckModal from '@/components/modals/InventoryDisponibilityCheckModal.vue'
import InventoryTable from './partials/dashboardInventory/InventoryTable.vue'
import InventoryHeader from './partials/dashboardInventory/InventoryHeader.vue'

export default Vue.extend({
    name: 'InventoryDashboard',
    components: {
        InventoryTable,
        InventoryDisponibilityCheckModal,
        InventoryHeader,
    },

    data() {
        const filters = {
            from: dayjs().format('YY/MM'),
            to: dayjs().add(5, 'month').format('YY/MM'),
            specialty: populationCategoriesMapToId.filter(({ label }) => label === 'Specialty').map(({ id }) => id),
            profession: populationCategoriesMapToId.filter(({ label }) => label === 'Profession').map(({ id }) => id),
            viewType: 'month',
        }
        return {
            isMonthView: true,
            isTableEmpty: false,
            filters,
            inventoryRowDetails: {} as Partial<InventoryItem>,
            isLoading: false,
            monthViewInformartions: {
                startFormatedDate: '',
                endFormatedDate: '',
                headerTableRows: [],
                year: null,
                inventory: [] as InventoryItem[],
                firstMonth: '',
                lastMonth: '',

            },
            quarterViewInformartions: {
                headerTableRows: ['Janvier - Mars', 'Avril - Juin', 'Juillet - Septembre', 'Octobre - Décembre'],
                year: null,
                inventory: [] as InventoryItem[],
            },

        }
    },

    mounted() {
        this.getMonthInventory()
    },

    computed: {
        ...mapState({
            monthInventory: (state: State) => state.proj.monthInventory,
            quarterInventory: (state: State) => state.proj.quarterInventory,
            specialityFilters: (state: State) => state.proj.inventoryFilter,
            userId: (state: State) => state.user.userId,
        }),
    },

    watch: {

        specialityFilters: {
            handler(newValue) {
                if (newValue.populationCategory.length === 0) {
                    this.resetFilters()
                } else {
                    this.filters.specialty = newValue.populationCategory.filter(({ label }) => label === 'Specialty').map(({ itemId }) => itemId)
                    this.filters.profession = newValue.populationCategory.filter(({ label }) => label === 'Profession').map(({ itemId }) => itemId)
                }

                this.getMonthInventory()
                this.getQuarterInventory()
            },
        },
        isMonthView(value: boolean) {
            if (value) {
                this.setFilters({
                    from: dayjs().format('YY/MM'),
                    to: dayjs().add(5, 'month').format('YY/MM'),
                    viewType: 'month',
                })
                this.getMonthInventory()
            } else {
                this.setFilters({
                    from: dayjs().startOf('year').format('YY/MM'),
                    to: dayjs().endOf('year').format('YY/MM'),
                    viewType: 'quarter',
                })
                this.getQuarterInventory()
            }
        },
        monthInventory: {
            handler(response) {
                if (response.length !== 0) {
                    this.monthDataProcessing(response)
                    this.isTableEmpty = false
                } else {
                    Toaster.show('warning', 'Le fichier est vide', 3000)
                    this.isTableEmpty = true
                }
            },
        },
        quarterInventory: {
            handler(response) {
                this.quarterDataProcessing(response)
            },
        },

    },
    methods: {
        getMonthInventory() {
            this.isLoading = true
            this.setFilters({
                viewType: 'month',
            })
            this.$store
                .dispatch('proj/getMonthInventory', this.filters).then((response) => {
                    this.monthDataProcessing(response)
                    this.isLoading = false
                })
                .catch((error) => {
                    this.isLoading = false
                    Toaster.show('error', error.response.data.errorMsg, 3000)
                })
        },
        getQuarterInventory() {
            this.isLoading = true
            this.setFilters({
                viewType: 'quarter',
            })
            this.$store
                .dispatch('proj/getQuarterInventory', this.filters).then((response) => {
                    this.quarterDataProcessing(response)
                    this.isLoading = false
                })
                .catch((error) => {
                    this.isLoading = false
                    Toaster.show('error', error.response.data.errorMsg, 3000)
                })
        },

        setFilters(filters: { from?: string, to?: string, specialty?: string[], profession?: string[] }) {
            this.filters = {
                ...this.filters,
                ...filters,
            }
        },

        resetFilters() {
            this.filters = {
                ...this.filters,
                specialty: populationCategoriesMapToId.filter(({ label }) => label === 'Specialty').map(({ id }) => id),
                profession: populationCategoriesMapToId.filter(({ label }) => label === 'Profession').map(({ id }) => id),
                viewType: 'month',
            }
        },

        monthDataProcessing(response: any) {
            const startFormatedDate = response[0].inventory[0].date
            const endFormatedDate = response[0].inventory[5].date
            const year = 20 + response[0].inventory[0].date.split('/')[0]

            const montharray = []

            const momentStartFormatedDate = dayjs(startFormatedDate, 'YY/MM', 'fr')
            const momentEndFormatedDate = dayjs(endFormatedDate, 'YY/MM')

            if (!momentStartFormatedDate.isValid() || !momentEndFormatedDate.isValid()) {
              throw new Error('Invalid dates')
            }

            const monthDiff = momentStartFormatedDate.diff(momentEndFormatedDate, 'month')
            for (let i = 0; montharray.length <= monthDiff; i++) {
              momentStartFormatedDate.add(i, 'month')
              montharray.push(momentStartFormatedDate.format('MMMM'))
            }

            this.monthViewInformartions = {
                ...this.monthViewInformartions,
                startFormatedDate,
                endFormatedDate,
                year,
                headerTableRows: montharray,
                inventory: response,
                firstMonth: montharray[0],
                lastMonth: montharray[5],
            }
        },
        quarterDataProcessing(response: Inventory[]) {
            const year = response[0].inventory[0].date.slice(-4)
            this.quarterViewInformartions = {
                ...this.quarterViewInformartions,
                year,
                inventory: response,
            }
        },

        tableNavigation(isNext: boolean) {
            if (this.isMonthView && isNext) {
                this.setFilters({
                    viewType: 'month',
                    from: dayjs(this.monthViewInformartions.endFormatedDate, 'YY/MM').add(1, 'month').format('YY/MM'),
                    to: dayjs(this.monthViewInformartions.endFormatedDate, 'YY/MM').add(6, 'month').format('YY/MM'),
                })
                this.getMonthInventory()
            }
            if (!this.isMonthView && isNext) {
                this.setFilters({
                    viewType: 'quarter',
                    from: dayjs(this.quarterViewInformartions.year, 'YYYY').add(1, 'year').format('YY/MM'),
                    to: dayjs(this.quarterViewInformartions.year, 'YYYY').add(23, 'month').format('YY/MM'),
                })
                this.getQuarterInventory()
            }
            if (this.isMonthView && !isNext) {
                this.setFilters({
                    viewType: 'month',
                    from: dayjs(this.monthViewInformartions.startFormatedDate, 'YY/MM').subtract(6, 'month').format('YY/MM'),
                    to: dayjs(this.monthViewInformartions.startFormatedDate, 'YY/MM').subtract(1, 'month').format('YY/MM'),
                })
                this.getMonthInventory()
            } if (!this.isMonthView && !isNext) {
                this.setFilters({
                    viewType: 'quarter',
                    from: dayjs(this.quarterViewInformartions.year, 'YYYY').subtract(12, 'month').format('YY/MM'),
                    to: dayjs(this.quarterViewInformartions.year, 'YYYY').subtract(1, 'month').format('YY/MM'),
                })
                this.getQuarterInventory()
            }
        },

        toggleView(event: boolean) {
            this.isMonthView = event
        },
        openModal() {
            this.$refs.InventoryDisponibilityCheckModal.openModal()
        },

    },

})
