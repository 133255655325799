
import Vue from 'vue'
import { PROFESSIONS_REF, arrayRef } from '@/models/refs'

export default Vue.extend({
    name: 'ProfessionSelector',
    props: ['values'],
    data() {
        return {
            selected: [] as string[],
            options: arrayRef(PROFESSIONS_REF),
        }
    },
    mounted() {
        this.selected = this.values
    },
    methods: {
        change() {
            this.$emit('onChange', this.selected as string[])
        },
    },
})
