
import Vue from 'vue'
import { mapState } from 'vuex'
import InputSelect from '@/components/inputs/InputSelect.vue'
import InputText from '@/components/inputs/InputText.vue'
import inputDeeplinkGen from '@/components/inputs/inputDeeplinkGen.vue'
import SearchAppModal from '@/components/modals/SearchAppModal.vue'
import { isValidUrl } from '@/utils/RegexpCheckUrl'
import { State } from '@/store'

export default Vue.extend({
    name: '',
    components: {
        InputSelect,
        inputDeeplinkGen,
        InputText,
        SearchAppModal,
    },
    computed: {
        ...mapState({
            deeplink: (state: State) => state.deeplink.deeplink,
        }),
    },
    created() {
        const { secondParams, firstParams } = this.deeplink
        this.deeplinkData = {
            selectedOption: secondParams ?? 'INTERNAL_WEBVIEW',
            appUrl: firstParams || '',
        }
    },
    data() {
        return {
            options: {
                INTERNAL_WEBVIEW: 'INTERNAL_WEBVIEW (option par défaut)',
                INTERNAL_BROWSER: 'INTERNAL_BROWSER',
                EXTERNAL_BROWSER: 'EXTERNAL_BROWSER',
            },
            deeplinkData: {
                selectedOption: 'INTERNAL_WEBVIEW',
                appUrl: '',
            },
            isEncode: true,
            urlNotValid: false,
            error: {
                isShow: false,
                message: "L'url saise est invalide",
            },
        }
    },
    methods: {
        setOptionSelected(event: string) {
            this.deeplinkData.selectedOption = event
        },
        encodeDeeplink(e) {
            this.isEncode = e.target.checked
        },
        setUrl(event: string) {
            this.deeplinkData.appUrl = event
            this.error.isShow = !isValidUrl(event) && event.length
            this.$emit('isNotEmpty', isValidUrl(event))
        },
        toggleSearchAppModal(open: boolean) {
            return open
                ? this.$refs.SearchAppModal.openModal()
                : this.$refs.SearchAppModal.closeModal()
        },
    },
})
