export const advertReqFields = {
    post: [
        'logo',
        'images',
        'author',
        'source',
        'title',
    ],
    video: [
        'logo',
        'author',
        'video',
        'source',
        'title',
    ],
    quiz: [
        'logo',
        'author',
        'footNote',
    ],
    story: [
        'logo',
    ],
    notification: [
        'title',
        'source',
    ],
    search: [
        'title',
        'source',
    ],
}

export const articleReqFields = {
    post: [
        'logo',
        'author',
        'source',
        'title',
    ],
    video: [
        'logo',
        'author',
        'video',
        'source',
    ],
    quiz: [
        'logo',
        'author',
        'source',
        'footNote',
    ],
    story: [
        'logo',
    ],
    notification: [
        'title',
        'source',
    ],
}
