
import Vue from 'vue'
import { capitalize } from '@/filters/capitalize.filter'

export default Vue.extend({
    name: 'ListSearchLabelsRecap',
    filters: { capitalize },
    props: ['filters'],
    data() {
        return {

        }
    },
    methods: {
        hasFilter(key: string, value?: string|number|Array<string>) {
            const filterExists = this.filters[key] != null && this.filters[key] !== ''

            if (value) {
                if (typeof value === 'string' || typeof value === 'number' || value === null) {
                    return (filterExists && this.filters[key] === value)
                } if (value.constructor === Array) {
                    return (filterExists && value.indexOf(this.filters[key]) > -1)
                }

                return filterExists
            }

            return filterExists
        },
        rmFilter(key: string) {
            this.$emit('filterRemoved', { key, value: this.filters[key] })
        },
    },
})
