import { Deeplink } from '@/models/deeplink'

export type DeeplinkState = {
    deeplink: Deeplink
}

const state: DeeplinkState = {
    deeplink: {
        url: '',
    },
}

const getters = {
    getDeeplink(state) {
        return state.deeplink
    },
}

const mutations = {
    setDeeplink(state: DeeplinkState, value: Deeplink): void {
        state.deeplink = value
    },
    resetDeeplink(state: DeeplinkState) {
        state.deeplink = {
            url: '',
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    state,
}
