
import Vue from 'vue'
import Api from '@/api/api'

export default Vue.extend({
    name: 'AdminPage',
    data() {
        return {
            csvFiles: [],
            csvData: [],
            csvHeaders: [],
            skip: 0,
            max: 25,
            availableFields360: [],
        }
    },
    mounted() {
        this.loadFilesList()
    },
    methods: {
        onLoadCsv(e: any) {
            this.csvFilename = e.target.value
            this.loadCsvData(this.csvFilename)
        },
        loadFilesList() {
            Api.get('/csv/list')
                .then((r: any) => {
                    this.csvFiles = r.files
                }).catch((e) => {
                    console.log(e)
                })
        },
        loadCsvData(filename: string) {
            Api.get('/csv/data', { filename, max: this.max, skip: this.skip })
                .then((r: any) => {
                    this.csvData = r.data
                    this.csvHeaders = r.headers
                    this.availableFields360 = r.availableFields360
                }).catch((e) => {
                    console.log(e)
                })
        },
        go() {
            const mapping = []

            for (const i in this.$refs.choosenFieldsSelects) {
                let val = this.$refs.choosenFieldsSelects[i].value
                val = (val === '') ? null : val

                const obj = {}
                obj[`${this.csvHeaders[i]}`] = val
                mapping.push(obj)
            }

            Api.post('/csv/import', { filename: this.csvFilename, mapping })
                .then(() => {
                    // done
                }).catch(() => {
                    // @fix log error
                })
        },
    },
})
