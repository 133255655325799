
import Vue from 'vue'
import uniqid from 'uniqid'
import { Advert, Article } from 'lib-ad-platform-models'
import { Toaster } from '@/components/Toaster'
import { AttachmentUpload } from '@/components/AttachmentUpload'
import { truncate, showReadMore } from '@/filters/truncate.filter'
import { stringIsBase64 } from '@/utils/base64'
import { mapMutations, mapState } from 'vuex'
import { State } from '@/store'

export default Vue.extend({
    name: 'PostPreview',
    filters: { truncate, showReadMore },
    props: {
        artefact: { type: Object, default: null },
        editable: { type: Boolean, default: false },
        showVideoControls: { type: Boolean, default: false },
        videoDatas: { type: Object, default: null },
        label: { type: String, default: 'advert' },
    },
    data() {
        return {
            mediaExtension: {
                post: 'image/*',
                video: 'video/mp4',
            },
            isShowReadMoreClicked: false,
            activeImageIndex: 0,
        }
    },
    computed: {
        ...mapState({
            initValues: (state: State) => state.previewHistory.initValues,
        }),
        shouldDisplayRemoveButton() {
            return this.initValues.isActive
        },
    },
    methods:
    {
        ...mapMutations({
            deactivatePreview: 'previewHistory/deactivatePreview',
        }),
        openImagesEditorModal() {
            this.$emit('openImagesEditorModal')
        },
        showFullText(isActive: boolean) {
            this.isShowReadMoreClicked = !!isActive
        },
        ensureLogoImg(artefact: Advert | Article) {
            if (!artefact.logo) {
                return '/images/default_logo_image.jpg'
            }
            // In this case, when dealing with a base64 file, we remove the uniquid since it's only a temporary identifier.
            // If the user decides to save the article, the base64 data will be uploaded and transformed into a URL with the uniquid.
            const logoParam = stringIsBase64(artefact.logo) ? '' : `?d=${uniqid()}`
            return `${artefact.logo}${logoParam}`
        },
        ensureCoverImg(artefact: Advert | Article) {
            const defImg = '/images/default_cover_image.png'
            const url = artefact.images[this.activeImageIndex] || artefact.image || defImg
            // In this case, when dealing with a base64 file, we remove the uniquid since it's only a temporary identifier.
            // If the user decides to save the article, the base64 data will be uploaded and transformed into a URL with the uniquid.
            const coverParam = stringIsBase64(url) ? '' : `?d=${uniqid()}`
            return `${url}${coverParam}`
        },
        ensureVideoSrc(artefact: Advert | Article) {
            const defVid = (artefact.type === 'video') ? '/images/default_cover_video.png' : '/images/default_cover_video.png'
            return `${(artefact.video !== 'undefined' && artefact.video !== null) ? artefact.video : defVid}?d=${uniqid()}`
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()
            if (!this.editable) {
                return
            }

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onLogoChanged() {
            if (this.$refs.logoInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')
            const handler = new AttachmentUpload(this.$refs.logoInput.files[0], this.artefact.id)

            handler.performUpload('logo')
                .then((r) => {
                    Toaster.show('success', 'Logo enregistré', 1300)
                    this.artefact.logo = r.webpath
                    this.$refs.logoImg.src = `${r.webpath}?d=${uniqid()}`
                }).catch((e) => {
                    Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                })
        },
        onCoverMediaChanged() {
            if (this.$refs.coverInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')
            const destination = (this.artefact.type === 'video') ? 'video' : 'cover'

            const handler = new AttachmentUpload(this.$refs.coverInput.files[0], this.artefact.id)

            handler.performUpload(destination)
                .then((r) => {
                    Toaster.show('success', 'Image ou video enregistrée', 1300)

                    if (this.artefact.type === 'video') {
                        this.artefact.video = r.webpath
                    } else {
                        this.artefact.image = r.webpath
                    }

                    this.$refs.coverMedia.src = r.webpath

                    if (this.artefact.type === 'video') {
                        const videoPlayer = document.getElementById('video-src') as HTMLMediaElement
                        videoPlayer.load()
                    }
                }).catch((e) => {
                    Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                })
        },
        removePreviewedImages() {
            this.artefact.title = this.initValues.title
            this.artefact.description = this.initValues.description ?? ''
            this.artefact.author = this.initValues.author ?? ''
            this.artefact.tagline = this.initValues.tagline ?? ''
            this.artefact.logo = this.initValues.logo
            this.artefact.image = this.initValues.image
            this.artefact.images = this.initValues.images
            this.deactivatePreview()
            Toaster.show('success', 'Les images ont bien été retirées', 3000)
        },
    },
})
