
import Vue from 'vue'
import * as papa from 'papaparse'
import { mapState } from 'vuex'
import { Node, Relation } from 'lib-ad-platform-models'
import { State } from '@/store'
import Api from '@/api/api'
import { Toaster } from '@/components/Toaster'
import { HTMLFileInputEvent } from '@/models/HTMLEvent'
import { formatThousands } from '@/filters/formatThousands.filter'
import AudienceSearchBar from './AudienceSearchBar.vue'
import RelationshipGroupList from './RelationshipGroupList.vue'
import AudienceUserTestTool from './AudienceUserTestTool.vue'

export default Vue.extend({
    name: 'AudienceEditor',
    components: { AudienceSearchBar, RelationshipGroupList, AudienceUserTestTool },
    props: ['id'],
    filters: { formatThousands },
    data() {
        return {
            groupEdited: null,
            size: 0,
            sizeLoading: false,
            keyComboDetected: false,
            audienceSize: null,
            area: null,
        }
    },
    computed: mapState({
        audience: (state: State) => state.audn.audience,
        audienceRoles: (state: State) => {
            if (state.audn.audience === null) { return [] }
            return state.audn.audience.relationships
                .filter((rel: { id: string, label: string }) => rel.label === 'Role')
                .map((role: { id: string, label: string }) => role.id)
        },
    }),
    watch: {
        audience(newValue, oldValue) {
            if (!this.area || !oldValue || oldValue.id !== newValue.id) {
                this.area = this.audience.area
            }
        },
        area(newValue, oldValue) {
            if (newValue && this.id === 'new') {
                this.audience.area = newValue
            }
            if (this.id !== 'new' && newValue && (oldValue || !this.audience.area)) {
                this.updateArea(oldValue, newValue)
            }
        },
    },
    methods: {
        rolesContainsExpert(roles: string[]) {
            if (roles === undefined) {
                return false
            }
            return !!roles.includes('ROLE_EXPERT')
        },
        hasAnyRole(roles: string[]) {
            // user can have only ONE of the roles specified, but needs at least one
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        async getAudienceSize() {
            this.sizeLoading = true
            try {
                this.audienceSize = await this.$store.dispatch('audn/getAudienceSize', { audienceId: this.audience.id, type: this.audience.type })
                this.sizeLoading = false
            } catch (error) {
                Toaster.show('warning', "erreur lors du calcul de la taille d'audience", 3000)
                this.sizeLoading = false
            }
        },
        onCsvAdded(e: HTMLFileInputEvent) {
            if (e.target.files.length !== 1) {
                console.log('File empty, or too many of them! I need only one')
                return
            }

            const { files } = e.target

            papa.parse(files[0], {
                complete: (result) => {
                    const emails = result.data
                    Api.put(`/audience/${this.audience.id}/emails/attach`, { emails: result.data })

                    for (const i in emails) {
                        this.$store.commit('audn/pushAudienceRel', { target: { id: emails[i], name: emails[i], label: 'User' } })
                    }
                },
                error: ((err) => {
                    console.log('ERROR', err)
                }),
            })
        },
        onKeyUp() {
            this.keyA = null
        },
        onKeyDown(e: KeyboardEvent) {
            if (this.keyA === null) {
                this.keyA = e.keyCode
            }

            if (this.keyA === 17 && e.keyCode === 90) {
                this.keyComboDetected = true
            }
        },
        async saveInfo() {
            if (!(Object.keys(this.audience.groupedRelationships).filter((el) => el !== 'Area').length && this.area)) {
                Toaster.show('warning', "Associez un critère pour pouvoir sauvegarder l'audience", 2000)
                return
            }

            Toaster.show('info', 'Saving...')

            this.$store.dispatch('audn/updateAudience', this.audience).then(() => { Toaster.show('success', 'Audience saved.', 1300) })
            this.searchDropdownVisible = false
        },
        async updateArea(oldValue: string, newValue: string) {
            Toaster.show('info', 'Saving...')
            const oldItem = {
                id: oldValue,
                label: 'Area',
                name: oldValue,
                _$justAdded: true,
            }

            const newItem = {
                id: newValue,
                label: 'Area',
                name: newValue,
                _$justAdded: true,
            }

            const payload = {
                source: new Node(this.audience),
                target: new Node(oldItem),
            }

            const relation: Relation = new Relation({
                type: 'AND',
                kind: null,
                event: null,
                caping: null,
            })

            await this.$store.dispatch('audn/rmAudienceRel', payload)
            await this.$store
                .dispatch('audn/addAudienceRel', {
                    source: this.audience,
                    target: newItem,
                    relationship: relation,
                }).then(() => { Toaster.show('success', 'Area saved.', 1300) })
        },
        groupBeeingEdited(e: any) {
            this.groupEdited = e.group
        },
        someUpdate() {
            setTimeout(() => {
                this.$refs.liveTestTool.refresh()
            }, 900)
        },
        openAdvert(id: string) {
            const path = this.$router.resolve({ path: `/adverts/edit/${id}` })
            window.open(path.href, '_blank')
        },
    },
    async mounted() {
        // Update
        if (this.id !== 'new') {
            try {
                await this.$store.dispatch('audn/loadAudience', this.id)
            } catch (error) {
                Toaster.show('warning', "Erreur lors du chargement de l'audience", 3000)
            }
        }

        // Create
        if (this.id === 'new') {
            this.area = 'french'
        }

        if (this.id === 'new' && !this.audience) {
            this.$router.push('/audiences')
            Toaster.show('warning', 'Audience non sauvegardée', 2000)
        }
    },
})
