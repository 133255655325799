// all references must be objects: with ID as key, and value as value
export const AREAS_REF = {
    french: 'french',
    english: 'english',
    spain: 'spain',
}

export const TITLES_REF = {
    DOCTOR: 'DOCTOR',
    STUDENT: 'STUDENT',
    PROFESSIONAL: 'PROFESSIONAL',
}

export const PROFESSIONS_REF = {
    2: 'Médecin',
    8: 'Autre profession de santé',
    4: 'Pharmacien',
    3: 'Infirmier/Infirmière',
    6: 'Sage Femme',
    7: 'Patient',
    9: 'Kinésithérapeute',
    5: 'Dentiste',
    10: 'Préparateur en pharmacie',
    12: 'Pédicure - Podologue',
    11: 'Secrétaire médicale',
}

export const SPECIALTIES_REF = {
    26: 'Médecine générale',
    10: 'Oncologie médicale',
    31: 'Oncologie radiothérapie',
    45: "Médecine d'urgence",
    30: 'Onco-hématologie',
    23: 'Rhumatologie',
    42: 'Gériatrie',
    12: 'Dermatologie et vénérologie',
    4: 'Chirurgie générale',
    46: 'Autre',
    32: 'Gynécologie-obstétrique',
    27: 'Pédiatrie',
    28: 'Psychiatrie',
    13: 'Hépato-gastro-entérologie',
    21: 'Pneumologie',
    37: 'Chirurgie plastique reconstructrice et esthétique',
    16: 'Médecine nucléaire',
    3: 'Oto-rhino-laryngologie et chirurgie cervico-faciale',
    39: 'Urologie',
    29: 'Santé publique et médecine sociale',
    5: 'Neurochirurgie',
    22: 'Radio-diagnostic et imagerie médicale',
    41: 'Chirurgie viscérale et digestive',
    1: 'Anesthésie-réanimation',
    8: 'Anatomie et cytologie pathologique',
    43: 'Hématologie',
    14: 'Génétique médicale',
    11: 'Cardiologie et maladies vasculaires',
    15: 'Médecine interne',
    24: 'Biologie médicale',
    36: 'Chirurgie orthopédique et traumatologique',
    17: 'Médecine physique et de réadaptation',
    18: 'Néphrologie',
    34: 'Chirurgie maxillo-faciale',
    6: 'Chirurgie orale',
    2: 'Ophtalmologie',
    47: 'Médecine vasculaire',
    25: 'Médecine et Santé au travail',
    40: 'Chirurgie vasculaire',
    19: 'Neurologie',
    9: 'Endocrinologie-diabétologie-nutrition',
    7: 'Gynécologie médicale',
    44: 'Maladies infectieuses et tropicales',
    38: 'Chirurgie thoracique et cardio-vasculaire',
    33: 'Chirurgie pédiatrique',
    35: 'Chirurgie de la face et du cou',
    50: 'Médecine intensive-réanimation',
    20: 'Pathologies cardio-vasculaires',
    51: 'Médecine légale et expertises médicales',
    48: 'Allergologie',
    53: 'Sport and exercise medicine',
}

export const OS_REFS = {
    ios: 'iOS',
    android: 'Android',
}

export function arrayRef(refObject: any): string[] {
    return Object.values(refObject)
}
