
import Vue from 'vue'
import { mapState } from 'vuex'
import inputDeeplinkGen from '@/components/inputs/inputDeeplinkGen.vue'
import InputSelect from '@/components/inputs/InputSelect.vue'
import InputText from '@/components/inputs/InputText.vue'
import { isValidUrl } from '@/utils/RegexpCheckUrl'
import { State } from '@/store'

export default Vue.extend({
    name: 'SourceFormAppParts',
    components: {
        inputDeeplinkGen,
        InputSelect,
        InputText,

    },
    computed: {
        ...mapState({
            deeplink: (state: State) => state.deeplink.deeplink,
        }),
    },
    created() {
        const { firstParams, secondParams } = this.deeplink
        this.deeplinkData = {
            selectedPartValue: firstParams ?? 'drive',
            urlInputValue: secondParams ?? '',
        }
    },
    data() {
        return {
            parts: {
                drive: 'Ouvrir le drive / Onglet mes fichiers',
                timeline: 'Ouvrir le fil de contenu',
                notifications: 'Ourvir le centre de notifs',
                applications: "Ouvrir l'onglet Tool / Catalogue d'app",
                medics: 'Ouvrir la vue Medics / Onglet Médicatment',
                tasks: 'Ouvrir les tâches',
                universe: "Ouvrir les pages de l'utilisateur",
                'db-download': 'Ouvrir la vue pour télécharger la BDD en offline',
                expert: 'Ouvrir la vue : Expert',
                'user-profile': 'Ouvrir la vue : Profil utilisateur',
                viewer: 'Ouvrir une page web précise (pas un tool)',
            },
            deeplinkData: {
                selectedPartValue: 'drive',
                urlInputValue: '',
            },
            error: {
                isShow: false,
                message: "L'url saisie est invalide",
            },
        }
    },
    methods: {
        setValue(event: string, part: string) {
            const validUrlConditions = part === 'viewer' && isValidUrl(event)
            this.error.isShow = !validUrlConditions && event.length
            this.$emit('isNotEmpty', validUrlConditions)
            this.deeplinkData.urlInputValue = event
        },
        setPartSelected(event: string) {
            this.deeplinkData.selectedPartValue = event
            if (event !== 'viewer') {
                this.deeplinkData.urlInputValue = ''
            }
            // all parts are valid except 'viewer' which must have a valid url input
            this.$emit('isNotEmpty', event !== 'viewer')
        },
    },
})
