
import Vue from 'vue'

import Library from '@/components/library/Library.vue'

export default Vue.extend({
    name: 'GlobalModal',
    props: {
        component: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: false,
        },
    },
    components: {
        Library,
    },
})
