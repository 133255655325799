
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import { replaceWord } from '@/filters/replaceWord.filter'
import { capitalize } from '@/filters/capitalize.filter'

export default Vue.extend({
    name: 'CellSelectPicker',
    filters: { replaceWord, capitalize },
    props: {
        options: {
            type: Array,
            default() {
                return []
            },
        },
        ukLabelClass: String,
        center: { type: Boolean, default: false },
        value: String,
        searchable: { type: Boolean, default: () => false },
        col: String,
    },
    computed: {
        ...mapState({
            clipboard: (state: State) => state.proj.clipboard,
        }),
    },
    data() {
        return {
            term: '',
            justChanged: false,
            ctrlDown: false,
            hover: false,
        }
    },
    mounted() {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Control' || e.key === 'Meta') {
                this.ctrlDown = true
            }
        })

        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key === 'Control' || e.key === 'Meta') {
                this.ctrlDown = false
            }
        })

        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'c' && this.ctrlDown && this.hover) {
                Toaster.show('success', 'Copy to clipboard!', 1000)
                this.$store.commit('proj/copyToClipboard', {
                    category: this.col,
                    value: this.value,
                })
            }

            if (e.key === 'v' && this.ctrlDown && this.hover && this.clipboard) {
                const { category, value } = this.clipboard

                if (category === this.col) {
                    this.$emit('change', value)
                }
            }
        })
    },
    methods: {
        change(value: string) {
            this.justChanged = true
            setTimeout(() => {
                this.justChanged = false
            }, 320)

            this.$emit('change', value)
        },
        getOptions(): Array<string> {
            if (this.term.length) {
                return this.options.filter((option: string) => {
                    return option.toLowerCase().includes(this.term.toLowerCase())
                })
            }
            return this.options
        },
    },
})
