
import { debounce } from 'lodash'
import Vue from 'vue'
import Api from '@/api/api'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'AlphonseAccessManager',
    props: ['userAccounts', 'projectId'],
    data() {
        return {
            term: null,
            searchResult: [],
            selectedUserAccounts: [],
        }
    },
    watch: {
        term(value) {
            if (value && value.length >= 3) {
                this.searchUserAccount(value)
            } else {
                this.searchResult = []
            }
        },
    },
    methods: {
        openAddUserAccountModal() {
            this.$modal.show('add-user-account')
        },
        searchUserAccount: debounce(async function searchUserAccount(value) {
            try {
                this.searchResult = await Api.post('/alphonse/account/search', { term: value, projectId: this.projectId })
            } catch (error) {
                Toaster.show('error', error, 3500)
            }
        }, 200),
        toggleOption(userId) {
            if (this.selectedUserAccounts.includes(userId)) {
                const index = this.selectedUserAccounts.indexOf(userId)
                this.selectedUserAccounts.splice(index, 1)
            } else {
                this.selectedUserAccounts.push(userId)
            }
        },
        addUserAccounts() {
            this.$modal.hide('add-user-account')

            this.$store.dispatch('alph/addAlphonseUserAccounts', {
                userAccounts: this.selectedUserAccounts,
                projectId: this.projectId,
            }).then(() => {
                this.term = null
            })
        },
        deleteUserAccount(accountId) {
            this.$store.dispatch('alph/removeAlphonseUserAccount', { accountId, projectId: this.projectId })
        },
    },
})
