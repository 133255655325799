var render, staticRenderFns
import script from "./AlphonseChart.vue?vue&type=script&lang=js&"
export * from "./AlphonseChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_lodash@4.17.21_prettier@3.0.1_vue-template-compiler@2.7.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports