import { render, staticRenderFns } from "./GroupEditor.vue?vue&type=template&id=5ced48e6&scoped=true&"
import script from "./GroupEditor.vue?vue&type=script&lang=ts&"
export * from "./GroupEditor.vue?vue&type=script&lang=ts&"
import style0 from "./GroupEditor.vue?vue&type=style&index=0&id=5ced48e6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_lodash@4.17.21_prettier@3.0.1_vue-template-compiler@2.7.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ced48e6",
  null
  
)

export default component.exports