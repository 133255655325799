
import Vue from 'vue'
import Papa from 'papaparse'
import { mapState } from 'vuex'
import { Toaster } from '@/components/Toaster'
import ConfirmModal from '@/components/ConfirmModal.vue'

export default Vue.extend({
    name: 'AlphonseListPage',
    components: { ConfirmModal },
    data() {
        return {
            alphonseProject: null,
            projectToDelete: null,
            formData: null,
            specialtyId: '26',
        }
    },
    computed: mapState({
        loading: (state: any) => state.xhr.loading,
        projects: (state: any) => state.alph.projectsList,
        specialties: (state: any) => state.meta.specialties,
    }),
    created() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.$store.dispatch('alph/loadAlphonseProjects')
        },
        openCreateModal() {
            this.$modal.show('newProject')
        },
        closeCreateModal() {
            this.$modal.hide('newProject')
        },
        openDeleteModal(id: string, name: string) {
            this.projectToDelete = { id, name }
            this.$modal.show('delete')
        },
        async getCsvFile(e) {
            if (!e.target.files.length) {
                return
            }

            const file = e.target.files[0]

            const missingKeys = await this.checkCSVKeys(file)

            if (missingKeys.length) {
                Toaster.show('error', `Missing columns : ${missingKeys.join(' | ')}`, 4000)
                return
            }

            this.formData = new FormData()
            this.formData.append('file', e.target.files[0])
        },
        async checkCSVKeys(file) {
            const reader = new FileReader()
            reader.readAsText(file)

            const result = await new Promise((resolve) => {
                reader.onload = function () {
                    resolve(reader.result)
                }
            })

            const keys = ['NAME', 'SURNAME', 'GENDER', 'CRIT_01']

            const tempResult = Papa.parse(result, { header: true }).data

            return keys.filter((key) => !(key in tempResult[0]))
        },
        async deleteProject() {
            const { id, name } = this.projectToDelete
            try {
                const result = await this.$store.dispatch('alph/deleteAlphonseProject', { id, name })
                Toaster.show('success', result, 2000)
                this.groupToDelete = { name: null, id: null }
            } catch (error) {
                Toaster.show('error', error, 3500)
            }
        },
        async createLabo() {
            this.formData.append('projectName', this.alphonseProject)
            this.formData.append('specialtyId', this.specialtyId)
            try {
                this.$modal.hide('newProject')
                await this.$store.dispatch('alph/createAlphonseProject', this.formData)
            } catch (error) {
                Toaster.show('error', error, 3500)
            }
        },
    },
})
