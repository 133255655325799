// @todo Export this from other JSON if it becomse to big
type TranslationMap = {
    [key: string]: string
}

const TRANSLATIONS: TranslationMap = {
    projectName: 'Projet',
    advertId: 'Ad',
    actionCode: 'Code',
    status: 'Statut',
    type: 'Type',
    populationCategory: 'Spé',
    populationSample: 'Ciblage',
    populationSize: 'Taille cible',
    startDate: 'Début',
    endDate: 'Fin estimée',
    goal: 'Objectif',
    checkDate: 'Deadlines sortie KPIs',
}

export function trans(str: string | number): string|number {
    if (typeof TRANSLATIONS[`${str}`] === 'undefined') {
        return str
    }
    return TRANSLATIONS[`${str}`]
}

export function transMonth(num: number): string {
    const months = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin',
        'Jui', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc']

    return months[num - 1]
}

export function transMonthInverse(str: string): number {
    const months = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin',
        'Jui', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc']

    const index = months.indexOf(str)

    return index + 1
}
