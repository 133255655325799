const all = [
    'Profession',
    'Specialty',
    'Role',
    'Title',
    'App',
    'Document',
    'Keyword',
    'Advert',
    'Country',
    'Zip',
    'Zipcode',
    'Feed',
    'OrGroup',
    'Uga',
    'Workplace',
    'User',
]

const story = [
    'Profession',
    'Specialty',
    'Role',
    'Country',
    'User',
    'Title',
]

export const audiencesCategories = {
    timeline: all,
    search: all,
    story: all,
    notification: all,
    banner: all,
    tools: story,
}
