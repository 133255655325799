
import Vue from 'vue'
import { getFormatedDate } from '@/utils/getFormatedDate'
import EventBus from '@/components/EventBus'
import { CdnItem } from '@/models/CdnItemType'
import FolderIcon from './FolderIcon.vue'

export default Vue.extend({
    name: 'CdnObjectItem',
    components: { FolderIcon },
    props: ['item', 'selected'],
    methods: {
        getFormatedDate,
        getFolderPath(path:string) {
            const temporaryPath = path.split('assets/clients/')[1].split('/')
            const pathLength = temporaryPath.length

            return temporaryPath[pathLength - 2]
        },
        isCollapsed() {
            if (this.collapsed === null) {
                return false
            }

            return this.collapsed
        },
        isSelected(item: CdnItem) {
            // this.item
            if (this.selected === null) {
                return false
            }

            if (typeof this.selected.Prefix !== 'undefined') {
                if (item.Prefix === this.selected.Prefix) {
                    return true
                }
            }
            if (typeof this.selected.Key !== 'undefined') {
                if (item.Key === this.selected.Key) {
                    return true
                }
            }

            return false
        },
        click(item: CdnItem) {
            if (this.isSelected(item) && item.Prefix) {
                EventBus.$emit('folderUnselected', item)
                return
            }

            if (item.Prefix) {
                EventBus.$emit('folderClicked', item)
            }
            if (item.Key) {
                EventBus.$emit('fileClicked', item)
            }
        },
        rmDir(path: string) {
            return path.split('\\').pop().split('/').pop()
        },
        ext(path: string) {
            return path.split('.').pop()
        },
    },
})
