import { Console } from '@/utils/Console'

export type ErrorState = {
    error: string
}

const state = () => ({
    error: null,
})

const getters = {}

const mutations = {
    fetchFailed: (state: ErrorState, payload: any): void => {
        state.error = payload.message
        Console.red(`Vuex store action failed: ${state.error}`)
    },
}

const actions = {}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
