import Api from '../api/api'

const validExtension = ['png', 'jpg', 'jpeg', 'gif', 'mp4', 'svg', 'ico', 'icon', 'x-icon', 'svg+xml', 'vnd.microsoft.icon']
function isFileValid(file: File): boolean {
    const ext = file.name.split('.').pop()
    return (validExtension.indexOf(ext) !== -1)
}

export class AttachmentUpload {
    $formData: any = {}
    $valid = false

    constructor(file: File, advertId: number|string) {
        if (!isFileValid(file)) {
            alert(`Format non autorisé: ${validExtension.join(', ')}, seulement.`)
            return
        }

        this.$valid = true
        this.$formData = new FormData()
        this.$formData.append('file', file)
        this.$formData.append('advertId', `${advertId}`)
    }

    performUpload(dest: 'logo'|'cover'|'video'|'small'|'media'): Promise<any> {
        if (this.$valid) {
            return Api.upload(`/f/advert/attachment/${dest}/upload`, this.$formData)
        }
        return Promise.reject(new Error('Invalid file type'))
    }
}

export class StoryLogoImageUpload {
    $formData: any = {}
    $valid = false

    constructor(file: File, advertId: number|string) {
        if (!isFileValid(file)) {
            alert('Format non autorisé: png, jpg, jpeg, gif, mp4 seulement.')
            return
        }

        this.$valid = true
        this.$formData = new FormData()
        this.$formData.append('file', file)
        this.$formData.append('advertId', `${advertId}`)
    }

    performUpload(): Promise<any> {
        if (this.$valid) {
            return Api.upload('/f/advert/attachment/story/logo/upload', this.$formData)
        }
        return Promise.reject(new Error('Invalid file type'))
    }
}

export class ScreenLogoUpload {
    $formData: any = {}
    $valid = false

    constructor(file: File, advertId: number|string, screenId: number|string) {
        if (!isFileValid(file)) {
            alert('Format non autorisé: png, jpg, jpeg, gif, mp4 seulement.')
            return
        }

        this.$valid = true
        this.$formData = new FormData()
        this.$formData.append('file', file)
        this.$formData.append('advertId', `${advertId}`)
        this.$formData.append('screenId', `${screenId}`)
    }

    performUpload(): Promise<any> {
        if (this.$valid) {
            return Api.upload('/f/advert/attachment/story/screen/logo/upload', this.$formData)
        }
        return Promise.reject(new Error('Invalid file type'))
    }
}

export class ScreenImageUpload {
    $valid = false
    $formData: any = {}

    constructor(file: File, advertId: number|string, screenId: number|string) {
        if (!isFileValid(file)) {
            alert('Format non autorisé: png, jpg, jpeg, gif, mp4 seulement.')
            return
        }

        this.$valid = true
        this.$formData = new FormData()
        this.$formData.append('file', file)
        this.$formData.append('advertId', `${advertId}`)
        this.$formData.append('screenId', `${screenId}`)
    }

    performUpload(): Promise<any> {
        if (this.$valid) {
            return Api.upload('/f/advert/attachment/story/screen/upload', this.$formData)
        }
        return Promise.reject(new Error('Invalid file type'))
    }
}

export class LibraryMediaUpload {
    $valid = false
    $formData: any = {}

    constructor(file: File, libraryId: string) {
        if (!isFileValid(file)) {
            alert('Format non autorisé: png, jpg, jpeg, gif, mp4 seulement.')
            return
        }

        this.$valid = true
        this.$formData = new FormData()
        this.$formData.append('file', file)
        this.$formData.append('libraryId', `${libraryId}`)
    }

    performUpload(): Promise<any> {
        if (this.$valid) {
            return Api.upload('/library/attachment/media/upload', this.$formData)
        }
        return Promise.reject(new Error('Invalid file type'))
    }
}
