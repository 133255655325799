
import Vue from 'vue'

export default Vue.extend({
    name: 'ConfirmButton',
    props: {
        size: {
            type: String,
            default: 'medium',
            required: false,
        },
        color: {
            type: String,
            default: 'primary',
            required: false,
        },
        label: {
            type: String,
            default: 'Label',
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            showConfirmControls: false,
            bgColors: {
                default: '#F8F9FA',
                primary: '#1E87F0',
                secondary: '#222222',
                success: '#1B8754',
                warning: '#FFC009',
                danger: '#F0506E',
            },
            textColors: {
                default: '#222',
                primary: '#FFFFFF',
                secondary: '#FFFFFF',
                success: '#FFFFFF',
                warning: '#FFFFFF',
                danger: '#FFFFFF',
            },
            fontSize: {
                small: 10,
                medium: 12,
                large: 16,
            },
            height: {
                small: 24,
                medium: 28,
                large: 36,
            },
            width: {
                small: 100,
                medium: 120,
                large: 150,
            },
        }
    },
    computed: {
        style() {
            return `
                backgroundColor: ${this.bgColors[this.color] || '#FFFFFF'};
                border: 2px solid ${this.bgColors[this.color] || '#FFFFFF'};
                color: ${this.textColors[this.color] || '#FFFFFF'};
                height: ${this.height[this.size] || 28}px;
                min-width: ${this.width[this.size] || 60}px;
                fontSize: ${this.fontSize[this.size] || 12}px;
            `
        },
    },
    watch: {
        showConfirmControls(val) {
            if (val) {
                setTimeout(() => {
                    this.showConfirmControls = false
                }, 5000)
            }
        },
    },
    methods: {
        toggleConfirmControls() {
            if (this.disabled) {
                return
            }

            this.showConfirmControls = !this.showConfirmControls
        },
        confirm() {
            this.$emit('input')
            this.showConfirmControls = false
        },
    },
})
