var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"project-list-filters"},[_c('SelectFilter',{attrs:{"node":"Project","category":"name","label":"Projet","filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('SelectFilter',{attrs:{"node":"Project","category":"clientName","label":"Client","filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('SelectFilter',{attrs:{"node":"Project","category":"keyAccount","label":"CSM","defaultOptions":_vm.csm.map(({id, name}) => ({ id, name })),"filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('SelectFilter',{attrs:{"node":"Target","category":"targetStatus","label":"Status","defaultOptions":_vm.targetStatus.map((item, i) => {
                return { id: i, name: item };
            }),"filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('SelectFilter',{attrs:{"node":"Target","category":"type","label":"Type","defaultOptions":_vm.types
                .filter(item => item)
                .sort()
                .sort((a) => {
                    if (a === 'post') {
                        return -1
                    }
                    return 1
                })
                .map((item, i) => {
                    return { id: i, name: item };
                }),"filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('SelectFilter',{attrs:{"node":"Target","category":"populationCategory","label":"Spé","defaultOptions":_vm.populationCategories
                .filter((item) => item !== 'Médecin')
                .sort()
                .sort((a) => {
                    if (a === 'Médecine générale') {
                        return -1
                    }
                    return 1
                })
                .map((item, i) => {
                    return { id: i, name: item };
                }),"filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('SelectFilter',{attrs:{"node":"Target","category":"populationSample","label":"Ciblage","filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('DateFilter',{attrs:{"category":"startDate","label":"Date de début","filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('DateFilter',{attrs:{"category":"checkDate","label":"Deadline sortie KPIs","filterOnProperty":"name"},on:{"change":_vm.updateFilters}}),_c('DateFilter',{attrs:{"category":"endDate","label":"Date de fin","filterOnProperty":"name"},on:{"change":_vm.updateFilters}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }