
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import { truncate } from '@/filters/truncate.filter'
import { replaceWord } from '@/filters/replaceWord.filter'
import { capitalize } from '@/filters/capitalize.filter'

export type SelectFilterSelection = {
    category: string
    property: string
    value: Array<string|number>
}

export default Vue.extend({
    name: 'SelectFilter',
    filters: { truncate, replaceWord, capitalize },
    props: {
        isInventoryView: {
            type: Boolean,
            default: false,
            required: false,
        },
        label: { type: String, default: null },
        category: null,
        defaultOptions: {
            type: Array,
            default() {
                return []
            },
        },
        sort: {},
        filterOnProperty: { type: String, default: null },
        node: { type: String, default: null },
    },
    data() {
        return {
            term: '',
            selectedIds: [],
        }
    },
    watch: {
        listFilters() {
            if (!this.listFilters[this.category] || !this.listFilters[this.category].length) {
                this.selectedIds = []
            } else {
                this.selectedIds = this.defaultOptions
                    .filter(({ name }) => this.listFilters[this.category].includes(name))
                    .map(({ id }) => id)
            }
        },
    },
    computed: {
        ...mapState({
            listFilters: (state: State) => state.proj.listFilters,
        }),
        dropdownRef() {
            return `toggle-${this.category}`
        },
        options() {
            return this.defaultOptions
        },

    },
    mounted() {
        // set active items with default active listFilters
        if (this.listFilters[this.category] && this.listFilters[this.category].length) {
            this.selectedIds = this.defaultOptions
                .filter(({ name }) => this.listFilters[this.category].includes(name))
                .map(({ id }) => id)
        }
    },
    methods: {
        selectAll() {
            this.selectedIds = this.options.map(({ id }) => id)

            this.$emit('change', {
                category: this.category,
                value: this.options.map((item) => {
                    return this.isInventoryView
                        ? { name: item[this.filterOnProperty], label: item.label, itemId: item.itemId }
                        : item[this.filterOnProperty]
                }),
            })
        },
        clearAll() {
            this.selectedIds = []

            this.$emit('change', {
                category: this.category,
                value: [],
                property: this.filterOnProperty,
            })
        },
        toggleOption(id: any) {
            if (!this.isSelected(id)) {
                this.selectedIds.push(id)
            } else {
                const index = this.selectedIds.indexOf(id)
                if (index !== -1) {
                    this.selectedIds.splice(index, 1)
                }
            }

            const selection = this.options
                .filter(({ id }) => this.selectedIds.includes(id))
                .map((item) => {
                    return this.isInventoryView
                        ? { name: item[this.filterOnProperty], label: item.label, itemId: item.itemId }
                        : item[this.filterOnProperty]
                })

            this.$emit('change', {
                category: this.category,
                value: selection,
                property: this.filterOnProperty,
            })
        },
        isSelected(id: any) {
            return this.selectedIds.includes(id)
        },
        searchList() {
            if (!this.term.length) {
                this.options = this.defaultOptions
            } else {
                this.options = this.defaultOptions
                    .filter(({ name }) => name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(this.term.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
            }

            // const filterList = this.options.filter(({ name }) => {
            //     return name.toLowerCase().includes(this.term.toLowerCase())
            // })

            // if (!this.term.length) {
            //     // return (this.options = []);
            //     this.term = ''
            // }

            // this.loading = true

            // const catRel = {
            //     targetStatus: 'status',
            // }

            // this.$store
            //     .dispatch('proj/getFilterList', {
            //         label: this.node || this.label,
            //         field: this.node ? catRel[this.category] || this.category : null,
            //         term: this.term,
            //         limit: 10,
            //         skip: 0,
            //     })
            //     .then((list: any) => {
            //         this.loading = false
            //         this.options = this.node
            //             ? [
            //                 ...list.map((item, i) => {
            //                     return { id: i, name: item }
            //                 }),
            //             ]
            //             : [...list]
            //     })
            //     .catch(() => {
            //         this.loading = false
            //     })
        },
    },
})
