
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'

export default Vue.extend({
    name: 'ArticleEditPart7',
    data() {
        return {

        }
    },
    computed: mapState({
        article: (state: State) => state.art.article,
    }),
    methods: {
        open(link: string) {
            if (!link) {
                return
            }
            window.open(link, '_blank')
        },
    },
})
