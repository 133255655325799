
import Vue from 'vue'
import { mapState } from 'vuex'
import uniqid from 'uniqid'

import { ScreenLogoUpload } from '@/components/AttachmentUpload'
import EventBus from '@/components/EventBus'
import { Toaster } from '@/components/Toaster'
import { getFileTypeFromUrl } from '@/utils/getFileTypeFromUrl'
import { klass, unklass } from '@/utils/klass'
import { State } from '@/store'

export default Vue.extend({
    name: 'StoryScreenEditor',
    props: {
        artefact: {
            required: true,
        },
        label: {
            required: true,
        },
        screen: {
            required: true,
        },
        showControls: {
            type: Boolean,
            default: true,
        },
        isUniqScreen: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isLoading: true,
            inputHeight: 0,
            isVideoPlaying: false,
        }
    },
    created() {
        this.$parent.$on('convertPreview', this.convertVideoPreviewToImage)
        this.$parent.$on('deletePreview', this.deleteImgPreview)
    },
    mounted() {
        if (this.screen) {
            this.setInputHeight()
        }
    },
    computed: {
        ...mapState({
            draggedItem: (state: State) => state.lib.draggedItem,
        }),
        visibleScreen() {
            const index = this.artefact.story.screens.findIndex(({ id }) => {
                return id === this.screen?.id
            })
            return index
        },
        inputStyle() {
            const index = this.artefact.story.screens.findIndex(({ id }) => {
                return id === this.screen.id
            })
            return this.artefact.story.screens[index].footerButtonText ? this.inputHeight : 54
        },
        mediaFormat() {
            return this.screen.illustration
                ? getFileTypeFromUrl(this.screen.illustration)
                : null
        },
    },
    watch: {
        visibleScreen() {
            this.inputHeight = 0
            setTimeout(() => this.setInputHeight(), 200)
        },
    },
    methods: {
        playVideo() {
            if (!this.isVideoPlaying) {
                this.$refs.videoPlayer.play()
                this.isVideoPlaying = true
            } else {
                this.$refs.videoPlayer.pause()
                this.isVideoPlaying = false
            }
        },
        setInputHeight() {
            this.inputHeight = !this.artefact.story.screens[this.visibleScreen]?.footerButtonText
                ? 0
                : this.$refs.footerButton?.scrollHeight
        },
        onDragStart(e: DragEvent, i: number) {
            const element = e.target
            e.dataTransfer.setData('index', `${i}`)
            klass(element, 'dragged')
        },
        onDragEnd(e: DragEvent) {
            const element = e.target
            unklass(element, 'dragged')
        },
        onDragOver(e: DragEvent) {
            e.preventDefault()
            const element = e.target
            klass(element, 'over')
        },
        onDragLeave(e: DragEvent) {
            const element = e.target
            unklass(element, 'over')
        },
        onDrop(e: DragEvent, j: number) {
            const i = parseInt(e.dataTransfer.getData('index'))

            if (this.label === 'advert') {
                this.$store.commit('advt/setSwitchStoryScreen', { i, j })
            } else if (this.label === 'article') {
                this.$store.commit('art/setSwitchArticleStoryScreens', { i, j })
            }

            this.navToScreen(j)
        },
        navToScreen(i: number) {
            this.isLoading = true
            for (const k in this.artefact.story.screens) {
                if (i === parseInt(k)) {
                    this.$emit('navToScreen', i)
                }
            }
        },
        onImgLoad() {
            this.isLoading = false
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onScreenLogoChange() {
            if (this.$refs.screenLogoInputP.files.length === 0) { return }
            const uploader = new ScreenLogoUpload(this.$refs.screenLogoInputP.files[0], this.artefact.id, this.artefact.story.screens[this.visibleScreen].id)

            Toaster.show('info', "Upload de l'image...")

            uploader.performUpload()
                .then((r) => {
                    Toaster.show('success', 'Image enregistrée.', 1400)
                    this.artefact.story.screens[this.visibleScreen].logo = r.webpath
                    this.$refs.screenLogo.src = r.webpath
                }).catch((error) => {
                    Toaster.show('error', `N'erreur est survenue lors de l'enregistrement de l'image.${error.toString()}`, 2800)
                })
        },
        ensureScreenLogoImg() {
            return `${(typeof this.artefact.story.screens[this.visibleScreen].logo !== 'undefined' && this.artefact.story.screens[this.visibleScreen].logo !== null)
                ? this.artefact.story.screens[this.visibleScreen].logo
                : '/images/default_logo_image.jpg'}?d=${uniqid()}`
        },
        async deleteScreen() {
            // eslint-disable-next-line
            const yes = confirm(`Supprimer l'écran n°${this.visibleScreen + 1}`)
            if (yes) {
                this.$emit('deleteScreen', this.visibleScreen, this.screen)
            }
        },
        isColorFormatCorrect(color: string) {
            const regex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i

            return color ? color.match(regex) : true
        },
        openLibraryModal() {
            EventBus.$emit('openGlobalModal', 'Library', {
                label: this.label,
                id: this.artefact.id,
                screenId: this.screen.id,
            })
        },
        convertVideoPreviewToImage() {
            if (this.mediaFormat !== 'video') {
                return
            }

            const { id } = this.screen
            const videoCanvas: any = document.getElementById(`canvas-${id}`)
            const video: any = document.getElementById(`video-src-${id}`)

            const ctx = videoCanvas.getContext('2d')

            ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight)

            const dataURL = videoCanvas.toDataURL()
            const img = document.createElement('img')
            img.setAttribute('id', `preview-temp-img-${id}`)
            img.src = dataURL
            img.style.position = 'absolute'
            video.style.display = 'none'
            videoCanvas.style.display = 'none'
            document.getElementById(`image-container-${id}`).appendChild(img)
        },
        deleteImgPreview() {
            if (this.mediaFormat !== 'video') {
                return
            }

            const { id } = this.screen
            const img = document.getElementById(`preview-temp-img-${id}`)
            const video: any = document.getElementById(`video-src-${id}`)
            video.style.display = 'block'
            img.remove()
        },
        drop() {
            if (this.draggedItem) {
                if (this.label === 'advert') {
                    this.$store.commit('advt/updateAdvertStoryScreenIllustration', {
                        illustration: this.draggedItem,
                        screenId: this.screen.id,
                    })
                }

                if (this.label === 'article') {
                    this.$store.commit('art/updateArticleStoryScreenIllustration', {
                        illustration: this.draggedItem,
                        screenId: this.screen.id,
                    })
                }
            }
        },
    },
})
