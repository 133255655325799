
import Vue from 'vue'
import { GridLayout, GridItem } from 'vue-grid-layout'
import editorOptions from '@/utils/editor-options-bat'

export default Vue.extend({
    name: 'SandBox',
    components: {
        GridLayout,
        GridItem,
    },
    props: [
        'bat',
        'page',
        'layer',
        'scale',
        'isActiveLayer',
        'isSVGDrawingEnabled',
    ],
    data() {
        return {
            editorOptions,
            selectedPath: null,
        }
    },
    computed: {
        batDynamicScale() {
            return `
            transform: scale(${this.scale});
            ${!this.isActiveLayer ? 'pointerEvents: none' : ''}
            `
        },
    },
    mounted() {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                this.selectedPath = null
            }

            if (e.key === 'Backspace') {
                this.deleteImageFromLayout(this.selectedPath)
            }
        })
    },
    methods: {
        setSelectedPath(e, id) {
            e.stopPropagation()
            this.selectedPath = id
        },
        convertToMobile(index) {
            this.layer.content.map((item) => {
                if (item.i === index) {
                    item.displayApp = !item.displayApp
                }

                return item
            })
        },
        deleteImageFromLayout(index: string) {
            if (!index) {
                return
            }

            const indexToRemove = this.layer.content.map(({ i }) => i).indexOf(index)
            this.layer.content.splice(indexToRemove, 1)
            this.selectedPath = null
        },
    },
})
