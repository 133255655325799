
import Vue from 'vue'
import { dayjs } from '@/utils/dayjs'
import JsonCSV from 'vue-json-csv'
import Tooltip from '@/components/Tooltip.vue'
import CsvImportButton from '@/components/CsvImportButton.vue'
import SelectFilter, { SelectFilterSelection } from '@/components/SelectFilter.vue'
import { populationCategoriesMapToId } from '@/utils/projectFilters'
import { mapRowSpecialityIdToName } from '@/utils/mapRowSpecialityIdToName'
import InventoryDisponibilityCheckModal from '@/components/modals/InventoryDisponibilityCheckModal.vue'

Vue.component('downloadCsv', JsonCSV)

export default Vue.extend({
    name: 'InventoryHeader',
    components: { Tooltip, SelectFilter, CsvImportButton, InventoryDisponibilityCheckModal },
    props: {
        headerData: {} as any,
        isMonthView: {
            type: Boolean,
            required: true,
            default: true,
        },
        userId: {
            type: String,
            required: true,
        },

    },
    data() {
        return {
            populationCategoriesMapToId,
            csvData: [],
        }
    },
    mounted() {
        this.exportCsv()
    },
    methods: {
        hasAnyRole(roles: string[]) {
            // user can have only ONE of the roles specified, but needs at least one
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        updateFilters(selection: SelectFilterSelection) {
            this.$store.commit('proj/setInventoryFilter', selection)
        },
        openModal() {
            this.$refs.InventoryDisponibilityCheckModal.openModal()
        },
        async exportCsv() {
            const now = dayjs()
            const twelveMonthLater = dayjs().add(11, 'M')

            const from = `${now.format('YY')}/${now.format('MM')}`
            const to = `${twelveMonthLater.format('YY')}/${twelveMonthLater.format('MM')}`

            const filters = {
                from,
                to,
                specialty: populationCategoriesMapToId.filter(({ label }) => label === 'Specialty').map(({ id }) => id),
                profession: populationCategoriesMapToId.filter(({ label }) => label === 'Profession').map(({ id }) => id),
                viewType: 'month',
            }

            const response = await this.$store.dispatch('proj/exportInventoryCsv', filters)

            this.csvData = response.map(({ id, inventory, label }) => {
                const jsonData = {
                    Profession: label === 'Profession' ? id : null,
                    Specialty: label === 'Specialty' ? id : null,
                    Nom: mapRowSpecialityIdToName(id, label, populationCategoriesMapToId),
                }

                inventory.forEach((element) => {
                    Object.assign(jsonData, {
                        [element.date]: element.totalViews,
                    })
                })

                return jsonData
            })
        },
    },

})
