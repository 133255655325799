
import Vue from 'vue'
import { mapState } from 'vuex'
import { DEEPLINK_SCHEMAS, DEEPLINK_EXPLANATIONS } from 'lib-ad-platform-models'
import { State } from '@/store'

export default Vue.extend({
    name: 'AdvertEditPart0',
    props: ['missingFields'],
    data() {
        return {
            searchTabs: {
                all: 'Tous',
                rcps: 'Medics',
                tools: 'tools',
                drive: 'Drive',
                eprescriptions: 'E-Prescription',
                news: 'Actualités',
                articles: 'Articles',
            },
            originalSource: null,
            deeplinkOptions: Object.values(DEEPLINK_SCHEMAS),
            deeplinkExplanations: Object.values(DEEPLINK_EXPLANATIONS),
        }
    },
    computed: mapState({
        advert: (state: State) => state.advt.advert,
    }),
    methods:
    {
        isTabChecked(key: string) {
            return this.advert.search.tabs.includes(key)
        },
        updateTabs(key: string) {
            if (this.advert.search.tabs.includes(key)) {
                this.advert.search.tabs = this.advert.search.tabs.filter((tab) => tab !== key)
            } else {
                this.advert.search.tabs.push(key)
            }
        },
        chooseDeeplink(schema: string) {
            if (schema === null) {
                this.advert.source = this.originalSource
                this.advert.quiz.link = this.originalSource
                return
            }

            this.originalSource = (this.originalSource === null) ? this.advert.source : this.originalSource
            const deeplink = schema.replace('%m_source%', encodeURIComponent(this.originalSource))
            this.advert.source = deeplink
            this.advert.quiz.link = deeplink
        },
        resetStoryExpDelay() {
            this.$store.dispatch('advt/resetStoryExpiration', { advertId: this.advert.id })
        },
    },
})
