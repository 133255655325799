
import Vue from 'vue'
import * as _ from 'lodash'
import { mapState } from 'vuex'
import { State } from '@/store'

export default Vue.extend({
    name: 'AdvertSelect',
    props: {},
    data() {
        return {
            items: [],
            isLoading: false,
            term: null,
            limit: 120,
            dropdownVisible: false,
        } as any
    },
    computed: {
        isShiftPressed() { return this.shiftPressed },
        ...mapState({
            results: (state: State) => state.audn.audienceSelectSearchResults,
        }),
    },
    methods: {
        close() {
            this.dropdownVisible = false
        },
        onSearch: _.debounce(async function (this: any) {
            if (this.term.length > 1) {
                try {
                    this.isLoading = true
                    const adverts = await this.$store.dispatch('advt/searchAdverts', { term: this.term })
                    this.items = adverts
                    this.dropdownVisible = true
                    this.isLoading = false
                } catch (error) {
                    console.log(error)
                }
            } else {
                this.dropdownVisible = false
            }
        }, 320),
        select(item: any) {
            this.term = item.name
            this.$emit('selected', { advert: item })
            this.close()
        }
    },
    mounted() {
        document.addEventListener('click', this.close, false)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close)
    },
})
