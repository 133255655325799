
import Vue from 'vue'

export default Vue.extend({
    name: 'SearchPreview',
    props: {
        advert: { type: Object, default: null },
        editable: { type: Boolean, default: false },
    },
    data() {
        return {
            visibleScreen: 0,
        }
    },
    methods:
    {

    },
})
