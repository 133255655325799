
import Vue from 'vue'
import * as sillyname from 'sillyname'
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import { Audience } from 'lib-ad-platform-models'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import { normalDate } from '@/filters/simpday.filter'
import ListSearchLabelsRecap from '@/views/partials/ListSearchLabelsRecap.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import AudienceDeleteModal from './partials/AudienceDeleteModal.vue'

const areasMatching = {
    french: '🇫🇷',
    english: '🇬🇧',
    spanish: '🇪🇸',
    italian: '🇮🇹',
    german: '🇩🇪',
}

export default Vue.extend({
    name: 'AudienceListPage',
    filters: { normalDate },
    components: { AudienceDeleteModal, ListSearchLabelsRecap, ConfirmModal },
    data() {
        return {
            params: {
                limit: 25,
                skip: 0,
                orderBy: 'createdAt',
                order: 'DESC',
                term: null,
                area: null,
                type: null,
                isInCatalog: null,
            },
            types: [
                'timeline',
                'search',
                'story',
                'notification',
                'banner',
            ],
            areas: [
                'french',
                'english',
                'spanish',
                'italian',
                'german',
            ],
            areasMatching,
            scrollToBottom: false,
            aud2Delete: {
                adverts: [],
            },
            selectedAudiences: [],
        }
    },
    computed: {
        ...mapState({
            loading: (state: State) => state.xhr.loading,
            audiences: (state: State) => state.audn.audiences,
        }),
    },
    created() {
        this.$store.commit('audn/setAudience', null)
        if (this.$store.state.audn.audiences.length === 0) {
            this.refresh()
        }
    },
    methods: {
        hasAnyRole(roles: string[]) {
            // user can have only ONE of the roles specified, but needs at least one
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        setSelectedAudiences(id:string) {
            if (this.selectedAudiences.includes(id)) {
                this.selectedAudiences.splice(this.selectedAudiences.indexOf(id), 1)
            } else {
                this.selectedAudiences.push(id)
            }
        },
        onScroll: debounce(function onScroll() {
            this.scrollToBottom = (this.$refs.page.scrollTop + this.$refs.page.offsetHeight + 100) >= this.$refs.page.scrollHeight

            if (this.scrollToBottom) {
                this.params.skip += this.params.limit
                this.scrollToBottom = false
                this.refresh()
            }
        }, 180),
        sort(field: string, order: string) {
            this.params.orderBy = field
            this.params.order = order
            this.refresh()
        },
        isSorted(field: string, order: string|null = null) {
            if (order === null) {
                return (this.params.orderBy === field)
            }
            return (this.params.orderBy === field && this.params.order === order)
        },
        refresh() {
            this.$store.dispatch('audn/loadAudiences', this.params)
        },
        filterByTerm() {
            this.params.skip = 0
            this.params.term = this.$refs.termInput.value
            this.refresh()
        },
        create(type: string) {
            this.$store.commit('audn/setAudience', new Audience({
                type,
                name: `Audience ${sillyname()}`,
                relationships: [{
                    kind: null,
                    name: 'french',
                    id: 'french',
                    label: 'Area',
                    caping: null,
                    event: null,
                    type: 'AND',
                }],
            }))

            this.$router.push('audiences/new')
        },
        duplicateAudience(id: string) {
            this.$store.dispatch('audn/duplicateAudience', id)
        },
        gotoEditPage(id: string) {
            this.$router.push(`/audiences/${id}`)
        },
        deleteAudience(audience) {
            this.aud2Delete = audience
            this.$modal.show('delete-audience-modal')
        },
        confirmeDeleteAudience() {
            const { id, name } = this.aud2Delete
            this.$store.dispatch('audn/rmAudience', { id })
                .then(() => {
                    this.closeDeleteModal()
                    Toaster.show('success', `Audience ${name} supprimée`, 4000)
                })
        },
        confirmeDeleteSeveralAudiences() {
            this.$store.dispatch('audn/rmSeveralAudiences', { audiences: this.selectedAudiences })
                .then(() => {
                    this.closeDeleteModal()
                    Toaster.show('success', `${this.selectedAudiences.length} ${this.selectedAudiences.length > 1 ? 'audiences supprimées' : 'audience supprimée'}`, 4000)
                    this.selectedAudiences = []
                })
        },
        closeDeleteModal() {
            this.$modal.hide('delete-audience-modal')
        },
        filterRemove(filter: {key: string, value: string}) {
            const { key } = filter
            this.params[key] = null
            this.refresh()
        },
    },
})
