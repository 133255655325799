
import Vue from 'vue'

export default Vue.extend({
    name: 'ConfirmModal',
    props: {
        name: { type: String, default: null, required: true },
        title: { type: String, default: 'Unkown confirm modal' },
        info: { type: String, default: null },
        list: { default: () => [] },
    },
    data() {
        return {
        }
    },
    methods: {
        confirm() {
            this.$emit('confirmed')
            this.$modal.hide(this.name)
        },
        cancel() {
            this.$modal.hide(this.name)
        },
    },
})
