
import Vue from 'vue'
import { mapState } from 'vuex'
import { TargetGoal, Target } from 'lib-ad-platform-models'
import JsonCSV from 'vue-json-csv'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import Api from '@/api/api'
import { formatThousands } from '@/filters/formatThousands.filter'

Vue.component('downloadCsv', JsonCSV)

const projectFields = {
    projectName: 'Projet',
    clientName: 'Labo',
    advertId: 'AD',
    advertSource: 'AD Source',
    actionCode: 'Code Action',
    status: 'Statut',
    keyAccount: 'CSM',
    type: 'Action',
    populationCategory: 'Spé',
    populationSample: 'Ciblage',
    populationSize: 'Taille de la cible',
    goal: 'Objectif',
    startDate: 'Date de début',
    checkDate: 'Deadline sortie KPIs',
    endDate: 'Date de fin estimée',
    actor: "type d'acteur",
    quotationSignedMonth: 'Mois signature du devis',
    initialQuotationDate: 'Date de diffusion initialement prévue sur le devis',
    revenue: 'CA HT',
    revenueDelayImpact: "CA de l'action après remise",
}

export default Vue.extend({
    name: 'ExportToCSV',
    props: ['data', 'filters', 'term'],
    data() {
        return {
            jsonData: null,
            isLoading: false,
        }
    },
    computed: {
        ...mapState({
            advertsFilters: (state: State) => state.advt.listFilters,
        }),
    },
    methods: {
        async fetchData(): Promise<any> {
            this.isLoading = true

            if (this.data === 'adverts') {
                return Api.post('/adverts', {
                    ...this.advertsFilters,
                    skip: 0,
                    limit: 1000,
                    published: true,
                })
                    .then((result: any) => {
                        this.jsonData = result.map((item) => {
                            const { id, type, name, author, source } = item

                            return {
                                id,
                                type,
                                name,
                                author,
                                source,
                            }
                        })
                    })
                    .then(() => {
                        if (this.jsonData.length > 0) {
                            // Simulate a click for lauch JsonCSV
                            this.$refs.dlBtn.$el.click()
                        } else {
                            Toaster.show('warning', 'No data to export', 2000)
                        }
                        this.isLoading = false
                    })
                    .catch((e: any) => {
                        this.isLoading = false
                        console.log(e)
                    })
            }

            if (this.data === 'projects') {
                return Api.post('/targets', {
                    fields: Object.keys(projectFields),
                    skip: 0,
                    limit: 1000000,
                    term: this.term,
                })
                    .then((result: Array<Target>) => {
                        this.jsonData = result.map((item) => {
                            return this.parseTargetsresult(item)
                        })
                    })
                    .then(() => {
                        if (this.jsonData.length > 0) {
                            // Simulate a click for lauch JsonCSV
                            this.$refs.dlBtn.$el.click()
                        } else {
                            Toaster.show('warning', 'No data to export', 2000)
                        }
                        this.isLoading = false
                    })
                    .catch((e: any) => {
                        console.log(e)
                        this.isLoading = false
                    })
            }

            if (this.data === 'groups') {
                return Api.get('/v2/groups', { limit: 10000 })
                    .then((result: Array<any>) => {
                        this.jsonData = result.sort((a, b) => (a.name > b.name ? 1 : -1))
                    })
                    .then(() => {
                        if (this.jsonData.length > 0) {
                            // Simulate a click for lauch JsonCSV
                            this.$refs.dlBtn.$el.click()
                        } else {
                            Toaster.show('warning', 'No data to export', 2000)
                        }
                        this.isLoading = false
                    })
                    .catch((e: any) => {
                        console.log(e)
                        this.isLoading = false
                    })
            }

            return Promise.resolve()
        },
        parseTargetsresult(result) {
            return Object.keys(result)
                .filter((key) => Object.keys(projectFields).includes(key))
                .sort((a, b) => {
                    return Object.keys(projectFields).indexOf(a) - Object.keys(projectFields).indexOf(b)
                })
                .reduce((obj, key) => {
                    const value = !result[key] || result[key] === 'null' ? '' : result[key]
                    if (key === 'goal') {
                        obj[projectFields[key]] = this.displayGoal(value) || ''
                    } else if (['startDate', 'endDate', 'checkDate', 'quotationSignedMonth', 'initialQuotationDate'].includes(key)) {
                        obj[projectFields[key]] = value && typeof value === 'number'
                            ? Intl.DateTimeFormat('fr').format(value * 1000)
                            : ''
                    } else if (typeof value === 'number') {
                        obj[projectFields[key]] = value.toString().replace('.', ',')
                    } else if (key === 'keyAccount') {
                        obj[projectFields[key]] = value ? value.name : ''
                    } else {
                        obj[projectFields[key]] = value ? value.replace("'", ' ').replace(/(\r\n|\n|\r)/gm, '') : ''
                    }
                    return obj
                }, {})
        },
        displayGoal(goal: TargetGoal) {
            if (goal === null) {
                return null
            }
            let text: string
            if (goal.views > 0) {
                return goal.views
            } if (goal.shipments > 0) {
                text = `${formatThousands(goal.shipments)} envois`
            } else if (goal.days > 0 && goal.days < 30) {
                text = `${goal.days} jours`
            } else if (goal.days >= 30) {
                text = `${Math.round((goal.days / 30) * 10) / 10} mois`
            } else {
                return null
            }

            return text
        },
    },
})
