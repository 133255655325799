
import Vue from 'vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { Target } from 'lib-ad-platform-models'
import { State } from '@/store'
import ProjectItem from '@/views/partials/ProjectItem.vue'
import ProjectListHead from '@/views/partials/ProjectListHead.vue'
import ProjectCreateModal from '@/views/partials/ProjectCreateModal.vue'
import ProjectActiveFilterList from '@/views/partials/ProjectActiveFilterList.vue'
import ProjectFiltersList from '@/views/partials/ProjectFiltersList.vue'
import SearchAdvertModal from '@/views/partials/SearchAdvertModal.vue'
import DelayReasonModal from '@/views/partials/DelayReasonModal.vue'
import FeedbacksDateModal from '@/views/partials/FeedbacksDateModal.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'

import { HTMLInputMouseEvent } from '@/models/HTMLEvent'
import EventBus from '@/components/EventBus'

export default Vue.extend({
    name: 'ProjectListPage',
    components: {
        ProjectListHead,
        ProjectItem,
        ProjectCreateModal,
        ProjectActiveFilterList,
        ProjectFiltersList,
        SearchAdvertModal,
        DelayReasonModal,
        FeedbacksDateModal,
        ConfirmModal,
    },
    computed: {
        ...mapState({
            loading: (state: State) => state.xhr.loading,
            projects: (state: State) => state.proj.projects,
            filters: (state: State) => state.proj.listFilters,
            csm: (state: State) => state.meta.csm,
            currentUserId: (state: State) => state.user.userId,
        }),
    },
    data() {
        return {
            targetId: null,
            target: null,
            projectId: null,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
                busy: true,
            },
            justChanged: false,
            projectToDel: null,
        }
    },
    watch: {
        csm() {
            // Set csm filter to current user by default
            this.setCurrentUserAsDefaultCSMFilter()
            this.refresh()
        },
    },
    created() {
        if (!this.projects.length) {
            // Set csm filter to current user by default
            this.setCurrentUserAsDefaultCSMFilter()
            this.refresh()
        }
    },
    mounted() {
        EventBus.$on('openFdbksModal', (target: Target) => {
            this.target = target
            this.$modal.show('fdbks-date')
        })

        EventBus.$on('openConfirmCancelTarget', (target: Target) => {
            this.target = target
            this.$modal.show('cancelTarget')
        })

        EventBus.$on('openDelayReasonModal', (target: Target) => {
            this.target = target
            this.$modal.show('delay-reason')
        })
        this.refresh()
    },
    methods: {
        change() {
            this.justChanged = true
            setTimeout(() => {
                this.justChanged = false
            }, 320)
        },
        deleteProject() {
            this.$store.dispatch('proj/deleteProject', this.projectToDel)
            this.projectToDel = null
        },
        toggleNewProjectModal(isOpen: boolean) {
            if (isOpen) {
                this.$modal.hide('create-new-project')
            } else {
                this.$modal.show('create-new-project')
            }
        },
        refresh() {
            // avoids reloading several times when scrolling down and changing a filter
            if (!this.filters.skip && this.$refs.projectTable) {
                this.$refs.projectTable.scrollTop = 0
            }

            this.change()
            this.$store.dispatch('proj/loadProjects', this.filters)
        },
        sort() {
            this.$refs.projectTable.scrollTop = 0
            this.refresh()
        },
        resetFilters() {
            this.$store.commit('proj/resetProjectFilters')
            this.refresh()
        },
        filterByTerm(term: string) {
            this.$store.commit('proj/setProjectFilter', {
                category: 'term',
                value: term,
                property: 'name',
            })
            this.refresh()
        },
        onScroll: debounce(function onScroll(e: HTMLInputMouseEvent) {
            const { skip, limit } = this.filters
            const isMoreProjects = skip + limit === this.projects.length

            if (
                e.srcElement.scrollTop // we must have scroll
                && e.srcElement.scrollTop + e.srcElement.offsetHeight + 800 > e.srcElement.scrollHeight // we must have reached the set limit
                && !this.justChanged // we should not have refresh very very very recently
                && isMoreProjects // There must be other projects to load
            ) {
                this.$store.commit('proj/setProjectFilter', {
                    value: skip + limit,
                    category: 'skip',
                    property: 'name',
                })
                this.refresh()
            }
        }, 180),
        openAdvertModal({ target, projectId }) {
            this.target = target
            this.projectId = projectId
            this.$modal.show('search-advert')
        },
        closeSearchAdvertModal() {
            this.$modal.hide('search-advert')
            this.targetId = null
            this.projectId = null
        },
        closeDelayReasonModal() {
            this.$modal.hide('delay-reason')
            this.target = null
        },
        openTimelineModal() {
            this.$modal.show('timeline-modal')
        },
        closeTimelineModal() {
            this.$modal.hide('timeline-modal')
        },
        dragMouseDown(event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag2(event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop
            - this.positions.movementY
            }px`
            this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft
            - this.positions.movementX
            }px`
        },
        openConfirmDeleteProject(id: string) {
            this.projectToDel = id
            this.$modal.show('deleteProject')
        },
        cancelTarget() {
            this.target.status = '7. annulé'
            this.target.releaseDateDiff = null
            this.target.delayReason = null
            this.target.revenueDelayImpact = null

            const { status, releaseDateDiff, delayReason, revenueDelayImpact } = this.target

            this.$store.dispatch('proj/updateTargetProps', {
                id: this.target.id,
                props: {
                    status,
                    releaseDateDiff,
                    delayReason,
                    revenueDelayImpact,
                },
            })
        },
        updateTarget(props: any) {
            Object.keys(props).forEach((key) => {
                this.target[key] = props[key]
            })

            this.$store.dispatch('proj/updateTargetProps', {
                id: this.target.id,
                props,
            }).then(() => this.$modal.hide('fdbks-date'))
        },
        setCurrentUserAsDefaultCSMFilter() {
            if (this.csm.length > 0) {
                const userExists = this.csm.find((user) => user.id === this.currentUserId)
                if (userExists) {
                    this.$store.commit('proj/setProjectFilter', {
                        category: 'keyAccount',
                        value: [userExists.name],
                        property: 'name',
                    })
                }
            }
        },
    },
})
