// fields which will use a date input and must be converted
// to a date filter on the target list page interface
// fields which will use a select dropdown to be edited and displayed
import { TARGET_STATUSES,
    TARGET_TYPES,
    TARGET_ACTORS,
    POPULATION_CATEGORIES_FLAT,
    DELAY_REASONS } from 'lib-ad-platform-models'

export const SELECT_TYPE_COLS = {
    status: TARGET_STATUSES,
    populationCategory: POPULATION_CATEGORIES_FLAT,
    type: TARGET_TYPES,
    actor: TARGET_ACTORS,
    delayReason: [
        'Aucune',
        ...DELAY_REASONS,
    ],
}
