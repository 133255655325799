
import Vue from 'vue'
import { mapState } from 'vuex'
import { DEEPLINK_SCHEMAS, DEEPLINK_EXPLANATIONS } from 'lib-ad-platform-models'
import { State } from '@/store'
import DeeplinkGenModal from '@/components/modals/DeeplinkGenModal.vue'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'AdvertEditPart2',
    props: ['missingFields'],
    components: {
        DeeplinkGenModal,
    },
    data() {
        return {
            originalSource: null,
            deeplinkOptions: Object.values(DEEPLINK_SCHEMAS),
            deeplinkExplanations: Object.values(DEEPLINK_EXPLANATIONS),
        }
    },
    computed: mapState({
        advert: (state: State) => state.advt.advert,
        deeplink: (state: State) => state.deeplink.deeplink,
    }),
    methods: {
        setQuizLink() {
            this.advert.quiz.link = this.advert.source
        },
        checkFields() {
            // remove quizz, video options on search types
            // if (this.advert.document.type === 'timeline') {
            //    this.$store.dispatch(CHANGE_ADVERT_PROP, { 'document.access_mask': "yolo" })
            // }
        },
        setDefaultFootNote(language: string) {
            const value = {
                french: 'Vos données sont protégées. Aucune donnée nominative n’est partagée avec le partenaire.',
                english: 'Your personal data is protected. No information is shared with the partner.',
                spanish: 'Sus datos están protegidos. No se compartirán sus datos personales con nadie.',
                italian: 'I tuoi dati sono protetti. Nessun dato personale viene condiviso con il partner.',
            }

            this.advert.footNote = value[language]
        },
        toggleNewDeeplinkModal(open: boolean) {
            return open
                ? this.$refs.DeeplinkModal.openModal()
                : this.$refs.DeeplinkModal.closeModal()
        },
        onCopy() {
            Toaster.show('success', 'Link copied', 2000)
            this.actionsToCopy = []
        },
        onError() {
            Toaster.show('error', 'Failed to copy actions', 2000)
            this.actionsToCopy = []
        },
    },
})
