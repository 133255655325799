
import Vue from 'vue'

export default Vue.extend({
    name: 'AdminEditUserModal',
    props: {
        name: { type: String, required: true },
        user: { type: Object, required: true },
    },
    data() {
        return {
            editedUser: this.user,
        }
    },
    mounted() {
        this.editedUser = { ...this.user }
    },
    methods: {
        cancel() {
            this.$modal.hide(this.name)
        },
        update() {
            const payload = { accountId: this.editedUser.id, name: this.editedUser.name, username: this.editedUser.email, nickname: this.editedUser.nickname }
            this.$store.dispatch('admin/updateAccount', payload)
            this.$modal.hide(this.name)
        },
    },
})
