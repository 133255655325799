
import Vue from 'vue'
import { Advert } from 'lib-ad-platform-models'
import { Toaster } from '@/components/Toaster'
import { AttachmentUpload } from '@/components/AttachmentUpload'

export default Vue.extend({
    name: 'ToolPreview',
    props: {
        advert: { type: Object, default: null },
        editable: { type: Boolean, default: false },
    },
    data() {
        return {

        }
    },
    methods:
    {
        ensureLogoImg(advert: Advert) {
            return (advert.logo !== 'undefined' && advert.logo !== null) ? advert.logo : '/images/default_logo_image.jpg'
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()
            if (!this.editable) { return }

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onLogoChanged() {
            if (this.$refs.logoInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')
            const handler = new AttachmentUpload(this.$refs.logoInput.files[0], this.advert.id)

            handler.performUpload('logo')
                .then((r) => {
                    Toaster.show('success', 'Logo enregistré', 1300)
                    this.$emit('updateAdvert', 'logo', r.webpath)
                    this.$refs.logoImg.src = r.webpath
                }).catch((e) => {
                    Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                })
        },
    },
})
