
import Vue from 'vue'
import { mapState } from 'vuex'
import { TargetGoal, Target } from 'lib-ad-platform-models'
import EventBus from '@/components/EventBus'
import { State } from '@/store'
import { UIKitStuff } from '@/models/MiscTypes'
import { targetFields } from '@/models/projectTarget'
import { normalDate } from '@/filters/simpday.filter'
import { truncate } from '@/filters/truncate.filter'
import { replaceWord } from '@/filters/replaceWord.filter'
import CellDisplay from '@/components/CellDisplay.vue'
import CellInputPicker from '@/components/CellInputPicker.vue'
import CellAdvertIdPicker from '@/components/CellAdvertIdPicker.vue'
import CellSelectPicker from '@/components/CellSelectPicker.vue'
import CellDatePicker from '@/components/CellDatePicker.vue'
import CellGoalInputPicker from '@/components/CellGoalInputPicker.vue'
import { DATE_TYPE_COLS } from '@/models/date-type-cols.model'
import { NUM_TYPE_COLS } from '@/models/num-type-cols.model'
import { SELECT_TYPE_COLS } from '@/models/select-type-cols.model'
import { CENTERED_COLS } from '@/models/centered-cols.model'
import { UNEDITABLE_COLS } from '@/models/uneditable-cols'
import { Toaster } from '@/components/Toaster'

declare let UIkit: UIKitStuff

export default Vue.extend({
    name: 'TargetItem',
    filters: { normalDate, truncate, replaceWord },
    components: {
        CellDisplay,
        CellInputPicker,
        CellAdvertIdPicker,
        CellSelectPicker,
        CellDatePicker,
        CellGoalInputPicker,
    },
    props: {
        target: { type: Object, default: null },
        index: { type: Number, default: null },
        projectId: { type: String, default: null },
        clientName: { type: String, default: null },
        projectName: { type: String, default: null },
        toggleEdit: null,
    },
    computed: {
        ...mapState({
            userId: (state: State) => state.user.userId,
        }),
        isFeedbackEnabled() {
            return (this.target.advertId || this.target.toolId)
            && ['3. stats à sortir',
                '3.1 Urgent - Stats à sortir',
                '3.2 stats intermédiaires',
                '4. feedback à préparer',
                '5. feedback prêt',
                '6. feedback réalisé'].includes(this.target.status)
        },
    },
    data() {
        return {
            editable: null,
            CENTERED_COLS,
            SELECT_TYPE_COLS,
            DATE_TYPE_COLS,
            NUM_TYPE_COLS,
            UNEDITABLE_COLS,
            editToolId: false,
            sheetValues: null,
            showConfirmDelete: false,
        }
    },
    watch: {
        showConfirmDelete(val) {
            if (val) {
                setTimeout(() => {
                    this.showConfirmDelete = false
                }, 5000)
            }
        },
    },
    mounted() {
        SELECT_TYPE_COLS.populationCategory = SELECT_TYPE_COLS.populationCategory
            .filter((item) => item !== 'Médecin')
            .sort()
            .sort((a) => {
                if (a === 'Médecine générale') {
                    return -1
                }
                return 1
            })

        SELECT_TYPE_COLS.type = SELECT_TYPE_COLS.type
            .sort()
            .sort((a) => {
                if (a === 'post') {
                    return -1
                }
                return 1
            })

        SELECT_TYPE_COLS.actor = SELECT_TYPE_COLS.actor
            .sort()
            .sort((a) => {
                if (a === 'Laboratoire') {
                    return -1
                }
                return 1
            })
    },
    methods: {
        hasAnyRole(roles: string[]) {
            return this.$store.getters['user/hasAnyRole'](roles)
        },
        hasThisRole(roles: string[]) {
            return this.$store.getters['user/hasThisRole'](roles)
        },
        setSheetValues() {
            this.sheetValues = this.generateSheet()
        },
        enableCell(i: number, col: string) {
            this.editable = `${i}${col}`
        },
        isLinkTo(type: string) {
            if (['mise en avant', 'référencement'].includes(type)) {
                return 'tool'
            }
            if (['post', 'video', 'quiz', 'story', 'story video', 'search', 'contenu additionel', 'notification', 'sondage', 'conseil associé', 'flash notif'].includes(type)) {
                return 'advert'
            }
            return null
        },
        onTextCellChange(value: string, col: string, target: Target) {
            target[col] = value
            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props: { [col]: value },
            })
        },
        onDateCellChange(value: string, col: string, target: Target) {
            target[col] = value
            const props = {
                [col]: value,
            }

            // update endDate if startDate is modifier and endDate is empty
            if (col === 'startDate' && target.type !== 'notification') {
                if (target.endDate === null) {
                    target.endDate = target.startDate + 86400 * 90
                    Object.assign(props, { endDate: target.endDate })
                }
            }

            if (
                (['startDate', 'endDate'].includes(col) && target.initialQuotationDate)
                || col === 'initialQuotationDate'
            ) {
                const oneDay = 86400
                const dateDiff = Math.round((target.startDate - target.initialQuotationDate) / oneDay)
                const broadcastDaysDuration = Math.round((target.endDate - target.startDate) / oneDay)

                target.releaseDateDiff = dateDiff
                Object.assign(props, { releaseDateDiff: dateDiff })

                if (target.revenue) {
                    target.revenueDelayImpact = dateDiff <= 15 ? 0 : (target.revenue * (dateDiff - 15)) / broadcastDaysDuration
                    Object.assign(props, { revenueDelayImpact: target.revenueDelayImpact })
                }

                if (!target.delayReason && dateDiff > 15) {
                    EventBus.$emit('openDelayReasonModal', this.target)
                }
            }

            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props,
            })
        },
        onCellSelectChange(value: string, col: string, target: Target) {
            if (col === 'status' && value === '7. annulé') {
                EventBus.$emit('openConfirmCancelTarget', target)
                return
            }

            if (col === 'status' && value === '3.2 stats intermédiaires') {
                EventBus.$emit('openFdbksModal', target)
                return
            }

            // unset delayReason value
            target[col] = (col === 'delayReason' && value === 'Aucune')
                ? null
                : value

            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props: { [col]: target[col] },
            })
        },
        onCellGoalChange(value: TargetGoal, col: string, target: Target) {
            target[col] = value
            this.$store.dispatch('proj/updateTargetProps', {
                id: target.id,
                props: { [col]: value },
            })
        },
        openFeedbacks() {
            window.open(`https://feedbacks-prd-55313450491.ew.r.appspot.com/feedback/${this.target.advertId}/view`, '_blank')
        },
        duplicateTarget() {
            this.$store.dispatch('proj/duplicateTarget', {
                target: this.target,
                projectId: this.projectId,
                position: this.index + 1,
            })
        },
        deleteTarget() {
            this.$store.dispatch('proj/deleteTarget', {
                targetId: this.target.id,
                projectId: this.projectId,
            })
        },
        createNewAdvert() {
            const advertName = `${this.clientName.toUpperCase()} - ${this.projectName} - ${this.target.actionCode}`

            window.localStorage.setItem('targetId', this.target.id)
            this.$router.push(`/adverts/edit/project-${advertName}`)
        },
        openAdvertModal() {
            this.$emit('openAdvertModal', this.target)
        },
        attributeToolId() {
            this.$store.dispatch('proj/updateTargetProps', {
                id: this.target.id,
                props: {
                    toolId: this.target.toolId,
                },
            })
            this.editToolId = false
            UIkit.dropdown(this.$refs.dropdownRef).hide()
        },
        cancelToolId() {
            this.editToolId = false
            UIkit.dropdown(this.$refs.dropdownRef).hide()
        },
        closeDropDown() {
            UIkit.dropdown(this.$refs.dropdownRef).hide()
            setTimeout(() => { this.sheetValues = null }, 2000)
        },
        getGoalValue(value: string) {
            const goal = new TargetGoal(JSON.parse(value))
            return `${goal.getValue()} ${goal.getType()}`
        },
        generateSheet() {
            const header = Object.keys(targetFields).map((key) => targetFields[key].label).join('\t')

            const excelData = Object.keys(targetFields)
                .map((key) => {
                    const { format, item } = targetFields[key]

                    let value = null
                    const object = item ? this[item][key] : this[key]

                    if (!object) {
                        value = ''
                    } else if (format === 'date') {
                        value = Intl.DateTimeFormat('fr').format(parseInt(`${object}`) * 1000)
                    } else if (format === 'goal') {
                        value = this.getGoalValue(JSON.stringify(object))
                    } else if (format === 'delay') {
                        value = `${object} jours`
                    } else {
                        value = object
                    }

                    return value
                })
                .join('\t')

            return `${header}\n${excelData}`
        },
        onCopy() {
            Toaster.show('info', 'Actions copied', 2000)
            this.actionsToCopy = []
        },
        onError() {
            Toaster.show('error', 'Failed to copy actions', 2000)
            this.actionsToCopy = []
        },
        async detachAdvert() {
            try {
                await this.$store.dispatch('proj/unsetAdvertRel', this.target.id)

                this.target.advertId = null
                this.target.toolId = null

                await this.$store.dispatch('proj/updateTargetProps', {
                    id: this.target.id,
                    props: {
                        advertId: null,
                        toolId: null,
                    },
                })
                Toaster.show('success', "Elément détaché de l'action avec succès", 3500)
            } catch (error) {
                Toaster.show('error', error, 3000)
            } finally {
                this.showConfirmDelete = false
                UIkit.dropdown(this.$refs.dropdownRef).hide()
            }
        },
    },
})
