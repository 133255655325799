
import Vue from 'vue'
import uniqid from 'uniqid'
import { mapState } from 'vuex'
import { Target, TARGET_STATUSES } from 'lib-ad-platform-models'
import { State } from '@/store'
import Timeline from '@/components/Timeline.vue'
import { replaceWord } from '@/filters/replaceWord.filter'
import { capitalize } from '@/filters/capitalize.filter'
import CellSelectPicker from '@/components/CellSelectPicker.vue'
import AdvertPreview from '@/views/partials/AdvertPreview.vue'
import TargetItem from './TargetItem.vue'

export default Vue.extend({
    name: 'ProjectItem',
    filters: { replaceWord, capitalize },
    components: { TargetItem, Timeline, CellSelectPicker, AdvertPreview },
    props: {
        project: { type: Object, default: null },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapState({
            previewAdvert: (state: State) => state.advt.previewAdvert,
            csm: (state: State) => state.meta.csm,
        }),
    },
    data() {
        return {
            editable: false,
            isProjectPage: false,
            newTarget: null,
            timelineProjectId: null,
            editKeyAccount: false,
        }
    },
    created() {
        if (
            [`/project/edit/${this.project.id}`, '/project/edit/new'].includes(
                this.$route.path,
            )
        ) {
            this.isProjectPage = true
        }
    },
    methods: {
        forceRerender() {
            this.componentKey += 1
        },
        openProject() {
            this.$router.push(`/project/edit/${this.project.id}`)
        },
        openTimeline(projectId: string) {
            this.timelineProjectId = projectId
        },
        closeTimeline() {
            this.timelineProjectId = null
        },
        addTarget() {
            const actionCode = `${this.project.clientName.substring(
                0,
                3,
            )}${this.project.name.substring(0, 4)}21-1.A1`

            this.newTarget = new Target({
                id: `temp-${uniqid()}`,
                actionCode: actionCode.toUpperCase(),
                status: TARGET_STATUSES[0],
                type: 'post',
            })

            this.$store.dispatch('proj/addNewTargetToProject', {
                projectId: this.project.id,
                target: this.newTarget,
            })
            this.newTarget = null
        },
        openAdvertModal(target: Target) {
            this.$emit('openAdvertModal', {
                target,
                projectId: this.project.id,
            })
        },
        updateKeyAccount(value: string) {
            const newkeyAccount = { id: this.getKeyaccountId(value), name: value }
            this.$store.dispatch('proj/updateProject', {
                id: this.project.id,
                keyAccount: newkeyAccount,
            }).then(() => {
                this.project.keyAccount = newkeyAccount
            })
        },
        deleteProject() {
            this.$emit('deleteProject', this.project.id)
        },
        openProjectWithComments() {
            const routeData = this.$router.resolve(`/project/edit/${this.project.id}#comment`)
            window.open(routeData.href, '_blank')
        },
        getKeyaccountId(name: string) {
            return this.csm.find((item) => item.name === name)?.id ?? null
        },
    },
})
