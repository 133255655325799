
import Vue from 'vue'
import { mapState } from 'vuex'
import * as htmlToImage from 'html-to-image'
import { Advert, DELAY_REASONS } from 'lib-ad-platform-models'
import Api from '@/api/api'
import { State } from '@/store'
import { isEmpty } from '@/utils/isEmpty'
import { Toaster } from '@/components/Toaster'
import { simpday, normalDate } from '@/filters/simpday.filter'
import { advertParser } from '@/utils/advertParser'
import AdvertPreview from '@/views/partials/AdvertPreview.vue'
import AdvertEditPart0 from '@/views/partials/AdvertEditPart0.vue'
import AdvertEditPart1 from '@/views/partials/AdvertEditPart1.vue'
import AdvertEditPart2 from '@/views/partials/AdvertEditPart2.vue'
import AdvertEditPart3 from '@/views/partials/AdvertEditPart3.vue'
import AdvertEditPart4 from '@/views/partials/AdvertEditPart4.vue'
import AdvertEditPart6 from '@/views/partials/AdvertEditPart6.vue'
import AdvertEditPart7 from '@/views/partials/AdvertEditPart7.vue'
import AdvertEditFunnel from '@/views/partials/AdvertEditFunnel.vue'
import AdvertEditTracking from '@/views/partials/AdvertEditTracking.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import ImagesEditor from '@/components/ImagesEditor.vue'
import StoryCreator from '@/views/partials/StoryCreator.vue'
import ConfirmButton from '@/components/ConfirmButton.vue'
import { advertReqFields } from '@/models/artefact-required-fields.models'

export default Vue.extend({
    name: 'AdvertEditPage',
    filters: { simpday, date: normalDate },
    components: {
        AdvertPreview,
        AdvertEditPart0,
        AdvertEditPart1,
        AdvertEditPart2,
        AdvertEditPart3,
        AdvertEditPart4,
        AdvertEditPart6,
        AdvertEditPart7,
        AdvertEditFunnel,
        AdvertEditTracking,
        ConfirmButton,
        ConfirmModal,
        ImagesEditor,
        StoryCreator,
    },
    data() {
        return {
            env: process.env.APP_ENV,
            validationMsgs: [],
            missingFields: [],
            ctrlKeyPressed: false,
            isNew: false,
            previewScale: 1,
            isPreviewDeployed: false,
            shouldUpdateAudience: false,
            previewLoading: false,
            showVideoControls: false,
            videoDatas: null,
            DELAY_REASONS,
            targetProps: {},
            isPending: false,
        }
    },
    computed: {
        filtering() {
            return 1
        },
        ...mapState({
            advert: (state: State) => state.advt.advert,
            loading: (state: State) => state.xhr.loading,
            advertProjectId: (state: State) => state.proj.advertProjectId,
            audienceRoles: (state: State) => {
                const audienceRoles = state.advt.advert._$audiences.map((a: any) => a.roles)
                return audienceRoles
            },
            roles: (state: State) => state.user.roles,
        }),
        isActionOverdue() {
            const { _$target } = this.advert
            if (!_$target || !('initialQuotationDate' in _$target)) {
                return false
            }

            const oneDay = 86400
            const dateDiff = Math.round((Math.floor(new Date().getTime() / 1000) - _$target.initialQuotationDate) / oneDay)

            return dateDiff > 15
        },
    },
    created() {
        if (this.$route.params.id === 'new') {
            this.isNew = true
            this.$store.commit('advt/createNewAdvert', {
                _model: 'latest',
                name: '',
            })
        } else if (this.$route.params.id.includes('project-')) {
            this.isNew = true
            const advertName = this.$route.params.id.substring(8)
            this.$store.commit('advt/createNewAdvert', {
                _model: 'latest',
                name: advertName,
            })
        } else {
            this.$store.dispatch('advt/loadAdvert', this.$route.params.id)
        }
    },
    mounted() {
    // detect CTRL+S to save current ad
        document.addEventListener(
            'keydown',
            (e) => {
                this.missingFields = []

                this.ctrlKeyPressed = !!e.metaKey

                if (this.ctrlKeyPressed && e.key.toLowerCase() === 's') {
                    e.preventDefault()
                    this.submit()
                    this.ctrlKeyPressed = false
                }
            },
            false,
        )

        document.addEventListener(
            'keyup',
            () => {
                this.ctrlKeyPressed = false
            },
            false,
        )
    },
    methods: {
        hasAllRoles(roles: string[]) {
            // user need to have ALL the roles specified to match (see user store getter)
            return this.$store.getters['user/hasAllRoles'](roles)
        },
        rolesContainsExpert(roles: string[]) {
            if (roles === undefined) {
                return false
            }
            return !!roles.includes('ROLE_EXPERT')
        },
        async back() {
            if (!this.validate(this.advert.published)) {
                Toaster.show('warning', this.validationMsgs.join(' / '), 3000)
                setTimeout(() => { this.missingFields = [] }, 3000)
                return
            }

            if (this.isNew) {
                this.$store
                    .dispatch('advt/createAdvert', this.advert)
                    .then(() => this.$router.go(-1))
            } else {
                if (this.shouldUpdateAudience) {
                    await this.$store.dispatch('advt/attachAdvertToAudience', {
                        advert: this.advert,
                        audienceIds: this.advert._$audiences.map(({ id }) => id),
                    })
                    this.shouldUpdateAudience = false
                }
                this.$store
                    .dispatch('advt/updateAdvert', { advert: this.advert })
                    .then(() => {
                        this.$store.commit('advt/setAdvert', advertParser(this.advert))
                    })
                    .then(() => {
                        return this.$router.go(-1)
                    })
            }
        },
        validate(isForPublication = false) {
            const {
                name,
                autoStartAt,
                autoStopAt,
                autoPublishOptions: { emails },
                type,
            } = this.advert as Advert
            let valid = true
            this.validationMsgs = []

            const errorsFormatter = [
                {
                    status: !isEmpty(autoStartAt) && isEmpty(emails),
                    name: ['email'],
                    message:
            'Au moins un email doit être renseigné pour la publication automatique',
                },
                {
                    status: isEmpty(name),
                    name: ['name'],
                    message: "Le nom de l'advert est requis",
                },
                {
                    status: isEmpty(autoStartAt) && !isEmpty(autoStopAt),
                    name: ['autoStartAt'],
                    message: 'La date de publication est requise',
                },
                {
                    status: !isEmpty(autoStartAt) && isEmpty(autoStopAt),
                    name: ['autoStopAt'],
                    message: 'La date de dé-publication est requise',
                },
                {
                    status: this.advert.type === 'story' && this.checkStoryColors(),
                    name: ['Story color'],
                    message: "Il y a une erreur dans le format d'une couleur de story",
                },
                {
                    status: isEmpty(type),
                    name: ['type'],
                    message: "Le type de l'advert est requis",
                },
            ]

            if (isForPublication) {
                errorsFormatter.push({
                    status: advertReqFields[this.advert.type]?.some((key: string) => isEmpty(this.advert[key])),
                    name: advertReqFields[this.advert.type]?.filter((key: string) => isEmpty(this.advert[key])),
                    message: `Les propriétés [${advertReqFields[this.advert.type]?.filter((key: string) => isEmpty(this.advert[key])).join(', ')}] sont requises`,
                })
            }

            errorsFormatter.forEach(({ status, name, message }) => {
                if (!status) {
                    return
                }

                valid = false
                this.missingFields.push(...name)
                this.validationMsgs.push(message)
            })

            return valid
        },
        checkStoryColors() {
            const { screens } = this.advert.story
            const regex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i

            return screens.some(({ backgroundColor, footerBgColor, footerButtonTextColor, footerChevronColor, subtitleColor, titleColor }) => {
                return [backgroundColor, footerBgColor, footerButtonTextColor, footerChevronColor, subtitleColor, titleColor].some((item) => !item.match(regex))
            })
        },
        submitFromImagesEditor() {
            if (!this.advert.images.length) {
                this.advert.image = null
            } else {
                // eslint-disable-next-line prefer-destructuring
                this.advert.image = this.advert.images[0]
            }
            this.submit()
        },
        async submit() {
            Toaster.show('info', 'Saving...')

            if (!this.validate(this.advert.published)) {
                Toaster.show('warning', this.validationMsgs.join(' / '), 3000)
                setTimeout(() => { this.missingFields = [] }, 3000)
                return
            }

            // validate tool sonataId field
            if (this.advert.type === 'tool' && this.advert.sonataId === '') {
                Toaster.show('warning', 'Sonata ID is required', 3000)
                return
            }

            if (this.isNew) {
                const newAdvertId = await this.$store.dispatch('advt/createAdvert', this.advert)
                Toaster.show('success', 'Enregistré', 1300)
                this.$router.push(`/adverts/edit/${newAdvertId}`)

                this.isNew = false
            } else {
                const advert = { ...this.advert }
                delete advert._$audiences
                if (this.shouldUpdateAudience) {
                    await this.$store.dispatch('advt/attachAdvertToAudience', {
                        advert,
                        audienceIds: this.advert._$audiences.map(({ id }) => id),
                    })
                    this.shouldUpdateAudience = false
                }
                await this.$store.dispatch('advt/updateAdvert', {
                    advert: {
                        ...advert,
                        updatedAt: Math.floor(Math.floor(new Date().getTime() / 1000)),
                        _$target: {
                            ...advert._$target,
                            ...this.targetProps,
                        },
                    },
                })

                this.$store.commit('advt/setAdvert', advertParser({
                    ...this.advert,
                    updatedAt: Math.floor(Math.floor(new Date().getTime() / 1000)),
                }))
            }
            Toaster.show('success', 'Enregistré', 1300)
        },
        setPreviewScale(action: string) {
            if (action === '+' && this.previewScale <= 0.95) {
                this.previewScale += 0.05
            }

            if (action === '-' && this.previewScale >= 0.5) {
                this.previewScale -= 0.05
            }
        },
        togglePreview() {
            this.isPreviewDeployed = !this.isPreviewDeployed
        },
        isAdvertValidForPublication() {
            if (!this.advert.published && this.advert.type === 'video' && !this.advert.video) {
                return 'Vous devez ajouter une vidéo avant de publier la publicité'
            }

            if (!this.advert._$audiences.length) {
                return 'Aucune audience associée à la publicité'
            }

            let expertCheck = true
            for (const i in this.audienceRoles) {
                const roles = this.audienceRoles[i]

                if (!this.rolesContainsExpert(roles)) {
                    expertCheck = false
                }
            }

            if (!expertCheck) {
                return 'Audience non expert associée à la publicité'
            }

            return null
        },
        async publishAdvert(publish: boolean, updateDates: boolean) {
            if (this.isAdvertValidForPublication()) {
                Toaster.show('error', this.isAdvertValidForPublication(), 3200)
                return
            }

            if (!this.validate(!this.advert.published)) {
                Toaster.show('warning', this.validationMsgs.join(' / '), 3000)
                setTimeout(() => { this.missingFields = [] }, 3000)
                return
            }

            const body = {
                advert: this.advert,
                publish,
                updateDates,
                target: this.targetProps,
            }

            this.isPending = true

            try {
                await this.submit()
                await this.$store.dispatch('advt/publishAdvert', body)
                if (updateDates) {
                    this.toggleDelayReasonModal(false)
                }
            } catch (error) {
                Toaster.show('error', error, 3000)
            } finally {
                this.isPending = false
            }
        },
        toggleDelayReasonModal(show: boolean) {
            this.$modal[show ? 'show' : 'hide']('delayReason')
        },
        openProject() {
            this.$router.push(`/project/edit/${this.project.id}`)
        },
        async convertToArticle() {
            try {
                const id = await this.$store.dispatch('advt/migrateAdvertToArticle', { id: this.advert.id })
                this.$router.push(`/articles/edit/${id}`)
            } catch (error) {
                Toaster.show('error', error, 3000)
            }
        },
        async generateScreenshot() {
            Toaster.show('chargement', "Création de l'image en cours")
            this.previewLoading = true

            try {
                if (this.advert.type === 'video') this.convertVideoPreviewToImage()

                const formData = new FormData()
                formData.append('advertId', this.advert.id)
                await Promise.all(Array.from(document.getElementsByClassName('bat-preview')).map(async (item: any, i) => {
                    const blob = await htmlToImage.toBlob(item)
                    const file = new File([blob], `name${i}.png`, { lastModified: new Date().getTime(), type: blob.type })

                    formData.append(`file${i}`, file)
                }))

                if (this.advert.type === 'video') this.deleteImgPreview()

                await Api.upload('/bat/screenshot/upload', formData)

                Toaster.show('success', "Votre capture est disponible dans l'onglet BAT", 3000)
                this.previewLoading = false
            } catch (error) {
                Toaster.show('error', error, 3500)
                this.previewLoading = false
            }
        },
        gotoBatPage() {
            this.$router.push(`/advert/bat/${this.advert.id}`)
        },
        convertVideoPreviewToImage() {
            const videoCanvas: any = document.getElementById('canvas')
            const video: any = document.getElementById('video-src')

            // convert seconds to mm:ss
            const secondsToTime = (secs: number) => {
                const time = Math.floor(secs)
                const minutes = Math.floor(time / 60)
                const seconds = time - minutes * 60
                return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
            }

            this.videoDatas = {
                current: secondsToTime(video.currentTime),
                total: secondsToTime(video.duration),
                progression: Math.round((video.currentTime / video.duration) * 100),
            }

            const ctx = videoCanvas.getContext('2d')

            ctx.drawImage(video, 0, 0, video.clientWidth * 2, video.clientHeight * 2)
            ctx.scale(2, 2)

            const dataURL = videoCanvas.toDataURL()
            const img = document.createElement('img')
            img.setAttribute('id', 'preview-temp-img')
            img.src = dataURL
            video.style.display = 'none'
            videoCanvas.style.display = 'none'
            document.getElementById('cover-image').appendChild(img)
            this.showVideoControls = true
        },
        deleteImgPreview() {
            const img = document.getElementById('preview-temp-img')
            const video: any = document.getElementById('video-src')
            video.style.display = 'block'
            img.remove()
            this.showVideoControls = false
            this.videoDatas = null
        },
        getReasonParts(reason: string) {
            return reason.split(' - ')
        },
        setDelayReason(reason: string) {
            const { _$target } = this.advert

            const oneDay = 86400
            const dateDiff = Math.round((Math.floor(new Date().getTime() / 1000) - _$target.initialQuotationDate) / oneDay)
            const broadcastDaysDuration = Math.round((_$target.endDate - Math.floor(new Date().getTime() / 1000)) / oneDay)

            this.targetProps = {
                delayReason: reason,
                releaseDateDiff: dateDiff,
                revenueDelayImpact: dateDiff <= 15 && _$target.revenue ? 0 : (_$target.revenue * (dateDiff - 15)) / broadcastDaysDuration,
            }
        },
        displayImagesEditor(display: boolean) {
            this.$modal[display ? 'show' : 'hide']('imagesEditor')
        },
    },
})
