
import Vue from 'vue'
import { Toaster } from '@/components/Toaster'
import { AttachmentUpload } from '@/components/AttachmentUpload'
import ConfirmModal from '@/components/ConfirmModal.vue'
import Api from '../api/api'

export default Vue.extend({
    name: 'ImagesEditor',
    components: {
        ConfirmModal,
    },
    props: {
        images: {
            type: Array,
            required: true,
            default: () => [],
        },
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            imageToDelete: null,
        }
    },
    computed: {
        isNew() {
            return this.$route.params.id === 'new'
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        sortImages(event) {
            const items = event.target.querySelectorAll('li')
            const indexOrder = []

            items.forEach((item) => indexOrder.push(item.getAttribute('data-id')))

            this.images.sort((a, b) => indexOrder.indexOf(a) - indexOrder.indexOf(b))
            this.updateAdvertImages()
        },
        updateAdvertImages() {
            this.$emit('update')
        },
        browseFile(e: MouseEvent, element: HTMLElement) {
            e.stopPropagation()

            const mouseClick = new MouseEvent('click', {
                bubbles: false,
                cancelable: true,
                view: window,
            })

            element.dispatchEvent(mouseClick)
        },
        onCoverMediaChanged() {
            if (this.$refs.coverInput.files.length === 0) {
                return
            }

            Toaster.show('info', 'Patientez...')
            const destination = 'cover'

            const handler = new AttachmentUpload(this.$refs.coverInput.files[0], this.id)

            handler.performUpload(destination)
                .then((r) => {
                    this.images.push(r.webpath)
                    this.updateAdvertImages()
                }).catch((e) => {
                    Toaster.show('error', `Une erreur est survenue: ${e.toString()}`, 2500)
                })
        },
        openConfirmDelete(id: number) {
            this.imageToDelete = id
            this.$modal.show('deleteImage')
        },
        async deleteImage() {
            const filePath = this.images[this.imageToDelete]
            if (!filePath.startsWith(process.env.VUE_APP_CDN_DOMAIN)) {
                Toaster.show('warning', 'Vous ne pouvez pas supprimer une image en cours de prévisualisation', 2500)
                return
            }
            try {
                Toaster.show('info', 'Patientez...')
                await Api.delete('/f/advert/attachment/delete', { filePath })
                this.images.splice(this.imageToDelete, 1)

                this.updateAdvertImages()
            } catch (error) {
                Toaster.show('error', `Une erreur est survenue: ${error.toString()}`, 2500)
            }
        },
    },
})
