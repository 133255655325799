
import Vue from 'vue'
import { Relation, keywordId } from 'lib-ad-platform-models'
import Papa from 'papaparse'
import { Toaster } from '@/components/Toaster'
import RelationshipGroup from './RelationshipGroup.vue'

export default Vue.extend({
    name: 'RelationshipGroupList',
    components: { RelationshipGroup },
    props: {
        audience: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            emailsList: [],
            message: null,
            isLoading: false,
            groupName: null,
        }
    },
    methods: {
        toggleNewGroupModal(open: boolean) {
            if (open) {
                this.$modal.show('add-new-group-modal')
            } else {
                this.$modal.hide('add-new-group-modal')
            }
        },
        addGroup() {
            this.$modal.hide('add-new-group-modal')

            const source = this.audience
            const target = { id: null, label: 'OrGroup', name: this.groupName } // null as id will create a uniq id on backend

            const relation: Relation = new Relation({
                type: 'AND',
                kind: 'required',
                event: (this.audience.type === 'search' && target.label === 'Keyword') ? 'search' : null,
                caping: null,
            })

            this.$store.dispatch('audn/addAudienceRel', { source, target, relationship: relation })
        },
        addKeywordModal() {
            this.$modal.show('add-keyword-modal', {})
        },
        addListModal() {
            this.$modal.show('add-list-modal', {})
        },
        closeListModal() {
            this.$modal.hide('add-list-modal')
            this.message = null
        },
        submitAddKeywordForm() {
            const keyword = this.$refs.addKeywordInput.value.toLowerCase().trim()
            this.$modal.hide('add-keyword-modal')

            const target = {
                id: keywordId(keyword),
                name: keyword,
                label: 'Keyword',
            }

            const relation = new Relation({
                type: 'SEARCH',
                kind: 'required',
                event: 'search',
            })

            this.$store.dispatch('audn/addAudienceRel', { source: this.audience, target, relationship: relation })
        },
        async submitAddListForm() {
            const { id, type, name, area } = this.audience

            let audienceId = id

            if (!audienceId) {
                audienceId = await this.$store.dispatch('audn/createAudience', { type, name, area })
            }

            this.isLoading = true
            this.$store.dispatch(
                'audn/importUsersListInAudience',
                {
                    emails: this.emailsList,
                    audienceId,
                },
            ).then((result: string) => {
                this.isLoading = false
                this.message = result
            })

            if (this.$route.params.id === 'new') {
                this.$router.push(`/audiences/${audienceId}`)
            }
        },
        async getCsvFile(e) {
            if (!e.target.files.length) {
                return
            }

            const file = e.target.files[0]
            const reader = new FileReader()
            reader.readAsText(file)

            const result = await new Promise((resolve) => {
                reader.onload = function () {
                    resolve(reader.result)
                }
            })

            const tempResult = Papa.parse(result, { header: true }).data

            // check if data is ok
            if (tempResult.some((item) => 'email' in item)) {
                this.emailsList = Papa.parse(result, { header: true }).data.map(({ email }) => email)
            } else {
                Toaster.show('error', "Erreur: Le fichier ne contient pas de colonne 'emails'", 3000)
            }
        },
        groupBeeingEdited(e: any) {
            this.$emit('groupBeeingEdited', e)
        },
        someEvent() {
            this.$emit('someEvent')
        },
    },
})
