export type LoadingState = {
    loading: boolean
}

const state = () => ({
    loading: false,
})

const getters = {

}

const mutations = {
    isPending: (state: LoadingState, status: boolean): void => {
        state.loading = status
    },
}

const actions = {}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
