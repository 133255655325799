
import Vue from 'vue'

export default Vue.extend({
    name: 'ToolTip',
    props: {
        textContent: {
            type: String,
            default: '',
            required: true,
        },
        position: {
            type: String,
            default: 'top-left',
            required: false,
        },
        offset: {
            type: Number,
            default: 0,
            required: false,
        },
    },
})
