
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'

export default Vue.extend({
    name: 'ArticleEditPart4',
    data() {
        return {

        }
    },
    computed: mapState({
        article: (state: State) => state.art.article,
    }),
})
