
import Vue from 'vue'

export default Vue.extend({
    name: 'ExpertSelector',
    props: ['value'],
    data() {
        return {
            selected: '',
        }
    },
    mounted() {
        this.selected = this.value
    },
    methods: {
        change() {
            this.$emit('onChange', this.selected)
        },
    },
})
