
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import SelectFilter, { SelectFilterSelection } from '@/components/SelectFilter.vue'
import DateFilter from '@/components/DateFilter.vue'
import { targetStatus, types, populationCategories } from '@/utils/projectFilters'

export default Vue.extend({
    name: 'ProjectFiltersList',
    components: { SelectFilter, DateFilter },
    props: ['mode'],
    computed: {
        ...mapState({
            projects: (state: State) => state.proj.projects,
            filters: (state: State) => state.proj.listFilters,
            csm: (state: State) => state.meta.csm,
        }),
        sort() {
            return {
                orderBy: this.filters.orderBy,
                order: this.filters.order,
            }
        },
    },
    data() {
        return {
            targetStatus,
            types,
            populationCategories,
            sortFilters: {
                name: 'Projet',
                clientName: 'Client',
                id: 'Id',
            },
        }
    },
    methods: {
        updateFilters(selection: SelectFilterSelection) {
            this.$store.commit('proj/setProjectFilter', selection)
            this.$emit('refresh')
        },
    },
})
