import Api from '@/api/api'
import { Console } from '@/utils/Console'

export type AdminState = {
    accounts: any[]
    availableRoles: string[]
    error: string
}

const state: AdminState = ({
    accounts: [],
    availableRoles: [],
    error: null,
})

const getters = {}

const mutations = {
    setError(state: AdminState, payload: string) {
        state.error = payload
    },
    setAccountsList(state: AdminState, payload: any[]): void {
        state.accounts = payload
    },
    addAccountTolist(state: AdminState, account: any): void {
        state.accounts.unshift(account)
    },
    setAccountInlist(state: AdminState, account: any): void {
        // vue list cant update without a no-ref copy.
        const copy = [...state.accounts]
        const index = state.accounts.findIndex((item: any) => {
            return `${item.id}` === `${account.id}`
        })

        if (index > 0) {
            copy[index] = { id: account.id, name: account.name, email: account.email, roles: account.roles, nickname: account.nickname }
            // Note vue list not updated with this bellow: copy[index] = { ...account }
        }

        state.accounts = [...copy]
    },
    removeAccountInlist(state: AdminState, accountId: string): void {
        // vue list cant update without a no-ref copy.
        const copy = [...state.accounts]
        const index = state.accounts.findIndex((item: any) => {
            return `${item.id}` === `${accountId}`
        })

        if (index > 0) {
            copy.splice(index, 1)
        }

        state.accounts = [...copy]
    },
    setAvailableRoles(state: AdminState, payload: string[]): void {
        state.availableRoles = payload
    },
    setAccountRoles(state: AdminState, payload: any): void {
        for (const i in state.accounts) {
            // use string comparison because User id is declared as string | number. Not 100% optimal model
            if (`${state.accounts[i].id}` === `${payload.accountId}`) {
                state.accounts[i].roles = payload.roles
            }
        }
    },
}

const actions = {
    async fetchAvailableRoles({ commit }, payload: any): Promise<void> {
        const path = '/admin/roles'
        Console.logAction(`Vuex store action: < fetchAvailableRoles > as GET ${path}`, 'get')

        try {
            const data = await Api.get(path, payload)
            commit('setAvailableRoles', data)
        } catch (error) {
            commit('setError', error.response.data.errorMsg || error.toString())
        }
    },
    async fetchAccountsList({ commit }, payload: any): Promise<void> {
        const path = '/admin/accounts'
        Console.logAction(`Vuex store action: < fetchAccountsList > as GET ${path}`, 'get')

        try {
            const data = await Api.get(path, payload)
            commit('setAccountsList', data)
        } catch (error) {
            commit('setError', error.response.data.errorMsg || error.toString())
        }
    },
    async deleteAccount({ commit }, payload: any): Promise<void> {
        const path = `/admin/accounts/${payload.accountId}/delete`
        Console.logAction(`Vuex store action: < deleteAccount > as POST (but DELETE) ${path}`, 'post')

        try {
            await Api.post(path, payload)
            commit('removeAccountInlist', payload.accountId)
        } catch (error) {
            commit('setError', error.response.data.errorMsg || error.toString())
        }
    },
    async setAccountRoles({ commit, rootState }, payload: any): Promise<void> {
        const path = `/admin/accounts/${payload.accountId}/roles`
        Console.logAction(`Vuex store action: < setAccountRoles > as PUT ${path}`, 'put')

        try {
            const roles = await Api.put(path, payload)

            if (payload.accountId === rootState.user.userId) {
                commit('user/refreshUserRoles', roles, { root: true })
            }
            commit('setAccountRoles', { accountId: payload.accountId, roles })
        } catch (error) {
            commit('setError', error.response.data.errorMsg || error.toString())
        }
    },
    async createAccount({ commit }, payload: any): Promise<void> {
        const path = '/admin/accounts'
        Console.logAction(`Vuex store action: < createAccount > as POST ${path}`, 'post')

        try {
            const account = await Api.post(path, payload)
            commit('addAccountTolist', account)
        } catch (error) {
            commit('setError', error.response.data.errorMsg || error.toString())
        }
    },
    async updateAccount({ commit }, payload: any): Promise<void> {
        const path = `/admin/accounts/${payload.accountId}`
        Console.logAction(`Vuex store action: < updateAccount > as PUT ${path}`, 'put')

        try {
            const account = await Api.put(path, payload)
            commit('setAccountInlist', account)
        } catch (error) {
            commit('setError', error.response.data.errorMsg || error.toString())
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
