export function highlight(words: string, query: string): string {
    if (words === null || words === '' || query === '') {
        return words
    }

    const iQuery = new RegExp(query, 'ig')
    return words.toString().replace(iQuery, (matchedTxt) => {
        return (
            `<span class='highlight'>${matchedTxt}</span>`
        )
    })
}
