
import Vue from 'vue'

import { mapMutations } from 'vuex'

export default Vue.extend({
    name: 'inputDeeplinkGen',
    props: {
        baseUrl: {
            type: String,
            required: true,
        },
        firstParamsValue: {
            type: String,
            required: false,
        },
        secondParamsValue: {
            type: String,
            required: false,
        },
        firstParams: {
            type: String,
            required: false,
        },
        secondParams: {
            type: String,
            required: false,
        },
        shouldEncodeURL: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            newDeeplink: '',
        }
    },
    methods: {
        ...mapMutations({
            setDeeplink: 'deeplink/setDeeplink',
        }),
        generatedLink() {
            const encodedUrl = this.encodedUrlIfNeeded(this.shouldEncodeURL, this.firstParamsValue)
            const base = `${this.firstParams ?? ''}${encodedUrl ?? ''}`

            const prefix = (base.includes('?') || this.baseUrl.includes('?')) ? '&' : '?'
            const searchTerm = this.secondParamsValue && this.secondParamsValue.trim()
                ? `${prefix}${this.secondParams}=${this.encodedUrlIfNeeded(true, this.secondParamsValue)}`
                : ''

            const link = `${base}${searchTerm}`

            this.newDeeplink = `${this.baseUrl + link}`
            this.setDeeplink({
                url: this.newDeeplink,
                firstParams: this.firstParamsValue,
                secondParams: this.secondParamsValue,
            })

            return link
        },
        encodedUrlIfNeeded(shouldEncode: boolean, value: string) {
            return shouldEncode ? encodeURIComponent(value) : value
        },
    },
})
