import { mapMutations } from 'vuex'
import { Advert, Article } from 'lib-ad-platform-models'

export default {
    methods: {
        ...mapMutations({
            setArticle: 'art/setArticle',
            setAdvert: 'advt/setAdvert',
        }),
        setValues(value, model): void {
            model.source = value
            model.quiz.link = value
            if (model instanceof Article) {
                this.setArticle(model)
            } else if (model instanceof Advert) {
                this.setAdvert(model)
            }
        },
    },
}
