
import Vue from 'vue'
import { mapState } from 'vuex'
import { State } from '@/store'
import { Toaster } from '@/components/Toaster'
import GroupSearchBar from './GroupSearchBar.vue'

export default Vue.extend({
    name: 'AudienceEditor',
    components: { GroupSearchBar },
    props: ['id', 'label'],
    data() {
        return {
        } as any
    },
    computed: mapState({
        group: (state: State) => state.audn.group,
    }),
    methods: {
        updateGroup() {
            Toaster.show('info', 'Saving...')
            const payload = { id: this.group.id, name: this.group.name, label: this.group.label }

            this.$store.dispatch('audn/updateGroup', payload).then(() => { Toaster.show('success', 'Saved.', 1300) })
            this.searchDropdownVisible = false
        },
        removeItemFromGroup(item: any) {
            const payload = { source: this.group, target: item }
            this.$store.dispatch('audn/rmAudienceGroupRel', payload)
        },
    },
    mounted() {
        this.$store.dispatch('audn/loadGroup', { id: this.$route.params.id, label: this.$route.params.label })
    },
})
