import VueCookie from 'vue-cookie'
import router from '@/router'
import Api from '@/api/api'
import { Console } from '@/utils/Console'

export type UserState = {
    accessToken: any | null
    refreshToken: any | null
    userId: string | null
    roles: string[]
    settings: any
    alerts: any
}

const ACCESS_TOKEN_COOKIE = '_sess1'
const REFRESH_TOKEN_COOKIE = '_sess2'
const USER_ID_COOKIE = 'uid'
const USER_ROLES_COOKIE = 'roles'

const state: UserState = ({
    accessToken: VueCookie.get(ACCESS_TOKEN_COOKIE) ? JSON.parse(VueCookie.get(ACCESS_TOKEN_COOKIE)) : null,
    refreshToken: VueCookie.get(REFRESH_TOKEN_COOKIE) ? JSON.parse(VueCookie.get(REFRESH_TOKEN_COOKIE)) : null,
    userId: VueCookie.get(USER_ID_COOKIE) ? VueCookie.get(USER_ID_COOKIE) : null,
    roles: VueCookie.get(USER_ROLES_COOKIE) ? JSON.parse(VueCookie.get(USER_ROLES_COOKIE)) : [],
    settings: {},
    alerts: {
        shouldBePublished: [],
        shouldBeUnpublished: [],
    },
})

const getters = {
    getAccessJwt: (state: UserState): string => {
        return state.accessToken
    },
    getRefreshJwt: (state: UserState): string => {
        return state.refreshToken
    },
    isAuthenticated: (state: UserState): boolean => {
        return Boolean(state.accessToken)
    },
    // user need to have ALL the roles specified to match. He can have more on his account though
    // an admin has access to everything
    // usage: this.this.$store.getters['user/hasAllRoles'](roles as string[])
    hasAllRoles: (state: UserState) => (roles: string[]): boolean => {
        if (state.roles.includes('ADMIN')) { return true }
        return roles.every((role: string) => state.roles.includes(role))
    },
    // user can have only ONE of the roles specified, but needs at least one
    // an admin has access to everything
    // usage: this.this.$store.getters['user/hasAllRoles'](roles as string[])
    hasAnyRole: (state: UserState) => (roles: string[]): boolean => {
        if (state.roles.includes('ADMIN')) { return true }
        return roles.some((role: string) => state.roles.includes(role))
    },
    hasThisRole: (state: UserState) => (roles: string[]): boolean => {
        return roles.some((role: string) => state.roles.includes(role))
    },
}

const mutations = {
    setSettings(state: UserState, payload: any): void {
        state.settings = { ...payload }
    },
    refreshUserRoles(state: UserState, roles: string[]): void {
        state.roles = roles
        VueCookie.set(USER_ROLES_COOKIE, JSON.stringify(roles), 1)
    },
    setAuthenticatedUser(state: UserState, payload: any): void {
        if (payload) {
            const { accessToken, refreshToken, userId, roles } = payload

            state.accessToken = { ...accessToken }
            state.refreshToken = { ...refreshToken }
            state.userId = userId
            state.roles = roles

            if (typeof accessToken === 'object' && typeof refreshToken === 'object') {
                VueCookie.set(ACCESS_TOKEN_COOKIE, JSON.stringify(accessToken), 1)
                VueCookie.set(REFRESH_TOKEN_COOKIE, JSON.stringify(refreshToken), 1)
                VueCookie.set(USER_ID_COOKIE, userId, 1)
                VueCookie.set(USER_ROLES_COOKIE, JSON.stringify(roles), 1)
            }
        }
    },
    clearUserData(state: UserState): void {
        state.accessToken = null
        state.refreshToken = null
        state.roles = []

        VueCookie.set(ACCESS_TOKEN_COOKIE, null, 1)
        VueCookie.set(REFRESH_TOKEN_COOKIE, null, 1)
        VueCookie.set(USER_ID_COOKIE, null, 1)
        VueCookie.set(USER_ROLES_COOKIE, null, 1)
        router.push('/login')
    },
    setAutoPublishReports(state: UserState, payload: any): void {
        state.alerts = { ...payload }
    },
}

const actions = {
    async authenticate({ commit }, payload: any): Promise<void> {
        const path = '/authenticate'
        Console.logAction(`Vuex store action: < authenticate > as POST ${path}`, 'post')

        const { username, password } = payload

        try {
            const tokens = await Api.post(path, { username, password })
            commit('setAuthenticatedUser', tokens)
        } catch (error) {
            commit('setAuthenticatedUser', null)
        }
    },
    async refreshToken({ commit, state }): Promise<any> {
        const path = '/authenticate/refresh'
        Console.logAction(`Vuex store action: < refreshToken > as POST ${path}`, 'post')

        try {
            const tokens = await Api.post(path, { token: state.refreshToken.token })
            return tokens
        } catch (error) {
            commit('setAuthenticatedUser', null)
            return error
        }
    },
    async passwordConfirm(_, payload: any): Promise<any> {
        const path = '/password/confirm'
        Console.logAction(`Vuex store action: < passwordConfirm > as POST ${path}`, 'post')

        try {
            const response = await Api.post(path, { confirmationToken: payload.confirmationToken, password: payload.password })
            return response
        } catch (error) {
            return error
        }
    },
    async passwordReset(_, payload: any): Promise<any> {
        const path = '/password/reset'
        Console.logAction(`Vuex store action: < passwordReset > as POST ${path}`, 'post')

        try {
            const response = await Api.post(path, { username: payload.username })
            return response
        } catch (error) {
            throw error
        }
    },
    async getAutopublishReport({ commit }): Promise<void> {
        const path = '/autopublish/report'
        Console.logAction(`Vuex store action: < getAutopublishReport > as GET ${path}`, 'get')

        try {
            const response = await Api.get(path)
            commit('setAutoPublishReports', response)
        } catch (error) {
            commit('setAutoPublishReports', null)
        }
    },

}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
