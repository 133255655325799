
import Vue from 'vue'
import AdvertSelect from '@/components/AdvertSelect.vue'

export default Vue.extend({
    name: 'FunnelAdvertEditModal',
    components: { AdvertSelect },
    data() {
        return {
            advertId: ''
        }
    },
    methods: {
        confirm() {
            this.$emit('confirmed', { advertId: this.advertId })
            this.$modal.hide(this.name)
        },
        onSelected(event: any) {
            const { advert } = event
            this.advertId = advert.id
        }
    },
})
