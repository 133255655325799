
import Vue from 'vue'
import { Project, Target, TARGET_STATUSES, TARGET_ACTORS } from 'lib-ad-platform-models'
import { mapState } from 'vuex'
import { Toaster } from '@/components/Toaster'
import { replaceWord } from '@/filters/replaceWord.filter'
import { capitalize } from '@/filters/capitalize.filter'
import { State } from '@/store'

export default Vue.extend({
    name: 'ProjectCreateModal',
    filters: { replaceWord, capitalize },
    data() {
        return {
            isChecking: false,
            mode: null,
            estimateId: null,
            targetsLength: null,
            actor: null,
            TARGET_ACTORS: TARGET_ACTORS
                .sort()
                .sort((a) => {
                    if (a === 'Laboratoire') {
                        return -1
                    }
                    return 1
                }),
        }
    },
    computed: {
        ...mapState({
            userId: (state: State) => state.user.userId,
            csm: (state: State) => state.meta.csm,
        }),
        project() {
            // Set default CSM to current user if he is in the list
            const userExists = this.csm.find((user) => user.id === this.userId)
            if (userExists) {
                return new Project({ keyAccount: userExists })
            }
            return new Project()
        },
    },
    methods: {
        setMode(value: string) {
            this.mode = value
        },
        generateActionCode() {
            const { clientName, name } = this.project
            const year = new Date().getFullYear() % 100
            return (
                `${`${clientName.substring(0, 3)}${
                    name.substring(0, 4) + year
                }-1`.toUpperCase()}.`
            )
        },
        generateTargets() {
            return Array.from({ length: this.targetsLength }, (x, i) => {
                return new Target({
                    id: `temp${i}`,
                    actionCode: this.generateActionCode(),
                    status: TARGET_STATUSES[0],
                    type: 'post',
                    actor: this.actor,
                })
            })
        },
        create() {
            const { name, clientName, keyAccount } = this.project
            this.isChecking = true
            const isValid = ![name, clientName, keyAccount].includes(null)

            if (isValid) {
                this.project._targets = this.generateTargets()

                this.$store
                    .dispatch('proj/createProject', {
                        ...this.project,
                        actionCode: this.generateActionCode(),
                    })
                    .then((projectId) => {
                        if (projectId) {
                            this.$router.push(`/project/edit/${projectId}`)
                        } else {
                            Toaster.show('error', 'Sorry, an error occured', 2000)
                        }
                    })
                    .catch((error) => {
                        Toaster.show('error', error.response.data.errorMsg, 3000)
                    })

                this.close()
            } else {
                setTimeout(() => { this.isChecking = false }, 3000)
            }
        },
        importEstimate() {
            const { name, keyAccount } = this.project
            this.isChecking = true
            const isValid = ![name, keyAccount, this.actor].includes(null)

            if (this.estimateId && isValid) {
                this.$store
                    .dispatch('proj/importEstimateForNewProject', {
                        id: this.estimateId,
                        keyAccount: this.project.keyAccount,
                        name: this.project.name,
                        actor: this.actor,
                    })
                    .then((projectId) => {
                        if (projectId) {
                            this.$router.push(`/project/edit/${projectId}`)
                        } else {
                            Toaster.show('warning', 'No estimate found with this ID', 2000)
                        }
                    })
                    .catch((error) => {
                        Toaster.show('error', error.response.data.errorMsg, 3000)
                    })
                this.close()
            } else {
                setTimeout(() => { this.isChecking = false }, 3000)
            }
        },
        close() {
            this.$emit('close')
        },
    },
})
