
import Vue from 'vue'
import { dayjs } from '@/utils/dayjs'
import { truncate } from '@/filters/truncate.filter'
import { isEmpty } from '@/utils/isEmpty'

export default Vue.extend({
    name: 'SelectFilter',
    filters: {
        truncate,
    },
    props: {
        label: { type: String, default: null },
        category: null,
        defaultOptions: {
            type: Array,
            default() {
                return []
            },
        },
        multiple: { type: Boolean, default: true },
        sort: {},
        filterOnProperty: { type: String, default: null },
        node: { type: String, default: null },
    },
    data() {
        return {
            loading: false,
            range: {
                from: null,
                to: null,
            },
        }
    },
    created() {
        if (this.type === 'range') {
            this.range.from = 0
            this.range.to = 20000
        }
    },
    computed: {
        dropdownRef() {
            return `toggle-${this.category}`
        },
    },
    methods: {
        fromUnixTimestamp(timestamp: number) {
            if (timestamp !== null) {
                return dayjs(timestamp * 1000)
                    .local()
                    .format('YYYY-MM-DD')
            }
            return null
        },
        toUnixTimestamp(dateStr: string, period: string) {
            if (isEmpty(dateStr)) {
                return null
            }
            return period === 'start'
                ? dayjs(dateStr).startOf('day').unix()
                : dayjs(dateStr).endOf('day').unix()
        },
        submitRange() {
            this.$emit('change', {
                category: this.category,
                value: { from: this.range.from, to: this.range.to },
                property: this.filterOnProperty,
            })

            this.range = {
                from: null,
                to: null,
            }
        },
        setToday() {
            const from = dayjs().startOf('day').unix()
            const to = dayjs().endOf('day').unix()

            this.$emit('change', {
                category: this.category,
                value: { from, to },
                property: this.filterOnProperty,
            })
        },
    },
})
