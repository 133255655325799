<script>
import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props: ['chartdata', 'usersCount'],
    data() {
        return {
            options: {
                responsive: true,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            max: this.usersCount,
                        },
                    }],
                },
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
            },
        }
    },
    mounted() {
        this.renderChart(this.chartdata, this.options)
    },
}
</script>

<style>
</style>
