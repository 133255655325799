
import { debounce } from 'lodash'
import Vue from 'vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import { Toaster } from '@/components/Toaster'

export default Vue.extend({
    name: 'TestAccountsPage',
    components: {
        ConfirmModal,
    },
    data() {
        return {
            term: '',
            searchResult: [],
            currentUser: null,
            isLoading: false,
        }
    },
    watch: {
        term(value) {
            if (value && value.length >= 3) {
                this.searchUserAccount(value)
            } else {
                this.searchResult = []
            }
        },
    },
    methods: {
        searchUserAccount: debounce(async function searchUserAccount(value) {
            this.isLoading = true
            try {
                this.searchResult = await this.$store.dispatch('audn/searchUsersAccount', { term: value })
            } catch (error) {
                Toaster.show('error', error, 3500)
            } finally {
                this.isLoading = false
            }
        }, 200),
        openModal(name, user) {
            this.currentUser = user
            this.$modal.show(name)
        },
        resetUserRels() {
            this.$store.dispatch('audn/resetUserRels', { userId: this.currentUser.id })
                .then((result) => {
                    this.searchResult.map((user) => {
                        if (user.id === result.id) {
                            user.relsCount = result.relsCount
                        }
                        return user
                    })

                    Toaster.show('success', 'Réinitialisation des interactions effectuée', 3500)
                })
                .catch((error) => {
                    Toaster.show('error', error, 3500)
                })

            this.$modal.hide('resetRels')
        },
    },
})
