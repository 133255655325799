
import { State } from '@/store'
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
    name: 'AdvertEditTracking',
    computed: mapState({
        advert: (state: State) => state.advt.advert,
    }),
})
