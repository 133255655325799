import Api from '@/api/api'
import { Console } from '@/utils/Console'

type MetaItem = {
    id: string,
    name: string
}

export type MetaState = {
    professions: MetaItem[],
    specialties: MetaItem[],
    audiences: MetaItem[],
    csm: MetaItem[],
}

const state = () => ({
    professions: [],
    specialties: [],
    audiences: [],
    csm: [],
})

const getters = {}

const mutations = {
    setMeta(state: MetaState, payload: MetaState): void {
        state.professions = { ...payload.professions.sort((a, b) => a.name.localeCompare(b.name)) }
        state.specialties = { ...payload.specialties.sort((a, b) => a.name.localeCompare(b.name)) }
        state.audiences = { ...payload.audiences.sort((a, b) => a.name.localeCompare(b.name)) }
        state.csm = payload.csm.sort((a, b) => a.name.localeCompare(b.name))
    },
}

const actions = {
    async loadMeta({ commit }: any): Promise<void> {
        const path = '/meta'
        Console.logAction(`Vuex store action: < loadMeta > as GET ${path}`, 'get')

        try {
            const result: MetaState = await Api.get(path)
            commit('setMeta', result)
        } catch (error) {
            commit('err/fetchFailed', error, { root: true })
        }
    },
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state,
}
