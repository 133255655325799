export type PushWhereCriterias = {
    area: string | null
    professions?: string[]
    specialties: string[]
    titles: string[]
    expert: 'all' | 'experts' | 'non-experts'
    security?: string
    os: string[]
}

type Notification = {
    title: string
    body: string
    navigation: string
}

export type PushPayload = {
    customId: string
    limit: number
    offset: number
    where: PushWhereCriterias
    notification: Notification
    $iteration: number
    $iterations: number
}

export class PushWhereNormalizer {
    static normalize(input: PushWhereCriterias): string {
        let where = ''
        if (typeof input.area !== 'string' || input.area.trim() === '') {
            throw new Error('Invalid area')
        } else {
            where += ` area = '${input.area.trim()}'`
        }

        if (input?.professions && input.professions.length > 0) {
            where += ` AND Profession IN(${input.professions?.map((profession: string) => `'${profession.trim()}'`).join(',')})`
        }

        if (input?.specialties && input.specialties.length > 0 && input.specialties[0] !== '') {
            where += ` AND Specialty IN(${input.specialties?.map((specialty: string) => `'${specialty.trim()}'`).join(',')})`
        }

        if (input?.titles && input.titles.length > 0 && input.titles[0] !== '') {
            where += ` AND Title IN(${input.titles?.map((title: string) => `'${title.trim()}'`).join(',')})`
        }

        if (input?.os && input.os.length > 0 && input.os[0] !== '') {
            where += ` AND os IN(${input.os?.map((os: string) => `'${os.trim()}'`).join(',')})`
        }

        if (input?.security && input.security.trim() !== '' && input.security !== null) {
            where += ` AND (${input.security})`
        }

        switch (input.expert) {
        case 'all':
        // nothing to declare, sir!
            break
        case 'experts':
            where += ' AND roles LIKE \'%%ROLE_EXPERT\''
            break
        case 'non-experts':
            where += ' AND roles NOT LIKE \'%%ROLE_EXPERT\''
            break
        default:
            // nothing
        }

        return where
    }
}
