
import Vue from 'vue'
import Api from '@/api/api'
// import { mapState } from "vuex"
import AdvertPreview from '@/views/partials/AdvertPreview.vue'
// import { Advert, AdvertListFilter } from "ad-platform-models"

export default Vue.extend({
    name: 'AudienceDebugPage',
    components: { AdvertPreview },
    data() {
        return {
            userId: '',
            audiences: [],
            adverts: [],
        }
    },
    methods: {
        onSubmitUserId() {
            this.userId = this.$refs.userIdInput.value

            Api.get(`/debug/audience/${this.userId}`)
                .then((r: any) => {
                    this.audiences = r.audiences
                    this.adverts = r.adverts
                })
                .catch((e) => {
                    console.log(e)
                })
        },

    },
})
