import { dayjs } from '@/utils/dayjs'

export function simpday(dateStr: string | number, showYear = false): string {
    if (typeof dateStr === 'string') {
        const date = new Date(dateStr)
        const opts: any = { /* year: 'numeric', */ month: 'short', day: 'numeric' }

        if (showYear === true) {
            opts.year = 'numeric'
        }

        return date.toLocaleDateString('en-US', opts)
    } if (typeof dateStr === 'number') {
        return dayjs(dateStr * 1000).format('LLL')
    }
    return null
}

export function normalDate(epochTimestamp: number, showHours = false): string {
    if (epochTimestamp === null) {
        return '--'
    }

    const date = dayjs(epochTimestamp * 1000)
    const formatted = date.locale('fr').format('L')
    const hours = date.locale('fr').format('HH:mm')

    return (!showHours) ? formatted : `${formatted} ${hours}`
}

export function shortDate(epochTimestamp: number): string {
    const date = dayjs(epochTimestamp)
    const dateText = date.locale('fr').format('ddd Do MMM')

    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
}
