
import Vue from 'vue'

export default Vue.extend({
    name: 'LayersToolbar',
    props: {
        activeLayer: {
            default: 1,
            required: true,
        },
        activePage: {
            required: true,
        },
    },
    data() {
        return {
            activeTrashLayer: null,
        }
    },
    computed: {
        layers() {
            return this.activePage.layers.map(({ level }) => level).sort((a, b) => a - b)
        },
    },
    methods: {
        selectLayer(level: number) {
            this.$emit('selectLayer', level)
        },
        showTrash(level: number) {
            this.activeTrashLayer = level
        },
        hideTrash() {
            this.activeTrashLayer = null
        },
        addLayer() {
            this.$emit('addLayer')
        },
        deleteLayer(layer) {
            this.$emit('deleteLayer', layer)
        },
    },
})
