
import Vue from 'vue'
import { mapState } from 'vuex'
import { capitalize } from '@/filters/capitalize.filter'
import { replaceWord } from '@/filters/replaceWord.filter'
import { truncate } from '@/filters/truncate.filter'
import { UIKitStuff } from '@/models/MiscTypes'
import { State } from '@/store'

declare const UIkit: UIKitStuff

export default Vue.extend({
    name: 'ArticleEditPart1',
    filters: { truncate, replaceWord, capitalize },
    props: ['missingFields'],
    data() {
        return {
            selectedAudienceId: null,
            categories: [
                'Area',
                'Country',
                'Role',
                'Profession',
                'Specialty',
                'Title',
                'OrGroup',
            ],
            term: '',
            options: [],
            loading: false,
        }
    },
    computed: {
        ...mapState({
            article: (state: State) => state.art.article,
        }),
        dropdownRef() {
            return `toggle-${this.category}`
        },
    },
    methods: {
        resetSearch() {
            this.term = ''
            this.options = []
        },
        getCategoryValues(label: string) {
            return this.article._$audiences.filter((item: any) => item.label === label)
        },
        searchList(label: string) {
            if (!this.term.length) {
                this.term = ''
            }

            this.loading = true

            this.$store
                .dispatch('proj/getFilterList', {
                    label,
                    term: this.term,
                    limit: 10,
                    skip: 0,
                })
                .then((list: any) => {
                    this.loading = false
                    this.options = list.filter(({ label, id }) => {
                        return !this.article._$audiences.some((item) => item.label === label && item.id === id)
                    })
                })
                .catch(() => {
                    this.loading = false
                })
        },
        selectValue(id: string, name: string, label: string) {
            this.article._$audiences.push({ id, name, label })

            this.resetSearch()
            UIkit.dropdown(this.$refs[label]).hide()
        },
        deleteValue(e, item: any) {
            e.stopPropagation()
            const index = this.article._$audiences.findIndex(({ label, id }) => id === item.id && label === item.label)
            this.article._$audiences.splice(index, 1)
        },
    },
})
