
import Vue from 'vue'
import { Article } from 'lib-ad-platform-models'
import StoryScreenEditor from '@/views/partials/story/StoryScreenEditor.vue'
import StoryRowIconEditor from '@/views/partials/story/StoryRowIconEditor.vue'
import NotificationPreview from '../previews/NotificationPreview.vue'
import PostPreview from '../previews/PostPreview.vue'
import QuizPreview from '../previews/QuizPreview.vue'

export default Vue.extend({
    name: 'ArticlePreview',
    components: {
        NotificationPreview,
        PostPreview,
        QuizPreview,
        StoryScreenEditor,
        StoryRowIconEditor,
    },
    props: {
        input: { type: Object, default: null },
        editable: { type: Boolean, default: false },
        isDeploy: { type: Boolean, default: false },
        scale: { type: Number, default: 1 },
    },
    data() {
        return {
            article: null,
            currentScreen: null,
        }
    },
    watch: {
        input: {
            handler(value: Article) {
                this.article = value
            },
            immediate: true,
        },
    },
    mounted() {
        if (this.article.type === 'story') {
            [this.currentScreen] = this.article.story.screens
        }
    },
    methods:
    {
        addScreen() {
            this.$emit('addScreen')
        },
        updateAdvert(props: string, value: string|number) {
            this.article[props] = value
        },
        openImagesEditorModal() {
            this.$emit('openImagesEditorModal')
        },
    },
})
